.item-icons
  .component-section-wrap.done
    .type-select-wrap
      margin: 0

    .type-select-nav, .type-select-content
      display: none

    .icons-list .card.selected
      flex: 100%

    .icons-grid .icons-data
      h5, p
        display: none

    .icon-content .file-detail
      display: none

  .icons-upload-nodata a
    font-size: 0.85em
    padding: 0.25em 0 1.25em
    display: inline-block

  .files-list
    margin-top: 1.5em
    margin-bottom: 0
    animation: showItem $speed-slower forwards ease
    animation-delay: $speed-fastest
    opacity: 0
    position: relative

    &:empty
      margin-top: 0
      margin-bottom: 0

  .card
    margin-top: 1em
    margin-bottom: 1em
    padding: 1em
    width: 100%
    animation: none
    transform-origin: center center
    opacity: 1
    text-align: center
    cursor: default

    &:hover
      .card-download
        animation: showCardActiveLeft $speed-faster forwards ease

      .card-delete
        animation: showCardActiveRight $speed-faster forwards ease

      .card-reorder
        animation: showCardActiveMiddle $speed-faster forwards ease

    &:before, &:after
      display: none

.icon-content
  padding-bottom: 1em

  .icon-loading
    min-height: 10.25em
    background-size: 10em
    background-position: center center
    width: 100%

  .file-detail
    &:after
      display: none

    .file-detail-content
      text-shadow: 0 -1px 0 rgba(white, 0.5)
      pointer-events: none
      text-align: right
      color: $color-text-min

    .file-text, .file-size
      display: inline-block
      overflow: hidden

    .file-text
      max-width: 16em
      text-overflow: ellipsis
      white-space: nowrap
      margin-right: 1.25em

    .file-size
      margin-left: 0.67em
      position: relative
      top: 0

      &:before
        content: "-"
        margin-right: 0.67em

.icons-grid
  display: flex
  flex-flow: row wrap

  .icons-data
    width: 20%
    padding: 1.25em 1em

    @media (max-width: $mdscreen)
      width: 25%

    @media (max-width: $smscreen)
      width: 33.333%

    i
      font-size: 2em

    img
      width: 4.25em

      @media (max-width: $smscreen)
        width: 4em

    h5, p
      cursor: text

    h5
      color: $color-body-text
      margin: 1.25em 0 0.5em
      font-size: 0.85em
      font-family: "muliregular"

      @media (max-width: $smscreen)
        font-size: 0.75em

    p
      font-family: "muliregular"
      color: $color-text-min
      font-size: 0.75em
      line-height: 1.15
      margin: 0.5em
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis