@import url('https://fonts.googleapis.com/css?family=Abril+Fatface')
@import url('https://fonts.googleapis.com/css?family=Abel')
@import url('https://fonts.googleapis.com/css?family=Bree+Serif')
@import url('https://fonts.googleapis.com/css?family=Cinzel')
@import url('https://fonts.googleapis.com/css?family=Comfortaa:300')
@import url('https://fonts.googleapis.com/css?family=Crimson+Text')
@import url('https://fonts.googleapis.com/css?family=Dancing+Script')
@import url('https://fonts.googleapis.com/css?family=Dosis:300')
@import url('https://fonts.googleapis.com/css?family=EB+Garamond')
@import url('https://fonts.googleapis.com/css?family=Gruppo')
@import url('https://fonts.googleapis.com/css?family=Inconsolata')
@import url('https://fonts.googleapis.com/css?family=Indie+Flower')
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:300')
@import url('https://fonts.googleapis.com/css?family=Josefin+Slab')
@import url('https://fonts.googleapis.com/css?family=Libre+Baskerville')
@import url('https://fonts.googleapis.com/css?family=Lora')
@import url('https://fonts.googleapis.com/css?family=Montserrat:400')
@import url('https://fonts.googleapis.com/css?family=Old+Standard+TT')
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300')
@import url('https://fonts.googleapis.com/css?family=Oswald:300')
@import url('https://fonts.googleapis.com/css?family=Philosopher')
@import url('https://fonts.googleapis.com/css?family=Playfair+Display')
@import url('https://fonts.googleapis.com/css?family=Quicksand:300')
@import url('https://fonts.googleapis.com/css?family=Raleway:300')
@import url('https://fonts.googleapis.com/css?family=Roboto:300')
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300')
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono:300')
@import url('https://fonts.googleapis.com/css?family=Rokkitt:300')
@import url('https://fonts.googleapis.com/css?family=Source+Serif+Pro')
@import url('https://fonts.googleapis.com/css?family=Slabo+27px')
@import url('https://fonts.googleapis.com/css?family=Titillium+Web:300')
  
.item-fonts
  .component-section-inner
    &.basic
      .fonts-advanced
        display: none

      .fonts-list
        margin-bottom: 4em

    &.advanced
      > h2
        display: none

      .fonts-list
        display: none

      .fonts-advanced
        display: block

  .component-section-wrap
    &.done
      .fonts-list
        margin-bottom: 0

      .font-type-toggle
        display: none

      .fonts-advanced .fonts-custom
        margin: 0.25em 0

      .card.selected
        font-size: 105%

        @media (max-width: $smscreen)
          font-size: 100%

  .fonts-advanced
    display: block

    .component-section-wrap
      padding: 0
      margin: 0

      &:before
        box-shadow: none
        background: transparent

      .section-btn-done, .section-edit-icon
        display: none

      .fonts-custom
        margin-bottom: 1em
        opacity: 0
        animation: showFade $speed-normal ease forwards
        position: relative
        z-index: 1

      .fonts-custom-list
        margin-top: 0.5em
        margin-bottom: 0.5em

  .font-type-toggle
    text-align: center

    .btn
      margin: 1em auto 0
      font-size: 1em

.fonts-list
  @media (max-width: $smscreen)
    font-size: 0.925em

  @media (max-width: $xsscreen)
    font-size: 0.85em

  .card
    font-size: 0.925em

    &:hover
      .card-expand
        animation: showCardActiveLeft $speed-faster forwards ease

  .card.selected
    &:hover
      .card-expand
        opacity: 1
        transition: none

        @media (max-width: $smscreen)
          animation: none

    .card-expand
      opacity: 0
      transition: opacity linear $speed-faster

      @media (max-width: $mdscreen)
        display: block
        animation: showCardActiveLeft $speed-faster forwards ease

  p
    margin: 0.25em 0
    font-size: 1.5em
    line-height: 1em
    text-transform: capitalize

.abel
  font-family: "Abel"
  font-size: 105%

  p
    font-size: 1.75em
    line-height: 0.75

.abrilfatface
  font-family: "Abril Fatface"
  font-size: 95%

.breeserif
  font-family: "Bree Serif"

.cinzel
  font-family: "Cinzel"
  font-size: 92%

.comfortaa
  font-family: "Comfortaa"
  font-size: 92%

  p
    position: relative
    top: 0.125em

.crimsontext
  font-family: "Crimson Text"

  p
    font-size: 1.75em
    line-height: 0.75
    position: relative
    top: 0.15em

.dancingscript
  font-family: "Dancing Script"
  font-size: 110%

  p
    font-size: 1.67em
    line-height: 0.85
    position: relative
    top: -0.075em

.dosis
  font-family: "Dosis"
  font-size: 105%

  p
    font-size: 1.75em
    line-height: 0.75

.ebgaramond
  font-family: "EB Garamond"
  font-size: 105%

  p
    font-size: 1.525em

.gruppo
  font-family: "Gruppo"
  font-size: 105%

  p
    font-size: 1.85em
    line-height: 0.75
    position: relative
    top: -0.125em

.inconsolata
  font-family: "Inconsolata"
  font-size: 95%

  p
    font-size: 1.67em
    line-height: 0.85

.indieflower
  font-family: "Indie Flower"
  font-size: 102%

  p
    font-size: 1.67em
    line-height: 0.85
    position: relative
    top: 0.15em

.josefinsans
  font-family: "Josefin Sans"

  p
    font-size: 1.75em
    line-height: 0.75
    position: relative
    top: 0.15em

.josefinslab
  font-family: "Josefin Slab"
  font-size: 105%

  p
    font-size: 1.75em
    line-height: 0.75
    position: relative
    top: -0.075em

.librebaskerville
  font-family: "Libre Baskerville"
  font-size: 90%

  p
    font-size: 1.25em
    position: relative
    top: 0.115em

.lora
  font-family: "Lora"
  font-size: 92%

.montserrat
  font-family: "Montserrat"
  font-size: 95%

.muli, .mulish
  font-family: "Mulilight"

.oldstandard
  font-family: "Old Standard TT"
  font-size: 95%

  p
    font-size: 1.5em
    line-height: 0.85
    position: relative
    top: 0.125em

.opensans
  font-family: "Open Sans"

.oswald
  font-family: "Oswald"

  p
    font-size: 1.67em
    line-height: 0.85
    position: relative
    top: 0.15em

.philosopher
  font-family: "Philosopher"
  font-size: 105%

.playfair
  font-family: "Playfair Display"
  font-size: 95%

  p
    font-size: 1.525em
    line-height: 0.925
    position: relative
    top: 0.075em

.quicksand
  font-family: "Quicksand"

  p
    font-size: 1.525em
    line-height: 0.925
    position: relative
    top: -0.075em

.raleway
  font-family: "Raleway"
  font-size: 95%

.roboto
  font-family: "Roboto"
  font-size: 95%

.robotocondensed
  font-family: "Roboto Condensed"
  font-size: 95%

.robotomono
  font-family: "Roboto Mono"
  font-size: 90%

.rokkitt
  font-family: "Rokkitt"
  font-size: 105%

  p
    font-size: 1.75em
    line-height: 0.75

.sourceserifpro
  font-family: "Source Serif Pro"
  font-size: 95%

  p
    font-size: 1.425em

.slabo
  font-family: "Slabo 27px"
  font-size: 105%

  p
    font-size: 1.67em
    line-height: 0.85

.titilliumweb
  font-family: "Titillium Web"
  font-size: 102%

  p
    position: relative
    top: -0.075em

.ui.modals .font-example-modal
  min-width: 75vw

  &:hover
    cursor: pointer

  .card-close, .card-selected
    animation: none

  .card-selected
    font-size: 1.25em

  .content
    padding: 1.25em 3.5em
    cursor: default

  h1, h2
    font-family: inherit
    line-height: 1.15em
    font-weight: normal

  h1
    font-size: 3.5em
    margin: 0.5em 0 0.25em
    text-transform: capitalize
    vertical-align: top
    position: relative
    padding-right: 2.25em

    @media (max-width: $smscreen)
      font-size: 3em
      padding-right: 1em

    .font-btns
      position: absolute
      right: -0.15em
      top: 0.367em

      @media (max-width: $smscreen)
        top: 0.225em

    .btn
      font-size: 0.33em
      margin: 0 0.33em
      padding: 0.5em 0.85em
      float: left

      @media (max-width: $smscreen)
        display: block
        float: none
        margin: 0.5em 0

      &.font-download
        @media (max-width: $smscreen)
          display: none

      .icon
        font-size: 1.15em

  h2
    margin: 1.5em 0 0.5em
    padding: 0
    font-size: 2.25em

  p
    line-height: 1.33em
    font-weight: normal
    text-transform: none

    &.font-paragraph
      font-size: 1.33em
      padding-top: 0.25em
      padding-bottom: 0.25em

    &.font-characters
      font-size: 1.5em
      word-wrap: break-word

  .btn
    display: inline-block
    margin: 1.75em auto 0.25em
    font-size: 0.925em

.font-paragraph
  padding-left: 2.5em
  border-left: 2px solid $color-primary-dark

  @media (max-width: $mdscreen)
    border-left-width: 1px
    padding-left: 2em

  @media (max-width: $smscreen)
    padding-left: 1.5em

// FONTS ADVANCED

.item-fonts
  .component-section-wrap.done
    .fonts-custom
      margin: 0

    .type-select-wrap
      margin: 0

    .type-select-nav, .type-select-content
      display: none

    .fonts-custom-list
      align-items: center
      justify-content: center

      &.has-one .card.selected
        max-width: 100%

        .font-aa
          font-size: 5.5em
          width: 12rem

          @media (max-width: $smscreen)
            font-size: 4.25em
            width: 6.25rem

        .font-example
          font-size: 1em
          letter-spacing: 4px

          @media (max-width: $smscreen)
            font-size: 0.85em
            letter-spacing: 2px

      .card.selected
        flex: auto
        max-width: calc(50% - 1em)

        @media (max-width: $mdscreen)
          max-width: 100%

        .font-name
          font-size: 1.15em
          overflow: hidden
          text-overflow: ellipsis
          padding: 0.75em 1.5em
          width: 100%

          @media (max-width: $smscreen)
            font-size: 1em

          a, .link
            display: none

        .font-detail
          padding: 1.5em 0.75em 2.25em
          display: flex

        .font-aa
          text-align: center
          font-size: 5em
          margin: 0 0 0.15em
          padding: 0 0.25em 0 0
          width: 9.5rem

          @media (max-width: $lgscreen)
            width: 8.5rem

          @media (max-width: $smscreen)
            width: 6rem
            font-size: 4em

        .font-example
          font-size: 0.85em
          overflow: hidden
          text-overflow: ellipsis
          line-height: 1.75

        .font-type, .font-buttons, .font-variants-toggle, .font-variants-list
          display: none

  .sorting, .deleting
    .fonts-custom-list
      transition: inherit

      .card
        .font-variants-list
          display: none

.fonts-custom
  margin: 3.5em 0 2.25em

.type-select-wrap
  filter: drop-shadow(0 0.085em 0.15em rgba(black, 0.15))
  margin: 1em 0
  position: relative
  z-index: 1

  .type-select-nav
    margin: 0
    padding: 0
    display: flex
    list-style: none
    flex-flow: row nowrap
    align-items: flex-end
    height: 3.5em

    li
      flex: 1
      padding: 1em 0.5em
      text-align: center
      background: $color-lightest
      color: $color-primary-dark
      position: relative
      overflow: hidden
      transition: color ease $speed-faster, padding ease $speed-faster, background ease $speed-faster, box-shadow ease $speed-faster

      &:hover
        background: white
        color: $color-secondary
        cursor: pointer
        box-shadow: 0 0.1em 0 $color-secondary inset

      &:before
        content: ""
        position: absolute
        bottom: 0
        left: 0
        height: 0
        width: 100%
        background: $color-primary-dark
        transition: height ease $speed-faster, box-shadow ease $speed-faster
        z-index: 0

      &.active
        color: white
        padding: 1.25em 0.5em
        cursor: default

        &:before
          height: 100%
          box-shadow: 0 0.1em 0 $color-primary-light inset

      span
        position: relative
        z-index: 1

  .type-select-content
    padding: 3em
    background: $color-primary-dark
    border-radius: 0 0 $radius-border $radius-border
    text-align: center

    @media (max-width: $smscreen)
      padding: 3em 1.5em

    .type-select-item
      position: relative
      display: none

      &.active
        opacity: 0
        display: block
        animation: showItem $speed-normal ease forwards

      .btn-under-input
        margin: 1.5em auto -0.75em
        font-size: 1em
        display: inline-block

      input
        background: rgba(white, 0.875)

        &:hover
          background: white

      .font-loading
        width: auto

  .type-select-search
    position: relative

    input
      font-size: 16px

    .type-select-search-icons
      position: absolute
      top: 0
      right: 0
      z-index: 1
      height: 100%

      .type-select-search-icon
        position: absolute
        height: 4em
        width: 4em
        top: 0
        right: 0
        font-size: 1em

      .icon
        position: absolute
        right: 0
        top: 0
        padding: 0.85em 1em
        font-size: 1.5em
        background: $color-primary
        color: white
        height: 100%
        transition: background ease $speed-faster, color ease $speed-faster

        @media (max-width: $smscreen)
          padding: 1em

        &:hover
          color: $color-primary
          background: white
          cursor: pointer

        &.icon-cross
          color: $color-alert
          background: white

          &:hover
            background: $color-alert
            color: white

  .type-select-autocomplete
    position: relative
    z-index: 1
    text-align: left

    > div
      position: absolute
      max-height: 12.5em
      overflow: auto
      width: 100%
      top: 4em
      left: 0
      background: rgba(white, 0.925)
      border-radius: 0 0 $radius-border $radius-border
      box-shadow: 0 0.15em 0.33em rgba(black, 0.15)
      opacity: 0
      animation: showFade $speed-faster ease forwards

      &::-webkit-scrollbar
        width: 3px

      &::-webkit-scrollbar-track
        background: $color-lightest

      &::-webkit-scrollbar-thumb
        background: darken($color-light, 15%)

  .files-dropzone
    background: darken($color-primary-dark, 5%)
    margin: 0
    opacity: 0
    animation: showFade $speed-normal ease forwards

    &:hover
      background: darken($color-primary-dark, 10%)

  .files-bad-wrap
    margin: 1.5em 0 0

#fonts-typekit-wrapper
  position: relative
  z-index: 1
  text-align: left

  .fonts-typekit-list
    .typekit-kit
      margin: 1.5em 0
      padding: 0.5em 0
      width: 100%
      overflow: hidden
      background: white
      border-radius: $radius-border
      box-shadow: 0 0.15em 0.33em rgba(black, 0.15)
      opacity: 0
      animation: showFade $speed-faster ease forwards

      .typekit-kit-name
        font-size: 1em
        padding: 0.75em 0 0.85em
        margin: 0.5em 1.5em
        border-bottom: 1px dashed rgba($color-primary-dark, 0.5)

        .btn
          float: right
          font-size: 0.75em
          margin: -0.75em 0 0

#fonts-upload-wrapper, #images-upload
  // .files-dropzone
  //   h4
  //     margin: 0.5em 0

  //   h5
  //     color: white
  //     font-size: 0.85em
  //     margin: 0 0 0.5em

  //   .btn
  //     margin: 1em auto

.fonts-type-family
  display: flex
  position: relative
  transition: padding ease $speed-faster

  &:hover, &.highlighted
    color: white
    background: $color-accent
    cursor: pointer

  &.active
    color: $color-success
    background: $color-lightest
    pointer-events: none
    cursor: default
    padding-left: 1em

    &:before
      content: "\e934"
      font-family: "icomoon"
      position: absolute
      top: 0
      bottom: 0
      left: 1.5em
      margin: auto
      color: $color-success
      font-size: 0.85em
      height: 1em
      font-weight: 600
      animation: showItem $speed-normal ease forwards

  .fonts-name
    flex: 1
    padding: 1em 1em 1em 2em

  .fonts-variants
    font-size: 0.75em
    width: 7.5em
    transition: background ease $speed-faster
    position: relative

    .variants-info-trigger
      margin: 1em 0 0
      padding: 0.425em 0 0.125em
      border-bottom: 1px dotted rgba(white, 0.75)
      display: inline-block

.font-variants-info
  pointer-events: none

  ul
    margin: 0
    padding: 0
    list-style: none
    flex-flow: row wrap
    display: flex
    align-items: center

  li
    padding: 0.25em 0.5em
    margin: 0.25em 0.33em
    font-size: 0.925em

.fonts-error
  margin: 1.5em 0
  width: 100%
  background: white
  border-radius: $radius-border
  box-shadow: 0 0.15em 0.33em rgba(black, 0.15)
  padding: 0.5em
  text-align: center
  opacity: 0
  animation: showFade $speed-faster ease forwards

.fonts-custom-list
  margin-top: 1.5em
  margin-bottom: 1em
  transition: margin ease $speed-normal
  position: relative
  z-index: 0

  .card
    animation: none
    opacity: 1
    width: 100%
    text-align: left
    align-items: center
    padding: 0
    margin-top: 0.85em
    margin-bottom: 0.85em
    cursor: inherit
    transform-origin: center center
    user-select: none

    &:hover
      cursor: grab

      .card-delete
        animation: showCardActiveRight $speed-faster forwards ease

        @media (max-width: $smscreen)
          animation: none
          opacity: 1

    &.selected
      &:before, &:after
        display: none

    &.has-variants
      .fonts-custom-content .fonts-custom-name
        position: relative
        cursor: pointer

        &:hover .font-name
          background: $color-lightest

          label
            color: $color-secondary

    &.font-primary-drop
      position: absolute
      top: 0
      left: 0
      height: 0
      width: calc(100% - 1em)
      pointer-events: none
      background: transparent
      box-shadow: none
      opacity: 0
      animation: showFade $speed-normal $speed-fastest forwards ease

      &:before
        content: ""
        position: absolute
        top: 0
        left: 0
        height: 100%
        width: 100%
        border-radius: $radius-btn
        z-index: 1
        box-shadow: 0 0 0 0.125em $color-success inset

      p
        background: darken($color-success, 5%)
        color: white
        font-size: 0.75em
        position: absolute
        text-shadow: 0 1px 0 rgba(black, 0.15)
        left: 0
        bottom: 0
        top: auto
        padding: 0.5em 0.75em 0.67em
        margin: auto
        line-height: 1
        border-radius: 0 $radius-btn 0 0
        z-index: 1
        width: auto
        height: auto

  .fonts-custom-content
    // animation: showFade $speed-fast ease forwards

    &.variantOn
      cursor: pointer

      > .font-detail
        box-shadow: 0 0.15em 0.25em rgba(black, 0.075)
        position: relative
        z-index: 1

      .font-variants-list
        display: block

    .font-loading
      min-height: 10.25em
      background-size: 10em
      background-position: center center
      width: 100%

      @media (max-width: $smscreen)
        min-height: 8.5em

    .font-detail
      @media (max-width: $smscreen)
        display: block

    .inline-loading
      width: 100%

.font-name
  margin: 0
  font-size: 1.15em
  font-family: "mulilight"
  width: 100%
  padding: 0.75em 2.33em 0.75em 1.5em
  background: white
  position: relative
  transition: background ease $speed-faster
  border-radius: $radius-btn $radius-btn 0 0

  .font-variants-toggle
    float: right

  .font-name-edit
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%
    display: flex

    .input
      flex: 1

    input
      font-size: 1em
      padding: 0.75em 2.33em 0.75em 1.5em
      background: white
      line-height: 1

.font-detail
  display: flex
  align-items: center
  padding: 1.75em 6.67em 3em 0.75em
  position: relative

  @media (max-width: $smscreen)
    padding: 1.75em 0.75em 2.25em

.font-aa
  margin: 0
  font-size: 5.5em
  padding: 0 0.33em
  width: 12rem
  line-height: 1
  pointer-events: none
  color: $color-body-text

  @media (max-width: $mdscreen)
    font-size: 5em
    padding: 0 0.25em
    width: 9rem

  @media (max-width: $smscreen)
    font-size: 4.25em
    padding: 0 0.15em
    width: 6.25rem
    display: inline-block
    position: relative
    top: -0.125em

.font-example
  margin: 0
  padding: 0.25em 0
  width: 100%
  font-size: 1.085em
  line-height: 1.33
  letter-spacing: 2px
  flex: 1
  pointer-events: none
  overflow: hidden
  text-overflow: ellipsis

  @media (max-width: $smscreen)
    font-size: 0.85em
    padding: 0.5em 0 0
    display: inline-block
    width: calc(100% - 6.25rem)

.font-buttons
  padding: 0 0 0.75em
  position: absolute
  top: 0
  right: 2.5em
  bottom: 0
  margin: auto
  display: flex
  flex-flow: column wrap
  justify-content: center

  @media (max-width: $smscreen)
    position: relative
    width: 100%
    padding: 1em 0.5rem 0
    display: block
    text-align: right
    right: 0

  .font-edit-btns-wrap
    height: 3em
    position: relative
    text-align: right
    white-space: nowrap

    .font-edit-btns
      position: absolute
      top: 0
      right: 0

      @media (max-width: $smscreen)
        top: -0.75em

      .btn
        display: inline-block

        &.btn-success
          margin-left: 1em

          @media (max-width: $smscreen)
            margin-left: 0.75em

  .btn
    display: block
    margin: 0.5em 0

    @media (max-width: $smscreen)
      display: inline-block
      margin: 0.25em 0.5em
      font-size: 1em

    .icon
      font-weight: 600
      font-size: 1.075em
      position: relative
      top: 0.15em

.font-variants-toggle
  &.active
    .variants-selected .icon
      transform: scaleY(-1)

  label
    cursor: pointer
    transition: color ease $speed-faster

  .variants-selected
    width: 100%
    text-align: center
    margin: 0.5em 0
    color: $color-success
    font-size: 0.75em

    span
      margin-right: 0.5em

    .icon
      margin-left: 0.5em
      position: relative
      top: 0.133em
      font-weight: 600
      display: inline-block
      transition: transform ease $speed-faster

.font-content
  display: block
  flex: 1

  display: inline-block
  width: calc(100% - 6rem)

  label
    margin: 0.75em 1.5em 0.25em 0
    text-transform: capitalize
    display: inline-block
    font-size: 0.85em
    cursor: pointer

  label span
    color: $color-text-min
    margin-right: 0.25em

.font-toggle
  display: inline-block
  height: 2.75em
  width: 2.75em
  line-height: 2.75
  font-size: 1.15em
  background: white
  border-radius: 100%
  text-align: center
  margin-right: 2.5em
  box-shadow: 0 0.075em 0.075em rgba(black, 0.15)
  cursor: pointer
  transition: background ease $speed-faster

  @media (max-width: $smscreen)
    margin-right: 1em
    font-size: 1em

  .icon
    color: $color-text-min
    transition: color ease $speed-faster, content ease $speed-faster
    font-weight: 600

.font-toggle-trigger
  position: absolute
  top: 0
  left: 0
  height: 100%
  width: 100%

.font-variants-list
  margin: 0
  padding: 0
  transition: opacity ease $speed-normal
  transform-origin: top center
  display: none
  background: rgba(white, 0.85)

  &.active
    opacity: 0
    animation: showContentFade $speed-normal ease forwards

    .font-detail
      display: flex

      @media (max-width: $smscreen)
        display: block

  .font-detail
    display: none
    transition: background ease $speed-faster
    background: darken($color-lightest, 2%)
    padding: 2em 0.67em
    box-shadow: none

    @media (max-width: $smscreen)
      padding: 2em 1em

    &:hover
      cursor: pointer
      background: white

      .font-toggle
        background: $color-primary

        .icon
          color: white

    &.active
      background: transparent

      &:hover
        background: darken($color-lightest, 1%)

      .font-aa
        color: $color-body-text
        opacity: 1

      .font-content
        color: $color-body-text
        opacity: 1

      .font-toggle
        background: $color-success

        .icon
          color: white

      &:hover
        .font-toggle
          background: $color-alert

          .icon:before
            content: "\e935"

    .font-aa
      font-size: 6.33em
      width: 13.5rem
      color: $color-text-min
      transition: color ease $speed-faster, opacity ease $speed-faster
      opacity: 0.67

      @media (max-width: $mdscreen)
        font-size: 5.5em
        width: 9rem

      @media (max-width: $smscreen)
        font-size: 4.25em
        width: 6rem
        display: block

    .font-example
      @media (max-width: $smscreen)
        width: 100%

    .font-content
      color: $color-text-min
      transition: opacity ease $speed-faster
      opacity: 0.67
      padding-right: 1.5em

      @media (max-width: $smscreen)
        width: calc(100% - 2.5rem)
        padding-left: 0.25em
        padding-top: 0.25em
        vertical-align: top
        display: inline-block

    .font-toggle
      @media (max-width: $smscreen)
        display: inline-block
        margin-right: 0
