.item-audience, .item-details
  .component-section-wrap.done
    padding-top: 1em
    padding-bottom: 1em

    &.none-selected
      padding-top: 1em
      padding-bottom: 1em

      .audience-section, .details-section
        display: none
        padding: 0
        margin: 0

    .audience-section, .details-section
      margin: 0.5em 0

      &.empty
        margin: 0
        padding: 0
        display: none

    .cards-list .other.selected
      background: $color-lightest
      padding: 1.5em

      .card-delete
        display: none

      input
        box-shadow: none
        background: none
        font-size: 1em
        padding: 0

        @media (max-width: $smscreen)
          border: none
          font-size: 16px

    .audience-demographics
      .audience-age, .audience-skill
        display: inherit
        width: 50%

        @media (max-width: $smscreen)
          .card
            min-width: 50%

      .audience-age
        padding-right: 0.5em

      .audience-skill
        padding-left: 0.5em

    .details-other .details-other-wrap
      margin: 0.5em 0

.item-audience, .item-details
  h2
    font-size: 2.15em
    line-height: 1.5
    margin: 0.33em 0 1.25em
    max-height: 5em

    @media (max-width: $mdscreen)
      line-height: 1.33
      margin: 1em 0 1.25em

    @media (max-width: $smscreen)
      font-size: 1.75em

  input, textarea
    opacity: 0
    animation: showItem $speed-slow forwards ease
    animation-delay: $speed-fastest

  label
    font-size: 1.15em
    margin: 1.25em 0
    line-height: 1.25

    @media (max-width: $smscreen)
      margin: 1em 0

  .cards-list
    @media (max-width: $smscreen)
      margin: 1em -0.5em

  .audience-section, .details-section
    display: inline-block
    width: 100%
    margin: 1em 0

  .audience-definition, .details-problem, .details-other
    textarea
      font-size: 1.33em

  .cards-list
    .other
      flex: 1
      padding: 0
      border-radius: $radius-border

      &.selected
        .card-delete
          @media (max-width: $smscreen)
            display: block

        &:hover .card-delete
          animation: showCardActiveRight $speed-faster forwards ease

      input
        font-size: 1.05em
        padding: 1.25em 1.5em
        font-family: "muliregular"
        height: 100%
        width: calc(100% + 0.5em)

        @media (max-width: $mdscreen)
          font-size: 16px

      .card-delete
        @media (max-width: $smscreen)
          display: none

    .card > p
      margin: 0
      padding: 0

.item-audience
  .audience-definition
    margin: 1.5em 0 2.5em

    &.empty
      .card-selected
        display: none

    &.selected
      .card-selected
        animation: showCardActiveRight $speed-faster forwards ease

    .audience-definition-wrap
      position: relative
      width: 100%
      margin: 0.5em 0

  .audience-demographics
    .audience-age, .audience-skill
      width: 50%
      display: inline-block

      .card
        width: 100%
        min-width: calc(50% - 1em)

    .audience-age
      padding-right: 3em

      @media (max-width: $mdscreen)
        padding-right: 2em

      @media (max-width: $smscreen)
        padding-right: 1.5em

    .audience-skill
      padding-left: 3em

      @media (max-width: $mdscreen)
        padding-left: 2em

      @media (max-width: $smscreen)
        padding-left: 1.5em

.item-details
  .component-section-wrap.done
    .details-category
      .cards-list .card.new
        margin-right: 0.5em

      .cards-list .card.redesign
        margin-left: 0.5em

    .details-category-text, .item-files-dropzone
      display: none

    .details-category
      .details-list
        margin-top: 0
        margin-bottom: 0

      label
        margin-bottom: 0.85em

    .details-due-wrap
      margin: 0.5em 0 0.67em

    .item-files .files-list
      margin-top: 0

      .card
        display: block
        max-width: calc(25% - 1em)
        flex: 100%

  .details-problem
    margin: 1em 0

    &.empty
      .card-selected
        display: none

    &.selected
      .card-selected
        animation: showCardActiveRight $speed-faster forwards ease

    .details-problem-wrap
      position: relative
      width: 100%
      display: inline-block
      margin: 0.25em 0

  .details-other
    margin: 0 0 3em

    &.empty
      .card-selected
        display: none

    &.selected
      .card-selected
        animation: showCardActiveRight $speed-faster forwards ease

    .details-other-wrap
      position: relative
      width: 100%
      display: inline-block
      margin: 0.25em 0

.details-category
  position: relative

  label
    margin-bottom: 1em

  .cards-list
    margin: 1em -0.5em
    position: relative

  .cards-list .card
    width: calc(50% - 2.5em)

    @media (max-width: $smscreen)
      font-size: 1.075em

    &.redesign
      margin-left: 3.5em

    p
      font-size: 1.15em

  .details-category-text
    position: absolute
    font-size: 1.33em
    width: 3em
    text-align: center
    left: 0
    right: 0
    margin: auto
    padding: 0
    top: 0
    bottom: 0
    height: 1em
    color: $color-primary-dark

    @media (max-width: $smscreen)
      margin-top: 1.425em

.details-due
  &.empty
    .card-selected
      display: none

    .card-selected-wrap
      opacity: 0

  &.selected
    &:hover
      .card-delete
        animation: showCardActiveRight $speed-faster forwards ease

    .card-selected
      animation: showCardActiveRight $speed-faster forwards ease

      .icon:before
        animation: showCardActiveCheck $speed-slow forwards ease
        font-weight: 600

    .card-delete-wrap
      animation: showFade $speed-faster $speed-faster forwards ease
      pointer-events: initial

.details-due-wrap
  position: relative
  margin: 1.5em 0 0.5em
  cursor: pointer

  .react-datepicker-wrapper,
  .react-datepicker__input-container
    width: 100%

  .react-datepicker
    font-family: "muliregular"
    border: 0 none
    font-size: 0.925em
    filter: drop-shadow(0 0.075em 0.25em rgba(black, 0.25))
    margin-left: -0.75em

    @media (max-width: $smscreen)
      font-size: 1em

  button
    box-shadow: none
    height: 0
    width: 0
    border-width: 0.67em
    border-radius: 0

    &:hover
      background: none

    &.react-datepicker__navigation--previous
      border-right-color: white
      left: 0.25em
      top: 0.75em

      &:hover
        border-right-color: $color-secondary

    &.react-datepicker__navigation--next
      border-left-color: white
      right: 0.25em
      top: 0.75em

      &:hover
        border-left-color: $color-secondary

  input
    font-size: 1.15em

    @media (max-width: $mdscreen)
      font-size: 16px

  .react-datepicker__triangle
    border-bottom-color: $color-primary

    &:before
      display: none

  .react-datepicker__header
    background-color: $color-primary
    border: 0 none

  .react-datepicker__current-month
    color: white
    padding: 0.33em 0 0.5em
    font-size: 1.15em

  .react-datepicker__day-name
    color: white

  .react-datepicker__day-name, .react-datepicker__day
    width: 2.5em
    margin: 0.25em
    line-height: 2.5em

  .react-datepicker__day--selected
    background-color: $color-primary-dark

.due-date-invalid
  position: absolute
  right: 2em
  top: 1.925em
  font-size: 0.85em
  color: $color-alert
  text-align: right
  line-height: 1.25

  .icon
    position: relative
    top: 0.125em
    font-size: 1.25em
    margin-right: 0.25em
    line-height: 0.75