// Colors
$color-primary: #1FBADB !default
$color-primary-light: lighten($color-primary, 10%) !default
$color-primary-lighter: lighten($color-primary, 15%) !default
$color-primary-lightest: lighten($color-primary, 25%) !default
$color-primary-dark: darken($color-primary, 18%) !default
$color-primary-darker: darken($color-primary, 25%) !default
$color-primary-darkest: darken($color-primary, 33%) !default
$color-primary-access: #1b9fbc !default
$color-primary-accessSm: #168198 !default
$color-secondary: #511FDB !default
$color-secondary-light: lighten($color-secondary, 10%) !default
$color-secondary-lighter: lighten($color-secondary, 18%) !default
$color-secondary-lightest: lighten($color-secondary, 25%) !default
$color-secondary-dark: darken($color-secondary, 12%) !default
$color-secondary-darker: darken($color-secondary, 25%) !default
$color-secondary-darkest: darken($color-secondary, 30%) !default
$color-accent: #E1367B !default
$color-accent-light: lighten($color-accent,10%) !default
$color-accent-lighter: lighten($color-accent,18%) !default
$color-accent-lightest: lighten($color-accent,25%) !default
$color-accent-dark: darken($color-accent, 12%) !default
$color-accent-darker: darken($color-accent, 25%) !default
$color-accent-darkest: darken($color-accent, 30%) !default
$color-tertiary: #E18936 !default
$color-tertiary-dark: darken($color-tertiary, 15%) !default
$color-quaternary: #00bf94 !default
$color-quinary: #BADB1F !default
$color-dark: #888888 !default
$color-darker: #444444 !default
$color-darkest: #111111 !default
$color-body-text: #2b2b2b !default
$color-text-min: #777777 !default

$color-light: #dee3e5 !default
$color-lighter: #e0e7e9 !default
$color-lightest: #f4f9fa !default

$color-warning: #de8c16 !default
$color-alert: #b23735 !default
$color-warning: $color-tertiary !default
$color-success: #5ea230 !default
$color-google: #4285f4 !default

// Sizing
$height-header: 8.5em !default
$width-drawer: 21em !default
$height-styleguide-nav: 5.25em !default

// Radius
$radius-border: 0.25rem !default
$radius-btn: 0.5rem !default

// Timing
$speed-fastest: 0.15s
$speed-faster: 0.25s
$speed-fast: 0.33s
$speed-normal: 0.5s
$speed-slow: 0.67s
$speed-slower: 0.85s
$speed-slowest: 1s

// Resolution
$xxsscreen: 15em !default
$xsscreen: 20em !default
$smscreen: 35.5em !default
$msscreen: 40em !default
$mdscreen: 48em !default
$lgscreen: 64em !default
$bigscreen: 70em !default
$hugescreen: 80em !default
$epicscreen: 120em !default

// Keyframes mixin
=keyframes($name)
  @-webkit-keyframes #{$name}
    @content
  @-moz-keyframes #{$name}
    @content
  @-ms-keyframes #{$name}
    @content
  @keyframes #{$name}
    @content