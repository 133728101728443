html
  height: 100%

  *,*:before
    box-sizing: border-box
    outline-color: $color-accent
    -webkit-tap-highlight-color: transparent

body
  font-family: "mulilight", sans-serif
  font-size: 16px
  line-height: 1
  padding: 0
  margin: 0
  min-height: 100%
  width: 100%
  background-color: $color-lighter
  background-image: url('../images/bg-brilliant.png')
  color: $color-body-text
  transition: background-color ease $speed-normal

  @media (min-width: $epicscreen)
    font-size: 20px  

  @media (max-width: $lgscreen)
    font-size: 14px

  @media (max-width: $mdscreen)
    font-size: 13px

  @media (max-width: $smscreen)
    font-size: 12px

  @media print
    background-image: none

  &:before, &:after
    content: ""
    position: fixed
    top: 0
    left: 0
    height: 100%
    width: 100%
    z-index: -1
    animation: hideFade $speed-fast ease forwards

  &:before
    background: linear-gradient(120deg, $color-primary 33%, $color-secondary)

  &:after
    background: linear-gradient(200deg, $color-secondary 25%, $color-primary 90%)

::selection
  background: $color-secondary
  color: white

div[disabled]
  pointer-events: none

a, .link
  text-decoration: none
  color: $color-primary-dark
  transition: color ease $speed-faster
  cursor: pointer

  @media (max-width: $smscreen)
    transition: none

  &:hover
    color: $color-secondary

  &.link-alert
    color: $color-alert

    &:hover
      color: darken($color-alert, 15%)

  &.link-highlight
    position: relative

    &:hover:before
      background: rgba($color-secondary, 0.75)
      height: 0.075em

    &:before
      content: ""
      position: absolute
      bottom: 0
      left: 0
      width: 100%
      height: 0.175em
      background: rgba($color-primary, 0.75)
      z-index: -1
      transition: background ease $speed-fast, height ease $speed-fastest

h1, h2, h3, h4, h5
  font-family: "muliextralight"
  font-weight: normal
  color: $color-primary-dark
  line-height: 1.33

h1
  font-size: 3em

h2
  font-size: 2.5em

h3
  font-size: 2.25em

h4
  font-size: 2em

h5
  font-size: 1.5em
  font-family: "mulilight"

p
  &.error
    color: $color-alert
    opacity: 0
    animation: showContentFade $speed-faster ease forwards

label
  color: $color-body-text
  display: block
  font-size: 0.925em
  font-family: "muliregular"
  line-height: 1
  margin-bottom: 0.25em

code
  background: $color-darkest
  border-radius: $radius-border
  color: white
  padding: 1.5em 2em

ul.unformatted
  list-style: none
  margin: 0
  padding: 0

  li
    margin: 1.5em 0

form
  input, textarea
    -webkit-appearance: none

input, textarea
  font-family: "mulilight"
  color: $color-body-text
  border-radius: 0
  width: 100%
  padding: 0.85em 1em
  border: none
  background-color: rgba(white, 0.85)
  box-shadow: 0 0.425em 0 -0.25em rgba(black, 0.075), 3px 0 0 $color-primary inset
  transition: background ease $speed-faster, box-shadow ease $speed-faster
  line-height: 1.5em

  @media (max-width: $mdscreen)
    font-size: 16px

  @media (max-width: $smscreen)
    transition: none

  &:hover
    box-shadow: 0 0.33em 0.5em rgba(black, 0.15), 3px 0 0 $color-primary inset

  &:focus
    box-shadow: 0 0.33em 0.5em rgba(black, 0.15), 5px 0 0 $color-accent inset
    outline: 0
    background-color: white

  &:disabled, &:disabled:hover
    opacity: 0.5
    box-shadow: none
    border-width: 0
    box-shadow: none

header
  width: 100%
  height: $height-header
  position: relative
  display: inline-block
  z-index: 1
  padding: 0 1em
  margin-bottom: -0.25em
  opacity: 0
  animation: showFade $speed-slow $speed-faster forwards ease

  @media (max-width: $smscreen)
    padding: 0
    height: calc(#{$height-header} - 2em)

  &:before, &:after
    content: ""
    position: absolute
    bottom: 0
    left: 0
    height: 100%
    width: 100%
    z-index: -1
    outline: 1px solid transparent
    -webkit-backface-visibility: hidden
    animation-delay: $speed-faster

  &:before
    animation: showHeaderBefore $speed-slow forwards ease
    background: linear-gradient(145deg, $color-primary 20%, $color-secondary 90%)

    @media (max-width: $smscreen)
      animation: none

  &:after
    animation: showHeaderAfter $speed-slow forwards ease
    box-shadow: 0 -0.5em 1em rgba(black, 0.075) inset
    background: linear-gradient(120deg, $color-primary 25%, $color-secondary 90%)
    height: 32em

    @media (max-width: $smscreen)
      animation: none

  a, .link
    color: white

    &:active, &:focus, &:hover
      color: white

iframe
  border: none
  background-color: white
  width: 100%

#terms, #privacy
  padding: 0 0 6.67em

  iframe
    min-height: 100vh
    border-radius: $radius-border
    box-shadow: 0 0.15em 0.33em rgba(black, 0.15)

  .content-wrap
    max-width: 64em
    min-width: 0

.header-wrap
  transition: margin ease $speed-faster

.logo-wrap
  float: left
  margin: 2.425em 0.5em 2.425em 3em
  opacity: 0
  animation: showFade $speed-slow forwards ease
  animation-delay: $speed-faster
  outline: 0

  @media (max-width: $lgscreen)
    margin-left: 2.5em
    margin-top: 2.33em

  @media (max-width: $mdscreen)
    margin-left: 1.5em

  @media (max-width: $smscreen)
    margin: 1.33em 0.25em 1.33em 1.5em

  @media (max-width: $xsscreen)
    margin-left: 1em
    margin-top: 1.5em

  a:hover
    img
      background: darken($color-secondary, 33%)
      box-shadow: 0 0.25em 0.5em rgba(black, 0.15)

    p
      color: darken($color-secondary, 30%)

  img
    width: 3em
    padding: 0.25em
    background-color: white
    border-radius: $radius-border
    box-shadow: 0 0.33em 0 -0.15em rgba(black, 0.15), 0 -0.075em 0.15em rgba(black, 0.075)
    vertical-align: top
    transition: background ease $speed-normal, box-shadow ease $speed-normal

    @media (max-width: $lgscreen)
      width: 3.25em

    @media (max-width: $mdscreen)
      width: 3.33em

    @media (max-width: $smscreen)
      width: 3.67em

    @media (max-width: $xsscreen)
      width: 3.25em

  p
    padding: 0.25em 0 0.25em 0.575em
    margin: 0
    display: inline-block
    font-size: 2em
    vertical-align: top
    text-shadow: 0 0.075em 0.15em rgba(black, 0.125)
    transition: color ease $speed-normal
    line-height: 1em

    @media (max-width: $lgscreen)
      padding-top: 0.33em

    @media (max-width: $mdscreen)
      padding-top: 0.425em

    @media (max-width: $smscreen)
      display: none

.no-photo .profile-img-wrap
  .profile-initials
    display: block

.promote-false nav.nav-main
  .account-upgrade
    display: none

nav.nav-main
  float: right
  margin: 1.75em 3em 0 0
  opacity: 0
  animation: showFade $speed-slow forwards ease
  animation-delay: $speed-faster

  @media (max-width: $lgscreen)
    margin-right: 2.5em

  @media (max-width: $mdscreen)
    margin-top: 2em
    margin-right: 1.5em

  @media (max-width: $smscreen)
    margin: 1.33em 1.5em 0 0

  @media (max-width: $xsscreen)
    margin-right: 1em

  a, .link, a.menu-item
    font-size: 1.15em
    line-height: 1em
    font-family: "muliregular"
    padding: 1.5em
    text-shadow: 0 0.075em 0.075em rgba(black, 0.2)
    text-transform: lowercase

    @media (max-width: $smscreen)
      padding: 1.5em 0.85em
      font-size: 1em

    @media (max-width: $xsscreen)
      padding: 1.5em 0.75em
      font-size: 0.875em

    &:hover
      color: lighten($color-quaternary, 20%)

    &:before
      content: ""
      position: absolute
      height: 0.375rem
      width: 0.375rem
      background: white
      border-radius: 100%
      left: 0
      right: 0
      margin: auto
      bottom: 0.175em
      text-align: center
      opacity: 0
      box-shadow: 0 1px 1px rgba(black, 0.15)

      @media (max-width: $smscreen)
        height: 0.25rem
        width: 0.25rem
        bottom: 0.33em

    &.new
      position: relative
      text-align: center
      display: inline-flex
      justify-content: center

      &:after
        content: "new"
        position: absolute
        top: 0.33em
        text-align: center
        font-size: 0.575em
        color: lighten($color-quaternary, 15%)
        font-family: Helvetica, Arial, sans-serif
        font-weight: 900
        letter-spacing: 1px
        text-transform: uppercase
        text-shadow: 0 1px 1px rgba(black, 0.5)

  .profile-menu
    &.upgrade:after
      position: absolute
      content: ""
      top: 0.525em
      right: 0
      height: 0.925em
      width: 0.925em
      border-radius: 100%
      background: $color-accent
      box-shadow: 0 0 0 0.0925em white inset, 0 0.075em 0.15em rgba(black, 0.15)

    div.dropdown
      margin: 0.5em 0 0 1.5em

      @media (max-width: $mdscreen)
        margin-top: 0

      @media (max-width: $smscreen)
        margin: 0 0 0 0.75em
        font-size: 1.075em

      .menu
        padding: 0.75em 1em 1em
        margin-top: -0.33em
        background: darken($color-secondary-darker, 10%)

        @media (max-width: $smscreen)
          margin-top: 0

        &:before
          border-bottom-color: darken($color-secondary-darker, 10%)

        a, .link
          text-transform: lowercase

          &.upgrade
            font-family: "muliregular"
            margin: 0.875em 0 0.5em
            font-size: 1em
            position: relative

            &:hover
              color: $color-accent

            &:after
              position: absolute
              content: ""
              top: -0.375em
              right: -0.425em
              height: 0.85em
              width: 0.85em
              border-radius: 100%
              background: $color-accent
              box-shadow: 0 0 0 0.0925em white inset, 0 0.075em 0.15em rgba(black, 0.15)

  .profile-img-wrap
    margin: 0.5em auto

    @media (max-width: $mdscreen)
      margin: 0.33em auto

    @media (max-width: $smscreen)
      margin: 0.075em auto

    .icon:before
      content: ""

    .profile-initials
      font-size: 1.5em

  .login, .logout
    margin: 0
    padding: 0

    button
      font-size: 1em

    button, &.btn
      padding: 0.4em 1em 0.6em
      margin-left: 1.5em
      min-width: 0
      line-height: 1.33

      @media (max-width: $smscreen)
        margin-left: 0.75em
        padding: 0.5em 0.75em

    &.btn
      font-size: 1.15em

      &:hover
        color: white

footer
  width: 100%
  position: relative
  bottom: 0
  z-index: 0
  display: inline-block
  z-index: 1
  padding: 0 1.5em
  margin: 0 0 -1em
  box-shadow: 0 -0.25em 0.5em rgba(black, 0.075) inset
  background: linear-gradient(145deg, $color-primary 20%, $color-secondary 85%)
  opacity: 0
  animation: showFade $speed-slow forwards ease
  animation-delay: $speed-slow * 1.5

  @media (max-width: $smscreen)
    padding: 0 0 2em
    text-align: center
    background: linear-gradient(110deg, $color-primary 20%, $color-secondary 100%)
 
  &:before
    content: ""
    position: absolute
    top: -0.75em
    left: 0
    height: 100%
    width: 100%
    z-index: -1
    outline: 1px solid transparent
    -webkit-backface-visibility: hidden
    opacity: 0.85
    transform: skewY(-0.5deg)
    box-shadow: 0 -0.5em 1em rgba(black, 0.075) inset
    background: linear-gradient(145deg, $color-primary 20%, $color-secondary 90%)

    @media (max-width: $smscreen)
      background: linear-gradient(110deg, $color-primary 20%, $color-secondary 100%)

  &:after
    content: ""
    position: fixed
    top: 100%
    left: 0
    height: 16em
    width: 100%
    z-index: -1
    outline: 1px solid transparent
    -webkit-backface-visibility: hidden
    opacity: 0.825
    box-shadow: 0 -0.5em 1em rgba(black, 0.075) inset
    background: linear-gradient(100deg, $color-primary 20%, $color-secondary 90%)

    @media (max-width: $smscreen)
      background: linear-gradient(90deg, $color-primary 20%, $color-secondary 100%)

  .social-wrap
    font-size: 0.85em
    padding: 1.5em 1em 1em
    margin: 1.25em 1.5em 0 0
    float: left

    @media (max-width: $smscreen)
      float: none
      margin: 1.5em auto 0
      padding-bottom: 0.5em

    .icon
      font-size: 2.25em
      margin: 0 0.67em
      display: inline-block

  a, .link
    color: white
    padding: 0.25em
    font-size: 0.925em

  nav.nav-footer
    float: right
    margin: 0 1em 1.25em

    @media (max-width: $smscreen)
      float: none
      margin: 0 auto 1em

    ul
      margin: 1em 0
      padding: 0
      justify-content: flex-end

      @media (max-width: $smscreen)
        margin: 0.5em 0 1em

      ul
        display: flex
        margin: 0
        
        li
          font-size: 0.875em

          a, .link
            text-decoration: underline

            &:hover
              text-decoration: none

    li
      display: inline-block
      list-style: none
      margin: 1em

      @media (max-width: $smscreen)
        margin: 1em 0.75em

      @media (max-width: $xsscreen)
        margin: 1em 0.5em

    a:hover, .link:hover
      color: $color-primary-light

    .footer-copy
      margin: 0.75em 1.5em 1em
      padding: 0
      float: right
      font-size: 0.75em
      opacity: 0.5
      color: white
      font-family: "muliregular"
      transition: opacity ease $speed-faster

      @media (max-width: $smscreen)
        float: none
        margin: 0 auto

      &:hover
        opacity: 1

/* Content Specific */

#root
  transition: filter ease $speed-slow

  @media (max-width: $smscreen)
    transition: none

.content-wrap
  margin: 0 auto
  max-width: 70em
  padding: 2em

  @media (max-width: $lgscreen)
    padding: 2em 3em

  @media (max-width: $mdscreen)
    padding: 1.5em 2em

  @media (max-width: $smscreen)
    padding: 0.75em 1.5em

  @media (min-width: $hugescreen)
    min-width: 75em

  @media (min-width: $epicscreen)
    min-width: 80em

  h1
    margin: 0.67em 0

    @media (max-width: $smscreen)
      font-size: 2.25em
      margin: 1em 0

.content-loading
  text-align: center
  margin-top: 5vh
  padding-bottom: 10em
  background-image: url('../images/loading.svg')
  background-position: center bottom
  background-size: 10em
  background-repeat: no-repeat
  opacity: 0
  animation: showContentFade $speed-normal $speed-fast forwards ease
  filter: drop-shadow(0 0.215em 0.425em rgba(black, 0.0925))

  &.no-header
    position: fixed
    width: 100%
    top: -1em
    left: 0
    bottom: 0
    margin: auto
    padding: 0
    height: 10em

  &.no-body
    width: 100%
    height: calc(100vh - #{$height-header})
    margin-top: 0
    background-image: none
    animation: none

  &.no-spinner
    background: none

    svg
      height: 10em

.content-outer
  height: 100%
  min-height: calc(100vh - #{$height-header})

  @media (max-width: $smscreen)
    min-height: calc(100vh - #{$height-header} + 2em)

.content-bg
  background: $color-lightest
  padding-bottom: 5em

.content-inner
  background: $color-lightest
  padding: 2.5em 2.5em
  border-radius: $radius-border
  box-shadow: 0 0.25em 0.75em rgba(black, 0.075)

  @media (max-width: $smscreen)
    padding: 2em 1.5em

.content-full
  header, footer
    display: none

.inline-loading
  background-image: url('../images/loading-md.svg')
  background-position: center bottom
  background-size: 4em
  background-repeat: no-repeat
  opacity: 0
  animation: showFade $speed-slow forwards ease
  height: 4em
  width: 4em

.msg-logged-in
  max-width: 48em
  margin: 1em auto
  opacity: 0
  animation: showFade $speed-slow forwards ease
  animation-delay: $speed-faster

  h1
    padding-bottom: 0.25em

.parallax
  position: relative
  transform: translate3d(0,0,0)

  @media (max-width: $mdscreen)
    transform: none !important

.progressive
  &.loading
    background-size: 14em
    background-repeat: no-repeat
    background-position: center center
    background-image: url('../images/loading-md.svg')
    opacity: 1

  &.done
    animation: showFade $speed-normal forwards ease
    opacity: 0

  &.blur
    transition: filter ease $speed-slow
    
img.progressive.loading
  background-image: none
  background-size: inherit

$num-colors: 20
$base-color: $color-primary-light
$base-color-positive: saturate($color-alert, 25%)
$spectrum: 200deg
$offset: 0deg

.progress-bar
  display: inline-block
  width: 100%
  height: 0.425em
  border-radius: $radius-border
  box-shadow: 0 0.075em 0.075em rgba(black, 0.075)
  background: white
  overflow: hidden
  position: relative

  &.positive
    @for $i from 0 to $num-colors
      &.done-#{$i * 5}, &.done-#{$i * 5 + 1}, &.done-#{$i * 5 + 2}, &.done-#{$i * 5 + 3}, &.done-#{$i * 5 + 4}, &.done-#{$i * 5 + 5}
        $adjusted-color: adjust-hue($base-color-positive, calc($offset - $spectrum / $num-colors * $i))

        .progress-bar-fill
          background: $adjusted-color

    &.burst, &.done-100
      .progress-bar-fill
        background: saturate($color-success, 25%)

  &.negative
    @for $i from 0 to $num-colors
      &.done-#{$i * 5}, &.done-#{$i * 5 + 1}, &.done-#{$i * 5 + 2}, &.done-#{$i * 5 + 3}, &.done-#{$i * 5 + 4}, &.done-#{$i * 5 + 5}
        $adjusted-color: adjust-hue($base-color, calc($offset - $spectrum / $num-colors * $i))

        .progress-bar-fill
          background: $adjusted-color

    &.burst, &.done-100
      .progress-bar-fill
        background: $color-alert

  .progress-bar-fill
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    background: $color-primary
    transition: width ease $speed-faster
    border-radius: 0 $radius-border $radius-border 0

/* Page Transitions */

.home
  #home
    opacity: 0
    animation: showFade $speed-slow forwards ease

  .hero-home-inner
    opacity: 0
    animation: showContentFade $speed-slow forwards ease
    animation-delay: $speed-faster

.demo-intro
  .nav-main .menu-item.demo
    cursor: default
    color: white

    &:before
      animation: showContentFadeUp $speed-fast forwards ease

.about
  .nav-main .menu-item.about
    cursor: default
    color: white

    &:before
      animation: showContentFadeUp $speed-fast forwards ease

  #about
    opacity: 0
    animation: showFade $speed-slow forwards ease

.signup
  .nav-main .menu-item.signup
    cursor: default
    color: white

    &:before
      animation: showContentFadeUp $speed-fast forwards ease

  #signup
    opacity: 0
    animation: showContentFade $speed-slow forwards ease
    animation-delay: $speed-faster

.login
  #login
    opacity: 0
    animation: showContentFade $speed-slow forwards ease
    animation-delay: $speed-faster

.projects
  .nav-main .menu-item.projects
    cursor: default
    color: white

    &:before
      animation: showContentFadeUp $speed-fast forwards ease

.styleguides
  .nav-main .menu-item.styleguides
    cursor: default
    color: white

    &:before
      animation: showContentFadeUp $speed-fast forwards ease

.showcases
  .nav-main .menu-item.showcases
    cursor: default
    color: white

    &:before
      animation: showContentFadeUp $speed-fast forwards ease
    
.project, .project-publish
  #project-edit, #project
    transform: none

.styleguide, .styleguide-publish
  #styleguide-edit, #styleguide
    transform: none

.showcase, .showcase-publish
  #showcase-edit, #showcase
    transform: none

.account
  #account
    opacity: 0
    animation: showFade $speed-slow forwards ease

.account-upgrade
  #account-upgrade
    opacity: 0
    animation: showContentFade $speed-slow forwards ease

.account-billing
  #account-billing
    opacity: 0
    animation: showContentFade $speed-slow forwards ease
    animation-delay: $speed-faster

.pass-forget
  #pass-forget
    opacity: 0
    animation: showContentFade $speed-slow forwards ease

.pass-change
  #pass-change
    opacity: 0
    animation: showContentFade $speed-slow forwards ease

.contact
  #contact
    opacity: 0
    animation: showContentFade $speed-slow forwards ease

.page-not-found, .page-locked, .iframe-error
  opacity: 0
  animation: showContentFade $speed-slow forwards ease

  .icon-large
    font-size: 12em
    display: block
    padding: 0.33em 0 0
    text-align: center
    color: $color-secondary

  h1
    margin: 0
    padding: 0.5em 1em 2em
    text-align: center
    color: $color-secondary

.error
  color: $color-alert

/* Fixed Nav */

.home, .about
  .app-wrap
    padding-top: $height-header
    min-height: calc(100vh - #{$height-header})

    @media (max-width: $smscreen)
      padding-top: calc(#{$height-header} - 2em)

  .header-wrap
    position: absolute
    top: 0
    left: 0
    width: 100%
    transition: transform ease $speed-faster, margin ease $speed-faster

    &.show, &.hide
      position: fixed
      top: 0
      left: 0
      height: calc(#{$height-header} / 1.25)
      transform: none
      filter: drop-shadow(0 0.25em 0.5em rgba(black, 0.33))
      z-index: 2
      background: linear-gradient(rgba($color-secondary, 0.75), transparent)

      @media (max-width: $smscreen)
        background: linear-gradient(rgba($color-secondary, 0.75), transparent)
        height: calc(#{$height-header} / 1.5)

      &:before, &:after
        visibility: hidden

      .logo-wrap
        margin-top: 1em
        margin-bottom: 1em

        @media (max-width: $smscreen)
          margin-top: 0.75em
          margin-bottom: 0.75em

      .nav-main
        margin-top: 0.25em

        @media (max-width: $smscreen)
          margin-top: 0.75em

    &.active
      transform: translateY(-$height-header)

    &.show
      transform: none

    &.hide
      transform: translateY(-$height-header)

.bg-texture
  content: ""
  position: absolute
  top: 0
  left: 0
  height: 100%
  width: 100%
  background-image: url('../images/bg-brilliant.png')
  filter: brightness(0.15)

  &.light
    filter: brightness(0.75)

    @media (max-width: $smscreen)
      filter: brightness(1)


/* 3rd Party Overrides */

.react-confirm-alert
  font: inherit
  border-radius: $radius-border
  box-shadow: 0 0.5em 1em rgba(black, 0.5)
  padding: 1.5em
  margin: 1.5em
  min-width: 30em
  max-width: 90vw

  .react-confirm-alert-button-group
    float: right

.react-confirm-alert-overlay
  position: fixed
  top: 0
  left: 0
  height: 100%
  width: 100%
  overflow: hidden
  background: rgba(black, 0.75)

/* Symantic UI */

body.dimmed
  #root
    // filter: blur(4px)

  .item-nav
    animation: none
    opacity: 1
    
.ui
  &.menu
    div.dropdown
      outline: 0

      &.left
        .menu
          right: auto
          left: 0

          &:before
            right: auto
            left: 1.25em

      &.center
        .menu
          left: 50%
          transform: translateX(-50%)
          right: auto

          &:before
            left: 0
            right: 0
            margin: auto
            width: 0

      &.visible
        .trigger .icon
          transform: scaleY(-1)

      .trigger
        color: $color-primary-dark
        cursor: pointer
        padding: 0.5em 0
        transition: color ease $speed-faster
        opacity: 0
        animation: showFade $speed-normal forwards ease
        position: relative
        display: flex
        flex-flow: row nowrap
        align-items: center
        font-family: "muliregular"

        &:hover
          color: $color-secondary

        p
          display: inline-block

        .icon
          display: inline-block
          font-size: 0.75rem
          position: relative
          margin-left: 0.85em
          margin-top: 0.1em
          transition: transform ease $speed-faster
          transform: none
          transform-origin: center center
          width: 1em
          flex: 1
          text-align: right
          font-weight: 600

          @media (max-width: $smscreen)
            font-size: 0.85em
            top: 0.075em

      .menu
        z-index: 2
        display: block
        pointer-events: none
        position: absolute
        right: 0
        background: darken($color-primary-darkest, 10%)
        padding: 0.75em 1.25em 1em 1em
        border-radius: $radius-btn
        filter: drop-shadow(0 0.125em 0.25em rgba(black, 0.25))
        opacity: 0
        line-height: 1.5
        transition: opacity ease $speed-faster
        text-transform: capitalize

        &:before
          content: ""
          position: absolute
          bottom: 100%
          right: 1.25em
          border-style: solid
          border-width: 0.5em
          border-color: transparent transparent darken($color-primary-darkest, 10%)

        &.visible
          pointer-events: initial
          opacity: 1

        .menu-item
          display: block
          width: 100%
          white-space: nowrap
          color: white

          &:hover
            color: $color-primary-lighter

          &:last-child
            margin: 0

        a, .link
          display: block
          width: 100%
          color: $color-lighter
          padding: 0.5em
          text-align: center
          margin: 0 0 0.5em
          text-shadow: 0 0.075em 0.075em rgba(black,0.075)
          font-family: "muliregular"

          &:hover
            color: $color-primary-lighter

        .btn
          width: 100%
          margin: 0.5em 0 0
          padding: 0.425em 1.5em 0.575em

    .menu
      display: inline-block
      vertical-align: top

    .menu-item
      display: inline-block
      position: relative

  &.sidebar
    position: fixed
    top: 0
    right: 0
    height: 100%
    width: $width-drawer
    background: lighten($color-darkest, 4%)
    margin: 0
    padding: 0
    border-radius: 0
    box-shadow: none
    opacity: 1
    color: white
    transition: transform ease $speed-fast, box-shadow ease $speed-fast
    transform: translateX($width-drawer)

    &.visible
      box-shadow: 0 -1em 1.5em rgba(black, 0.15)
      transform: none

    .item-nav-close
      position: fixed
      top: 0
      right: 0
      padding: 0.25em
      cursor: pointer
      z-index: 1

      &:before
        content: ""
        position: absolute
        background: black
        transform: rotate(45deg)
        height: 6.33em
        width: 6.33em
        top: -3.33em
        right: -3.33em
        box-shadow: 0 0 0 0.115em rgba(black, 0.085)
        transition: box-shadow ease $speed-faster

      &:hover
        &:before
          box-shadow: 0.15em 0.15em 0.33em 0.075em rgba(black, 0.115)

        .icon
          color: $color-alert

      .icon
        color: white
        font-size: 1.075em
        transition: color ease $speed-faster
        margin: 0
        position: relative
        top: 0.275em
        right: 0.375em

  &.selection
    font-family: "mulilight"
    color: $color-body-text
    width: 100%
    padding: 0.85em 3.5em 0.85em 1em
    background-color: rgba(white, 0.85)
    box-shadow: 0 0.425em 0 -0.25em rgba(black, 0.075), 3px 0 0 $color-primary inset
    transition: background ease $speed-faster, box-shadow ease $speed-faster, padding ease $speed-faster
    line-height: 1.5em
    position: relative
    outline: 0
    cursor: pointer
    overflow: hidden
    min-height: 3em

    &:hover
      box-shadow: 0 0.33em 0.5em rgba(black, 0.15), 3px 0 0 $color-primary inset

    &.search
      padding: 1.06em 3.5em 1.06em 1em

      input
        position: absolute
        background: none
        box-shadow: none
        padding: 0
        width: calc(100% - 4em)

      .icon
        top: 0.925em

      .text
        min-height: 1.5em

      .menu
        max-height: 16em
        overflow: auto

      .menu .menu-item:nth-child(1)
        height: auto
        padding: 0.75em 1.25em
        visibility: visible

      .message
        padding: 0.75em 1.25em

    &.visible
      box-shadow: 0 0.33em 0.5em rgba(black, 0.15), 5px 0 0 $color-accent inset
      overflow: visible
      z-index: 1

      .icon
        transform: scaleY(-1)

    .icon
      font-family: "icomoon"
      height: 1.5em
      width: 1em
      position: absolute
      right: 1.25em
      top: 0.85em
      color: $color-body-text
      font-style: normal
      transform: none
      transform-origin: center center
      transition: transform ease $speed-faster

      &:before
        content: "\e93a"
    
    .menu
      display: block
      font-size: 0.85em
      pointer-events: none
      position: absolute
      left: 0
      top: 100%
      width: 100%
      background: $color-lightest
      box-shadow: 0 0.33em 0.5em rgba(black, 0.15), 5px 0 0 $color-accent inset, 0 0.075em 0.15em rgba(black, 0.15) inset
      opacity: 0
      line-height: 1.5
      transition: opacity ease $speed-faster
      text-transform: capitalize
      z-index: -1

      &.visible
        pointer-events: initial
        opacity: 1

      .menu-item, .item
        padding: 0.75em 1.25em
        margin-left: 5px
        transition: background ease $speed-faster, color ease $speed-faster

        &:nth-child(1)
          height: 0
          padding: 0
          visibility: hidden

        &:hover
          background: darken($color-accent, 7.5%)
          color: white

        &.selected
          background: $color-accent
          color: white

    .default
      color: $color-text-min

  &.modals
    position: fixed
    top: 0
    left: 0
    height: 100%
    width: 100%
    overflow: hidden
    background: rgba(black, 0.75)
    z-index: 100
    opacity: 0

    &.active
      animation: showFade $speed-normal forwards ease 

    .modal
      position: relative
      margin: auto
      z-index: 101
      background: $color-lighter
      border-radius: $radius-border
      box-shadow: 0 0.5em 1em rgba(black, 0.5)
      display: inline-block
      align-self: center
      width: 50%
      padding: 0
      overflow: auto
      max-height: 95vh
      max-width: calc(100vw - 1em)
      opacity: 0
      animation: showModal $speed-normal forwards ease

      @media (max-width: $mdscreen)
        width: 75%

      @media (max-width: $smscreen)
        width: 95%

      &.share-modal
        max-width: 40em

      &.video-modal
        width: calc(726px + 2em)

        iframe
          max-width: 100%
          max-height: calc(95vh - 2em)

      &.confirm
        background: $color-lightest
        padding: 1.25em 2.25em
        max-width: 40em
        
        &.delete
          .actions button
            float: right

        &.save
          .header
            color: darken($color-success, 10%)

          .actions button.primary
            background: $color-success

            &:hover
              background: adjust-hue($color-success, 15deg)

        &.progressive
          .header
            color: $color-primary-dark

          .actions button.primary
            background: $color-primary

            &:hover
              background: adjust-hue($color-primary, 15deg)

        .header
          color: $color-alert

        .content
          font-size: 1.15em
          padding: 0.5em 0
          line-height: 1.33

        button
          background: $color-text-min

          &:hover
            background: $color-darker

          &.primary
            background: $color-alert

            &:hover
              background: darken($color-alert, 10%)

      &.loading
        background-image: url('../images/loading.svg')
        background-position: center center
        background-size: 25%
        background-repeat: no-repeat

        &.delete
          text-align: center
          background-position: center 25%
          pointer-events: none

          &:after
            content: "Deleting..."
            position: absolute
            font-size: 2.25em
            color: $color-alert
            bottom: 1.33em
            left: 50%
            transform: translateX(-50%)
            animation: pulseFade $speed-slowest infinite ease 
            opacity: 1

        *
          opacity: 0

      ::selection
        background: $color-secondary

      h1, .header
        font-size: 2em
        color: $color-primary-dark
        font-family: "mulilight"
        padding: 0.25em 0
        line-height: 1.33

      h2
        margin: 0.75em 0.5em 0.25em
        color: $color-primary-dark

      h3
        color: $color-body-text
        font-size: 1.25em
        margin: 1em 0
        font-family: "mulilight"

      .content
        padding: 1em

      .centered
        text-align: center

      .actions
        float: right
        margin-top: 1.5em
        margin-right: -0.75em

        button
          margin: 1em 0.5em 0.5em
          font-size: 1em

      .modal-close-wrap
        position: fixed
        height: 5.75em
        width: 5.75em
        overflow: hidden
        border-radius: $radius-border 0 0
        z-index: 1

      .modal-close
        position: absolute
        cursor: pointer
        font-size: 1.5em

        &:before
          content: ""
          position: absolute
          height: 5em
          width: 5em
          top: -2.5em
          left: -2.5em
          background-color: white
          transform: rotate(45deg)
          transform-origin: center center
          box-shadow: 0 0 0 0.085em rgba(black, 0.05)
          transition: box-shadow ease $speed-faster, background ease $speed-faster

        &:hover
          &:before
            background-color: $color-lightest
            box-shadow: 0.15em 0 0.33em 0.05em rgba(black, 0.075)

          .icon
            color: $color-accent

        .icon
          color: $color-body-text
          transition: color ease $speed-faster
          position: relative
          z-index: 1
          top: 0.375em
          left: 0.5em
          font-size: 1.15em

          @media (max-width: $smscreen)
            transition: none

      #form-login
        margin: 0 auto
        background: none
        box-shadow: none

  &.popup
    background: white
    padding: 0.75em 1.15em 1em
    border-radius: $radius-border
    font-size: 0.85em
    filter: drop-shadow(0 0.075em 0.15em rgba(black, 0.25))
    position: relative
    min-width: 4em
    z-index: 101
    line-height: 1.25
    opacity: 0
    animation: showPopUp $speed-faster forwards ease

    &.bottom
      margin-top: 0.25em
      animation-name: showPopDown

      &:before
        top: auto
        bottom: 100%
        border-color: transparent transparent white

    &.item-members
      text-align: center
      margin-bottom: 0.33em

    &.popup-help, &.font-variants-info, &.popup-share-help
      margin-right: -1.425em
      max-width: 20em
      line-height: 1.33
      padding: 1em 1.25em
      margin-bottom: 0.33em
      pointer-events: none

      &.publish
        margin-right: -0.75em

      &:before
        left: calc(100% - 3.085em)

        @media (max-width: $lgscreen)
          left: calc(100% - 3.275em)

      p
        margin: 0
        padding: 0

        .popup-tip
          display: block
          margin-top: 0.5em

          strong
            color: $color-primary
            font-weight: 600

      ul
        margin: 0.75em 0 0.5em

    &.popup-share-help
      margin-right: -2.25em

    &.popup-settings
      margin-bottom: 0.5em
      max-width: 12.5em

    &.popup-icon
      margin-bottom: 0.5em

      h5
        margin: 0 0 0.25em
        font-size: 1.15em
        padding: 0

      p
        margin: 0 0 0.25em
        padding: 0

    &.popup-item-footer
      max-width: 16em

    &:before
      content: ""
      position: absolute
      top: 100%
      left: calc(50% - 0.5em)
      margin: auto
      border-style: solid
      border-width: 0.5em
      border-color: white transparent transparent

    .item-user-name
      display: block
      text-align: left
      padding: 0.5em 0

  &.checkbox
    position: relative
    display: inline-block
    -webkit-backface-visibility: hidden
    backface-visibility: hidden
    outline: 0
    vertical-align: baseline
    font-style: normal
    min-height: 1.5em
    font-size: 1em
    line-height: 1em
    min-width: 1em

    &.radio
      input
        &:checked~label
          &:before
            border-color: white
            background: $color-success

          &:after
            border-color: darken($color-success, 10%)

      label
        margin-top: 0.085em

        &:before
          content: ""
          z-index: 1
          height: calc(1.25em - 2px)
          width: calc(1.25em - 2px)
          border-radius: 100%
          background: transparent
          border: 3px solid transparent
          top: 1px
          left: 1px
          font-size: 1em
          transition: background ease $speed-faster, border ease $speed-faster
          
        &:after
          z-index: 0
          height: calc(1.25em - 2px)
          width: calc(1.25em - 2px)
          border-radius: 100%
          transition: background ease $speed-faster, border ease $speed-faster
          border-width: 1px

    &.fitted.toggle, &.fitted.slider
      width: 3.5em

      label
        margin-left: 0

    &.toggle, &.slider
      input
        &:checked~label
          color: rgba(black, 0.95)

      label
        cursor: pointer
        margin-left: 1em
        margin-top: 0.15em

        &:before
          content: ""
          z-index: 1
          width: 3.5em
          transition: background ease $speed-faster
          font-size: 1em
          
        &:after
          background: $color-dark
          z-index: 1
          border: none
          width: 1.5em
          height: 1.5em
          border-radius: 100%
          transition: left ease $speed-faster, background ease $speed-faster, border-color ease $speed-faster

    &.toggle
      label
        margin-left: 3.25em

      label:before
        box-shadow: 0 0.25em 0 -0.15em rgba(black, 0.075)
        top: 0
        left: 0
        height: 1.5em
        background: white
        border-radius: 500rem
        box-shadow: 0 1px 0 1px rgba(black, 0.085) inset

      input:checked~label
        &:before
          background: lighten($color-success, 10%)
          content: "\e934"
          font-family: "icomoon"
          padding: 0.25em 0.5em
          color: white

        &:after
          left: 2.15em
          background: darken($color-success, 15%)

    &.toggle.optional
      label:before
        background-color: lighten($color-primary, 15%)
        box-shadow: 0 1px 0 1px rgba(black, 0.085) inset

      label:after
        background: darken($color-primary, 10%)
        box-shadow: 0 0 0 2px lighten($color-primary, 15%) inset

      input:checked~label
        &:before
          background: lighten($color-secondary, 20%)
          content: ""

        &:after
          background: darken($color-secondary, 10%)
          box-shadow: 0 0 0 2px lighten($color-secondary, 20%) inset

    &.slider
      label
        margin-left: 3.25em

      label:before
        top: 0.67em
        left: 0
        height: 0.25em
        background: white
        box-shadow: 0 1px 1px rgba(black, 0.075)
        border-radius: 2px

      input:checked~label
        &:before
          background: lighten($color-success, 10%)

        &:after
          left: 2.15em
          background: darken($color-success, 15%)

    input
      width: 3.5em
      height: 1.5em

      &[type=checkbox]
        cursor: pointer
        position: absolute
        top: 0
        left: 0
        opacity: 0 !important
        outline: 0
        z-index: 3
        width: 1em
        height: 1em

      &:checked~label
        border-color: rgba(black, 0.95)

        &:before
          color: white

        &:after
          background: $color-success
          border-color: $color-success

      &.hidden
        z-index: -1

    label
      cursor: pointer
      margin-left: 1em
      margin-top: 0.15em

      &:before
        display: block
        position: absolute
        content: "\e934"
        font-family: 'icomoon'
        z-index: 1
        transform: none
        border: none
        color: $color-light
        top: 0.5em
        left: 0.5em
        font-size: 0.75em
        font-weight: 900
        
      &:after
        background: white
        position: absolute
        content: ""
        opacity: 1
        z-index: 0
        border: 1px solid $color-dark
        width: calc(1.5em - 2px)
        height: calc(1.5em - 2px)
        top: 0
        left: 0
        border-radius: $radius-border
        transition: left ease $speed-faster, background ease $speed-faster
        box-shadow: 0 1px 1px rgba(black, 0.15)

.index, .home, .about, .contact, .terms, .privacy, .item-publish
  .toast-wrap
    display: none

.toast-wrap
  position: fixed
  z-index: 100
  bottom: 1.5em
  left: 50%
  transform: translateX(-50%)

  .toast-content
    display: flex
    flex-flow: row nowrap
    align-items: center
    align-self: center
    justify-content: center
    background: rgba($color-secondary, 0.975)
    padding: 0.75em
    color: white
    border-radius: $radius-btn
    box-shadow: 0 0.5em 1em rgba(black, 0.25)
    opacity: 0
    animation: showContentFadeUp $speed-slow ease forwards
    white-space: nowrap

    p
      margin: 0 0.5em
      padding: 0
      font-size: 0.925em
      font-family: "muliregular"

    button.btn
      margin-left: 1em
      