body.about
  &:before
    animation: hideFade $speed-slower ease forwards

  &:after
    animation: showFade $speed-slower ease forwards

  .app-wrap
    overflow: hidden
    position: relative

  header
    &:before, &:after
      box-shadow: none
      display: none

  footer
    &.styleguides
      background: linear-gradient(145deg, $color-secondary 20%, $color-accent 85%)

      &:before
        background: linear-gradient(145deg, $color-secondary 20%, $color-accent 90%)

    &.showcases
      background: linear-gradient(145deg, $color-accent 20%, $color-tertiary 85%)

      &:before
        background: linear-gradient(145deg, $color-accent 20%, $color-tertiary 90%)

#about
  position: relative
  min-height: calc(100vh - #{$height-header})
  opacity: 0
  animation: showFade $speed-slow ease forwards

  &.projects
    .tour-screen
      opacity: 0
      animation: showFade $speed-faster $speed-fastest ease forwards

  &.styleguides 
    .tour-section
      &:before
        border-left-color: $color-secondary

      h2
        color: $color-secondary

        .tour-num:before
          background: $color-secondary

    .screen-section
      @media (max-width: $smscreen)
        background: $color-secondary-lightest
        box-shadow: 0 1em 1.5em -0.5em rgba($color-secondary, 0.5)

    .tour-screen
      background-color: $color-secondary-lightest
      box-shadow: 0 1em 1.5em -0.5em rgba($color-secondary, 0.5)
      opacity: 0
      animation: showFade $speed-faster $speed-fastest ease forwards

      .screen-section .ui.input:after
        left: 7.5em

    .files-dropzone, .files-drop-progress
      background-color: darken($color-secondary-darkest, 5%)

    .files-dropzone > .btn
      background-color: $color-secondary

    .drop-progress-bar
      background-color: $color-secondary

    .item-publish
      transition-duration: $speed-slowest * 5

  &.showcases 
    .tour-section
      &:before
        border-left-color: $color-accent

      h2
        color: $color-accent

        .tour-num:before
          background: $color-accent

    .screen-section
      @media (max-width: $smscreen)
        background: lighten($color-accent, 15%)
        box-shadow: 0 1em 1.5em -0.5em rgba($color-accent, 0.5)

    .tour-screen
      background-color: lighten($color-accent, 15%)
      box-shadow: 0 1em 1.5em -0.5em rgba($color-accent, 0.5)
      opacity: 0
      animation: showFade $speed-faster $speed-fastest ease forwards

      .screen-section .ui.input:after
        left: 7.5em

    .files-dropzone, .files-drop-progress
      background-color: darken($color-accent-darkest, 5%)

    .files-dropzone > .btn
      background-color: $color-accent

    .drop-progress-bar
      background-color: $color-accent-dark

    .showcase-files
      width: 100%

      .files-list
        .card
          width: calc(50% - 1em)
          min-height: 10.5vh
          margin-bottom: 0.5em

          .icon
            font-size: 5.25em

    .item-publish
      transition-duration: $speed-slowest * 2.5

      .screens-list .screen-desktop .file-img
        padding: 0.75em
        margin: 6em auto 8em

        &:after
          top: 0.75em
          left: 0.75em
          height: calc(100% - 1.5em)
          width: calc(100% - 1.5em)

        img
          border-radius: $radius-btn * 2

      .screens-list .screen-background .file-img
        padding: 4em
        margin: 1.5em -1.5em
        height: calc(90vh - 80px)
        max-height: 37.5em
        min-height: 32em
        display: inline-flex
        justify-content: center
        align-items: center

        @media (max-height: $smscreen)
          height: auto
          max-height: 100%
          min-height: 43em

      .showcase-files .card
        width: calc(33.33% - 1em)

  .about-bg
    content: ""
    position: fixed
    top: 0
    left: 0
    height: 100%
    width: 100%
    opacity: 0
    z-index: 0

    &.projects
      background: linear-gradient(192deg, $color-secondary 15%, $color-primary 90%)

    &.styleguides
      background: linear-gradient(192deg, $color-accent 15%, $color-secondary 90%)

    &.showcases
      background: linear-gradient(192deg, $color-tertiary 15%, $color-accent 90%)

    &.current
      animation: showFade $speed-slow ease forwards

    &.previous
      animation: hideFade $speed-slow ease forwards

  h1
    font-size: 5em
    color: white
    margin-top: 0
    text-shadow: 0 0.15em 0.15em rgba($color-primary-darker, 0.5)
    opacity: 0
    animation: showContentFadeUp $speed-slow $speed-faster forwards ease

    @media (max-width: $smscreen)
      font-size: 4em
      margin-top: 0.15em
      margin-bottom: 0.525em
      line-height: 1.15

    @media (max-width: $xsscreen)
      font-size: 3.5em
      margin-top: 0
      margin-bottom: 0.25em

  .content-wrap
    text-align: center
    min-height: 100%
    position: relative
    height: 40em
    z-index: 0

    @media (max-width: $mdscreen)
      height: 36em

    @media (max-width: $smscreen)
      margin-top: 2.75vh

    .about-header
      position: relative
      display: block

.about-types
  display: inline-flex
  flex-flow: row nowrap
  justify-content: center
  margin: 2em -2em
  position: relative

  @media (max-width: $smscreen)
    margin: 2.25em -1.125em

  .about-type
    margin: 0 2em
    border-radius: $radius-btn
    cursor: pointer
    align-items: center
    display: inline-flex
    flex-flow: column nowrap
    text-align: center

    @media (max-width: $smscreen)
      margin: 0 1.125em

    &:hover .theme-thumb
      filter: grayscale(0)
      box-shadow: 0 1.5em 3em -0.5em rgba($color-secondary-darkest, 0.5)

    &.active
      &:before, &:after
        font-family: "icomoon"
        position: absolute
        top: calc(100% + 0.5em)
        transform: rotate(-90deg)
        color: white
        font-size: 2em
        opacity: 0

      &:before
        content: "\e93a"
        transform: none
        margin-left: 2px
        color: rgba(white, 0.5)
        animation: scrollArrowAlt $speed-slowest * 2 $speed-faster linear infinite

      &:after
        color: white
        content: "\e943"
        animation: scrollArrow $speed-slowest * 2 $speed-faster ease infinite

    &.active, &.active:hover
      cursor: default

      .theme-thumb
        box-shadow: 0 0 0 0.25em white, 0 0 0 1em $color-quaternary
        filter: grayscale(0)
        transform: scale(1.215)
      
    .theme-thumb
      filter: grayscale(0.925)
      font-size: 0.5em
      box-shadow: 0 0.5em 1.5em -0.5em rgba($color-secondary-darkest, 0.5)
      min-width: 16em
      transition: box-shadow ease $speed-faster, transform ease $speed-fast, filter ease $speed-fast
      border-radius: $radius-btn
      transform: scale(1)
      transform-origin: center 75%

      @media (max-width: $smscreen)
        font-size: 0.375em

      .theme-layout
        font-size: 1em
        margin: 0

    p
      color: white
      font-family: "muliregular"
      font-size: 1.33em
      margin: 1.5em 0 0
      text-shadow: 0 0.075em 0.075em rgba(black, 0.15)
      position: relative
      text-align: center

.about-tour
  width: 100%
  min-height: 24em
  padding: 0 0 6em
  position: relative
  z-index: 1

  .about-tour-bg
    content: ""
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%
    opacity: 0
    animation: showContentFadeMidUp $speed-normal ease forwards
    z-index: -1

    &:before, &:after
      position: absolute
      content: ""
      top: 0
      left: 0
      height: 100%
      width: 100%
      transform-origin: center center
      pointer-events: none

    &:before
      background: rgba(white, 0.15)
      transform: skewY(-9deg) scaleY(1.0075)

      @media (max-width: $smscreen)
        transform: skewY(-9deg) scaleY(1.005)

    &:after
      background: white
      transform: skewY(-6.67deg)

  .tour-content
    position: relative
    max-width: 80rem
    margin: 0 auto
    padding-bottom: 16em
    opacity: 0
    animation: showFade $speed-normal $speed-faster ease forwards

    .images .file-img-bg
      background-size: cover
        

  .tour-section
    position: relative
    width: 55%
    padding: 12em 6em
    margin: 0 0 10em

    @media (max-width: $mdscreen)
      padding: 12em 1em 12em 3.5em
      width: 50%

    @media (max-width: $smscreen)
      width: 100%
      padding: 8em 1em 0 3.5em
      margin: 0 0 8em

    &#name
      padding: 0
      margin: 0

    &#publish
      margin-bottom: 6em

      @media (max-width: $smscreen)
        margin-bottom: 4em

    &.active
      .tour-num
        color: white
        text-shadow: 0 1px 2px rgba(black, 0.15)

        &:before
          opacity: 0
          animation: showFadeZero $speed-fast ease forwards

    &:before
      content: ""
      border-left: 0.15em solid $color-primary
      position: absolute
      left: 5.375em
      top: 17em
      height: calc(100% - 10em)
      width: 100%
      opacity: 0.33
      z-index: -1

      @media (max-width: $mdscreen)
        left: 3.375em
        top: 16.85em

      @media (max-width: $smscreen)
        left: 3.33em
        top: 12.5em
        height: calc(100% - 12.5em)
        border-left-width: 0.25em

    > h2
      font-size: 3.25em
      padding-left: 1.25em
      color: $color-primary-access
      margin-bottom: 0.5em
      margin-top: 0
      position: relative
      transition: color ease $speed-fast

      @media (max-width: $mdscreen)
        font-size: 3.15em

      @media (max-width: $smscreen)
        font-size: 3em

      .tour-num
        position: absolute
        left: -0.5em

        @media (max-width: $mdscreen)
          left: -0.33em

        &:before
          content: ""
          position: absolute
          top: -0.0925em
          left: -0.5em
          width: 1.64em
          height: 1.64em
          background: $color-primary
          border-radius: 100%
          z-index: -1
          opacity: 1
          transform: scale(1)
          animation: hideFadeZero $speed-fast ease forwards
          transform-origin: center top

    > p
      font-size: 1.5em
      margin-left: 4.25rem
      margin-right: 1.5em
      line-height: 1.75

      @media (max-width: $mdscreen)
        margin-left: 2.75em

      @media (max-width: $smscreen)
        margin-left: 2.67em

      .tour-section-btns
        display: block
        margin-left: -0.425em
        margin-top: 1em

      .btn-text
        display: block

      a
        border-bottom: 1px dashed $color-primary

        &.btn
          border: none

        &:hover
          border-bottom-color: $color-secondary

  .sticky-holder
    position: absolute
    top: 7.5em
    right: 5%
    width: 40%
    height: 100%
    min-height: 30em 
    z-index: 1

    @media (max-width: $mdscreen)
      right: 2.5%
      width: 48%
      top: 8.5em

    @media (max-width: $smscreen)
      bottom: 0
      position: fixed
      width: 100%
      right: 0
      height: 38.5vh
      min-height: 0 !important
      top: auto

    @media (max-width: $xsscreen)
      height: 42vh

    > div
      position: relative
      height: calc(90vh - 80px)
      max-height: 37.5em
      min-height: 32em
      width: 100%

      @media (max-width: $smscreen)
        height: 100%
        min-height: 100%
        max-height: 100%

      &.sticky
        .tour-screen
          top: 80px

          @media (max-height: $smscreen)
            top: 0.5em
            font-size: 0.75em

  .tour-screen
    position: absolute
    height: 100%
    width: 100%
    top: 0
    left: 0
    border-radius: $radius-btn
    background-color: $color-primary-lighter
    background-image: url('../images/bg-brilliant.png')
    box-shadow: 0 1em 1.5em -0.5em rgba($color-primary-access, 0.5)

    @media (max-width: $smscreen)
      top: 0
      border-radius: 0
      box-shadow: 0 -0.5em 1.5em -0.5em rgba($color-primary-access, 0.5)
      opacity: 0
      animation: showFade $speed-fast ease forwards

    &.off
      @media (max-width: $smscreen)
        opacity: 1
        animation: hideFade $speed-fast ease forwards

.screen-section
  position: absolute
  top: 0
  left: 0
  height: 100%
  width: 100%
  display: flex
  flex-flow: row wrap
  padding: 2em
  justify-content: center
  align-items: inherit
  opacity: 1
  animation: hideContentFade $speed-faster ease forwards
  pointer-events: none

  @media (max-width: $smscreen)
    padding: 1.5em
    font-size: 0.925em
    animation: none
    position: relative
    height: auto
    background: $color-primary-light
    margin-top: 3.5em
    margin-left: -2.33em
    width: calc(100% + 2em)
    border-radius: $radius-btn
    box-shadow: 0 1em 1.5em -0.5em rgba($color-primary, 0.5)

  &.name
    .ui.input:after
      content: "|"
      animation: inputBlink $speed-slowest infinite
      position: absolute
      left: 9.125em
      top: 0.7925em
      font-size: 1.67em
      font-weight: 100

  &.audience, &.details, &.logos, &.palette, &.elements
    .screen-section-content
      align-items: center

  &.publish
    padding: 0
    border-radius: $radius-btn
    overflow: hidden

    &.published.active
      .publish-inner
        transform: rotateY(-180deg)

        @media (max-width: $smscreen)
          transform: translateX(-100%)

    .screen-section-content
      perspective: 1000px

    .publish-inner
      transition: transform $speed-slower ease
      transform-style: preserve-3d
      position: relative
      height: 100%
      width: 100%

    .theme-buttons .btn
      font-size: 0.67em

  &.logos
    .item-files .files-list
      margin-top: 1em

      @media (max-width: $smscreen)
        margin-top: 0

      .card
        margin: 0.75em 0.5em
        width: calc(100% - 1em)
        flex: calc(100% - 1em)
        max-height: 7.5em
        height: 7.5em

        @media (max-width: $smscreen)
          margin: 0.5em

        .file-content .file-img-bg
          background-size: contain
          margin: 1em 1.5em
          width: calc(100% - 3em)
          height: calc(100% - 2em)

  &.screens
    .item-files .files-list
      margin-top: 1em

      .card
        margin: 0.75em 0.5em
        width: calc(100% - 1em)
        padding: 0
        height: auto
        max-height: 100%
        flex-flow: column nowrap

        &.screen-background .file-img
          padding: 3em

        &.screen-desktop .file-img
          margin: 3.33em 3em
          padding: 1em

          img
            border-radius: $radius-btn * 2

        &:before, &:after
          display: none

        .file-img
          display: inline-flex
          padding: 0
          overflow: hidden
          transition: all ease $speed-faster

          img
            max-width: 100%
            padding: 0

          .screen-controls
            font-size: 1em
            margin: 1.67em auto 1.5em

            .screen-control
              padding: 1.5em 2em

              &.active .icon
                color: white

              .icon
                color: $color-body-text

        .screen-controls
          margin: 1.75em auto 1.5em

  &.active
    opacity: 0
    animation: showContentFade $speed-slow ease forwards

  .screen-section-content
    height: 100%
    width: 100%
    display: flex
    flex-flow: row wrap

  label
    color: $color-darkest

  .ui.input
    width: 100%
    position: relative
    margin: 0.5em 0

    input
      background: white
      box-shadow: 0 0.25em 0.5em rgba(black, 0.15), 5px 0 0 $color-secondary inset

  #item-name-input
    width: 100%
    font-size: 1.5em
    padding: 0.75em 1.25em
    
  .cards-list
    margin: 0 -0.5em
    min-width: calc(100% + 1em)

    @media (max-width: $smscreen)
      margin: 0
      min-width: 100%

    .card
      min-width: calc(50% - 1em)
      display: flex
      align-items: center

      p
        padding: 0

  .palette-list
    .card
      margin: 0.5em

    .palette
      flex: 1
      align-items: center
      height: 100%

      @media (max-width: $smscreen)
        height: 3.5em

      .color
        height: 100%

  .fonts-list
    .card
      @media (max-width: $smscreen)
        padding: 1.25em 1.5em

  .images-list
    margin-top: 0
    margin-bottom: 0

    .card
      height: calc(25% - 1em)

      &.selected
        background-color: $color-success

        .image-wrap
          opacity: 0.3

          .file-img-bg
            filter: grayscale(1)

      &:nth-child(9)
        display: none

        @media (max-width: $smscreen)
          display: flex

      @media (max-width: $smscreen)
        min-width: calc(33.33% - 1em)
        height: 5.5em

      .image-wrap
        transition: opacity ease $speed-faster, filter ease $speed-faster
        opacity: 1
        filter: none

  .screen-input
    flex: 100%

    label
      margin-bottom: 1em

    input
      font-size: 1.15em

  .audience-age, .audience-skill
    flex: 50%
    padding: 0 0.5em

    @media (max-width: $smscreen)
      font-size: 0.85em

  .audience-age
    margin-left: -0.5em

  .audience-skill
    margin-right: -0.5em

  .audience-list
    margin-top: 0.5em
    margin-bottom: 0.5em

    @media (max-width: $smscreen)
      margin-left: inherit
      margin-right: inherit
      width: inherit

    .card
      width: calc(100% - 1em)
      padding: 1.15em

      @media (max-width: $smscreen)
        font-size: 0.925em

  .details-category
    width: 100%

    label
      margin-bottom: 0.75em

    .cards-list
      margin-top: 0.5em
      margin-bottom: 0.5em

      .card
        min-width: 0
        font-size: 0.925em
        padding: 1.15em

      .details-category-text
        color: white
        font-size: 1.15em

  .details-files
    width: 100%

    .files-list.dark .card
      background: $color-darkest

  .item-files
    width: 100%

    .files-dropzone
      font-size: 0.575em
      margin-top: 1em
      margin-bottom: 1em
      padding: 0.5em

      &.uploading
        .drop-progress-bar
          transition-duration: $speed-slow

      .drop-progress-bar
        transition-duration: $speed-fast

        &.uploaded
          width: 100%

    .files-drop-progress
      background: $color-primary

  .files-list
    min-height: 5em
    margin: 0 -0.25em
    min-width: calc(100% + 0.5em)

    .card
      display: none
      margin-bottom: 0
      font-size: 0.67em
      min-width: 0
      height: 6.75em
      max-height: 6.75em
      animation: hideFade $speed-fastest ease forwards
      opacity: 0
      display: flex

      @media (max-width: $smscreen)
        max-height: 6em
        height: 6em
        min-width: 0
        width: calc(25% - 1em)

      &.selected
        animation: showFade $speed-slow ease forwards

        @media (max-width: $smscreen)
          width: calc(25% - 1em)

    .icon
      top: 0

  .item-theme, .item-publish
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    backface-visibility: hidden

  .item-theme
    width: 100%
    text-align: center
    padding: 2em

    @media (max-width: $smscreen)
      padding: 2em 1em 3em
      position: relative

    > label
      font-size: 1em
      margin-top: 0.25em

    .cards-list .card
      min-width: 0
      width: calc(50% - 3em)
      font-size: 0.67em

    .theme-thumb, .theme-layout
      width: 100%

    .component-theme-wrap
      .card
        &.selected
          &:before, &:after
            font-size: 1.5em

      .theme-logo,
      .theme-layout.project .theme-audience .theme-line
        background: $color-primary

      .theme-images 
        .theme-image.image-1
          background-image: url('../images/home-1.jpg')

        .theme-image.image-2
          background-image: url('../images/home-2.jpg')

        .theme-image.image-3
          background-image: url('../images/home-3.jpg')

        .theme-image.image-4
          background-image: url('../images/home-4.jpg')

      .theme-layout.project .theme-header
        background-image: linear-gradient(-15deg, $color-secondary 5%, $color-primary 67%)

      .theme-files
        .theme-file:before
          height: 3rem
          width: 3rem
          transform: rotate(45deg) translateX(-0.085rem)

    .cards-list > .btn
      font-size: 1em
      margin-top: 2em

      &.active
        .publish-text
          font-size: 0

        .icon
          margin: 0
          transform-origin: center center
          animation: publishedBtnIcon $speed-slow $speed-faster forwards ease

      .publish-text
        transition: font ease $speed-faster

      .icon
        position: relative
        margin-right: 0.85em
        top: 0.125em
        display: inline-block

  .item-publish
    transform: rotateY(-180deg)
    height: auto
    transition: margin ease $speed-slowest

    @media (max-width: $smscreen)
      transform: none
      left: 100%

    .item-header, .item-body
      opacity: 1
      position: relative

    &.project
      .item-header
        background: linear-gradient(160deg, $color-primary 33%, $color-secondary)
        height: auto
        text-align: center

        h3
          color: white
          margin: 0.85em 0
          text-shadow: 0 0.15em 0.15em rgba($color-secondary-dark, 0.33)

      .item-body
        padding: 1.5em 1em

    &.styleguide  
      .item-header
        background: white
        text-align: center
        padding: 5em 3.33em
        height: auto

        img
          max-width: 80%

      .item-body
        .publish-section
          padding: 1em 1.5em

        .item-publish-font
          margin: 0 0.5em

    .item-body
      background: $color-lightest
      margin-top: 0
      text-align: center

      .publish-section
        padding: 1em 1.5em 1.25em

        h5
          font-size: 1.25em

        label
          font-size: 0.825em
          margin-bottom: 0.5em

        .icons-grid, .elements-item
          display: flex
          margin: 0.5em
          text-align: left
          background: white
          border-radius: $radius-btn

        .icons-grid
          padding: 0.5em 1em

          .icons-data
            width: 25%
            text-align: center
            padding: 1em

            .icon
              font-size: 1.5em

        .elements-item
          padding: 0.5em 2em

        #button-element
          margin-bottom: 1.5em

    .adjectives-list
      display: flex
      align-items: center
      justify-content: center
      padding: 0.25em 1em 0
      font-size: 1.5em

      .item-adjective
        margin: 0 1em

    .item-palette-wrap
      margin: 0

      .item-palette-color
        height: 4.25em
        position: relative

        @media (max-width: $smscreen)
          flex: auto

        &.primary
          background: $color-primary

        &.secondary
          background: $color-secondary

        &.accent
          background: $color-accent

        &.tertiary
          background: $color-tertiary

        &:before
          content: ""
          position: absolute
          bottom: 0
          left: 0
          width: 100%
          height: 33%
          background: linear-gradient(90deg, rgba(black, 0.125), rgba(white, 0.25))

    .item-publish-font
      text-align: left
      font-size: 0.85em
      padding: 1em 1.5em
      background: white

      h3
        font-size: 1.75em

      .font-paragraph
        font-size: 0.625em
        line-height: 1.33
        margin: 1.5em 0 1.75em

    .images-list
      display: flex
      flex-flow: row wrap

      .image
        height: 6.67em
        margin: 0.5em
        width: calc(50% - 1em)
        background-position: center center
        background-size: cover
        border: 2px solid white
        box-shadow: 0 0.15em 0.33em rgba(black, 0.085)

    .files-list
      .card
        &:before, &:after
          display: none

    .item-palette-header
      margin-bottom: 2em

    .item-header-color
      overflow: hidden
      position: relative

      &.header-palette-primary
        height: 10em
        background: $color-primary

      &.header-palette-secondary
        height: 7.5em
        background: $color-secondary

      &.header-palette-accent
        height: 6em
        background: $color-accent

      .header-img
        content: ""
        position: absolute
        top: 0
        left: 0
        height: 100%
        width: 100%
        opacity: 0.15
        background-size: cover
        background-position: center center
        filter: grayscale(0.5)

    .item-logo
      background: white
      border-radius: $radius-btn
      margin: 0.75em 0.5em
      width: calc(100% - 1em)
      padding: 0.5em

      &:last-child
        margin-bottom: 0

      img
        max-width: 90%
        max-height: 3em

  .card-palette-custom
    width: 100%

    .palette-custom
      .palette
        display: flex
        font-size: 0.75em
        background: white
        padding: 0.33em
        border-radius: $radius-btn
        box-shadow: 0 0.33em 0 -0.15em rgba(black, 0.085)

        p
          padding: 1em 1.75em

        .color-num
          font-size: 1.67em
          margin-bottom: 0.5em

        .color
          height: 10.5em
          transition: background ease $speed-fast

          &.color-primary.active
            background: $color-primary

          &.color-secondary.active
            background: $color-secondary

          &.color-accent.active
            background: $color-accent

          &.active
            p
              opacity: 0

          @media (max-width: $smscreen)
            height: 7.5em

  .custom-palette-picker
    box-shadow: 0 0.33em 0 -0.15em rgba(black, 0.085)
    position: relative
    z-index: 1

    &.primary
      .color-indicator
        left: calc(16.825% - 1.25em)

    &.secondary
      .color-indicator
        left: calc(50% - 1.25em)

    &.accent
      .color-indicator
        left: calc(83.125% - 1.25em)

    .color-indicator
      bottom: calc(100% - 0.5em)

      @media (max-width: $smscreen)
        bottom: calc(100% - 1em)
    
    .sketch-picker
      margin-top: 1.75em
      padding: 0.5em !important
      overflow: hidden

      @media (max-width: $smscreen)
        margin-top: 1.25em

      &:before, &:after
        display: none

      input, span
        font-size: 0.75em !important

      > div:first-child
        padding-bottom: 12em !important

        @media (max-width: $smscreen)
          padding-bottom: 8em !important

      > div:last-child
        > div
          margin: 0 0.5em 0.5em 0 !important

          @media (max-width: $smscreen)
            margin: 0 0.25em 0.25em 0 !important
            height: 1em !important
            width: 1em !important

  .fonts-google
    width: 100%
    filter: none

    .type-select-search input
      font-size: 1.25em

    .type-select-search-icons
      @media (max-width: $smscreen)
        font-size: 0.925em

  .fonts-custom-list
    margin: 0.5em -0.25em
    min-width: calc(100% + 0.5em)

    @media (max-width: $smscreen)
      margin: 0 -0.25em

    .card
      font-size: 0.75em
      max-height: calc(100% - 5em)
      overflow: auto
      align-items: flex-start
      animation: hideFade ease $speed-faster forwards

      @media (max-width: $smscreen)
        max-height: 100%

      &.selected
        animation: showContentFade ease $speed-faster forwards

        &:before, &:after
          opacity: 1
          display: block
          font-size: 1.2em

      label
        color: $color-primary-dark

      .fonts-custom-content
        width: 100%

      .font-aa
        width: 6.25rem
        font-size: 4em
        font-family: "muliregular"

        @media (max-width: $smscreen)
          width: 5.25rem
          text-align: center

      .font-example
        font-size: 0.75em
        font-family: "muliregular"

      .font-toggle
        margin-right: 0.5em
        font-family: "muliregular"

      .font-detail
        @media (max-width: $smscreen)
          display: flex
          padding-top: 1.5em
          padding-bottom: 1.25em

        &.weight-100 *
          font-weight: 100
          font-family: "muliextralight"

        &.weight-200 *
          font-weight: 200
          font-family: "mulilight"

        &.weight-300 *
          font-weight: 300
          font-family: "muliregular"

  .icons-list
    margin-top: 1em

    @media (max-width: $smscreen)
      margin-top: 0.25em

    .card
      width: 100%
      max-height: 100%
      height: auto
      font-size: 1em
      text-align: center
      padding: 1.5em 1em

      @media (max-width: $smscreen)
        padding: 1em

      &.selected
        width: 100%

      .icons-data
        width: 25%
        padding: 1.25em 0.5em

        @media (max-width: $smscreen)
          padding: 0.75em 0.5em

      .icon
        font-size: 2.5em

        @media (max-width: $smscreen)
          font-size: 2.25em

      h5
        font-size: 0.575em

  #elements
    width: 100%

    .elements-item
      width: 100%

    .elements-content
      font-size: 1em

    .element-buttons.active .element-button-edit-wrap .edit-item-colors
      min-width: 0

  #header-element, #blockquote-element, #button-element
    margin-top: 1.5em
    margin-bottom: 1.5em

    @media (max-width: $smscreen)
      margin: 1em 0

  #header-element
    margin-top: 0

    h1
      font-size: 2.25em
      text-shadow: none

    h2
      font-size: 1.75em

    h3
      font-size: 1.5em

    h4
      font-size: 1.25em

    h5
      font-size: 1em

    h1, h2, h3, h4, h5
      color: $color-primary-dark
      margin: 0
      padding: 0
      text-align: left
      font-family: "mulilight"
      margin: 0.5rem 0

  #blockquote-element
    font-size: 0.85em
    margin-top: 0

    .element-content
      padding-left: 2em
      padding-right: 2em

    blockquote
      font-family: "muliregular"
      color: $color-secondary
      border-left: 3px solid $color-secondary
      margin: 0.75em 0
      font-size: 0.925em
      padding: 0.5em 0.5em 0.5em 2em

    .card-edit-wrap 
      font-size: 1.15em
      
      .card-edit
        animation: showCardActiveRight $speed-normal forwards ease

  #button-element
    font-size: 0.85em
    margin-bottom: 0

    .element-content
      width: 100%

      .buttons-list
        margin-left: -1em
        margin-right: -1em

      .btn-group
        margin: 0.15em 0
        display: flex
        flex-flow: row nowrap

      .btn
        width: calc(33.33% - 1em)
        max-width: 100%
        margin: 0.5em
        padding: 0.85em 0.5em
        text-align: center
        font-size: 1em

        @media (max-width: $smscreen)
          padding: 0.75em 0.5em

        &.hover
          background: transparent
          border-width: 2px
          border-style: solid
          text-shadow: none
          box-shadow: 0 0.33em 0.33em -0.15em rgba(black, 0.175)

        &.active
          box-shadow: none
          text-shadow: none

        &.btn-primary
          &.hover
            border-color: $color-primary
            color: $color-primary

          &.active
            background: $color-primary-dark

        &.btn-secondary
          &.hover
            border-color: $color-secondary
            color: $color-secondary

          &.active
            background: $color-secondary-dark

        &.btn-accent
          &.hover
            border-color: $color-accent
            color: $color-accent

          &.active
            background: $color-accent-dark

    .buttons-custom
      margin-top: 0.25em

      .link
        text-decoration: none

    .element-button-edit-wrap
      &.active .element-button-edit
        animation: showAboutText $speed-normal ease forwards

      .element-button-edit
        background: $color-accent
        border-radius: $radius-btn
        border: 2px solid $color-accent
        margin: 0.75em 0.5em
        width: calc(100% - 1em)
        animation: hideCard $speed-fast ease forwards
        display: flex

        @media (max-width: $smscreen)
          padding: 0.33em 0.5em

        &.normal
          &:before
            left: calc(15% - 0.5em)

          .color .item-palette-picker
            background: white

          .bg .item-palette-picker
            background: $color-accent

          .border .item-palette-picker
            background: $color-accent

          .shadow .item-palette-picker
            background: rgba(black, 0.15)

        &.hover
          background: transparent

          &:before
            left: calc(50% - 0.5em)

          .color .item-palette-picker
            background: $color-accent

          .bg .item-palette-picker
            background: transparent

          .border .item-palette-picker
            background: $color-accent

          .shadow .item-palette-picker
            background: transparent

        &.active
          background: $color-accent-dark
          border-color: $color-accent-dark

          &:before
            left: calc(85% - 0.5em)
            border-bottom-color: $color-accent-dark

          .color .item-palette-picker
            background: white

          .bg .item-palette-picker
            background: $color-accent-dark

          .border .item-palette-picker
            background: $color-accent-dark

          .shadow .item-palette-picker
            background: rgba(black, 0.15)

        &:before
          border-bottom-color: $color-accent
          transition: left ease $speed-fast
          left: auto
          right: auto

      .edit-item-colors
        margin: 0.5em
        width: calc(25% - 1em)

        @media (max-width: $smscreen)
          font-size: 0.85em

        label
          @media (max-width: $smscreen)
            font-size: 0.75em

        .item-palette-picker
          width: 100%

.styleguides .tour-button:before
  background: linear-gradient(transparent, rgba($color-accent-light, 0.15))

.tour-button
  text-align: center
  position: absolute
  z-index: 1
  bottom: 0
  display: inline-block
  width: 100%
  padding: 3.5em 1.5em 8.5em

  &:before
    content: ""
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%
    background: linear-gradient(transparent, rgba($color-primary-light, 0.25))
    transform: skewY(-6.67deg)

  .btn
    padding: 0 1.5em 0 0
    border-radius: 3em
    text-shadow: none
    background: white
    font-size: 1.33em
    display: inline-flex
    flex-flow: row nowrap
    align-items: center

    &.btn-accent
      border-color: $color-accent
      color: $color-accent-dark
      padding: 0 0 0 1.5em
      flex-direction: row-reverse

      &:hover
        background: lighten($color-accent-lighter, 10%)
        border-color: lighten($color-accent-lighter, 10%)
        color: $color-accent-darker

        .icon
          border-color: $color-accent-dark
          background: $color-accent-dark
          color: white
          transform: rotate(0deg) scale(1.15)

      &:active
        .icon
          left: calc(-100% + 1.5em)
          transform: rotate(0deg) scale(0.925)

      .icon
        border-color: $color-accent-dark
        background: $color-accent
        color: white
        margin-left: 1em
        margin-right: -0.15em
        
    &:hover
      border-color: lighten($color-primary-lighter, 5%)
      background: lighten($color-primary-lighter, 5%)
      color: $color-primary-darker
      
      .icon
        transform: rotate(180deg) scale(1.15)
        border-color: $color-primary-dark
        background: $color-primary-dark
        color: white

    &:active
      .icon
        position: relative
        left: calc(100% - 1.5em)
        transform: rotate(180deg) scale(0.925)

    .icon
      transform: rotate(90deg)
      transform-origin: center center
      display: inline-flex
      align-items: center
      justify-content: center
      transition: transform ease $speed-fast, border ease $speed-faster, background ease $speed-faster, color ease $speed-fastest, left ease $speed-fastest
      font-weight: 600
      background: $color-primary
      border: 2px solid $color-primary-access
      border-radius: 100%
      height: 3.5em
      width: 3.5em
      margin: -0.15em 1em -0.15em -0.15em
      position: relative
      text-shadow: none
      color: white
      left: 0
      right: auto

    > span
      display: inline-flex
      height: 100%
      line-height: 1

.about-responsive
  position: relative
  z-index: 0
  width: 100%
  max-width: 92rem
  display: block
  margin: 0 auto
  padding: 10em 8em
  text-align: center

  @media (max-width: $smscreen)
    padding: 8em 0 5em

  .about-types
    margin-bottom: 10em

    .about-type
      &:before, &:after
        top: -1.5em

      &:before
        animation: scrollArrowAltUp $speed-slowest * 2 $speed-faster linear infinite
        margin-left: -1.5px

      &:after
        animation: scrollArrowUp $speed-slowest * 2 $speed-faster ease infinite

      p
        order: 0
        margin: 0 0 1.5em

      .theme-thumb
        order: 1
        transform-origin: center 25%

  h3
    color: white
    font-size: 3.75em
    margin: 1em 0
    text-shadow: 0 0.15em 0.15em rgba($color-secondary-dark, 0.33)

    @media (max-width: $smscreen)
      font-size: 3.5em
      margin: 0 1em 1em

  .about-responsive-lg, .about-responsive-sm
    display: inline-block
    position: relative

    &:hover
      .about-img-hover
        opacity: 1

    img
      display: block
      width: 100%
      border-radius: $radius-btn * 2

    .about-img-hover
      position: absolute
      top: 0
      left: 0
      height: 100%
      width: 100%
      opacity: 0
      transition: opacity ease $speed-normal

  .about-responsive-lg
    width: 75%
    position: relative
    left: 3em
    
    @media (max-width: $smscreen)
      left: 2.5vw

    &.screen-desktop
      .file-img
        border-radius: $radius-btn * 4
        box-shadow: 0 0 0 2px $color-darker, 0 1em 2em -0.15em rgba(black, 0.25)

        @media (max-width: $smscreen)
          border-radius: $radius-btn * 2

  .about-responsive-sm
    width: 25%
    position: relative
    top: 1.5em
    left: -3em

    @media (max-width: $smscreen)
      left: -2.5vw
      top: 1em

    &.screen-mobile
      .file-img
        border-radius: $radius-btn * 4
        padding: 0.67em
        background: white
        box-shadow: 0 0 0 2px $color-light, 0 1em 2em -0.15em rgba(black, 0.25)

        @media (max-width: $smscreen)
          border-radius: $radius-btn * 2
          padding: 0.33em

  .btn
    margin-top: 5em
    font-size: 1.5em

.about-collab
  position: relative
  z-index: 1
  margin-top: 8em

  &:before, &:after
    content: ""
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%
    z-index: 0

  &:before
    background: rgba($color-lightest, 0.15)
    transform: skewY(-7deg) scaleY(1.05)

  &:after
    background: $color-lightest
    transform: skewY(-5deg)

  .about-collab-content
    position: relative
    z-index: 1
    max-width: 80rem
    margin: 0 auto
    padding: 14em 6em
    display: flex
    flex-flow: row wrap
    align-items: center

    @media (max-width: $smscreen)
      flex-flow: column wrap
      padding: 8em 1.5em 10em

  .about-collab-text
    padding-right: 10%
    width: 45%

    @media (max-width: $smscreen)
      padding: 0 2em 4em
      width: 100%

    h2
      font-size: 3.75em
      color: $color-secondary
      margin: 0 0 0.75em
      padding: 0

      @media (max-width: $smscreen)
        font-size: 3.5em

    p
      font-size: 1.25em
      line-height: 1.5

    .btn-text
      display: inline-block
      margin: 0 0 0 -0.425em

  .about-collab-video
    border-radius: $radius-btn
    box-shadow: 0 1.5em 2em -0.5em rgba($color-secondary-dark, 0.5), 0 -0.75em 3em rgba($color-primary, 0.575)
    position: relative
    margin-right: -5%
    width: 57.5%
    display: grid

    @media (max-width: $smscreen)
      margin-right: 0
      width: 100%

    video
      width: 100%
      border-radius: $radius-btn
      overflow: hidden

.about-signup
  padding: 8em 4em 10em
  position: relative
  z-index: 1
  text-align: center
  color: white

  @media (max-width: $smscreen)
    padding-bottom: 8em

  h3
    color: white
    font-size: 3.33em
    position: relative
    z-index: 1
    text-shadow: 0 0.15em 0.15em rgba($color-secondary-dark, 0.33)

    @media (max-width: $smscreen)
      font-size: 3em

    span
      display: block
      font-size: 1.15em

      @media (max-width: $smscreen)
        display: initial

.paletteAlt, #paletteAlt
  .card-palette-custom .palette-custom
    .palette .color
      &.color-primary.active
        background: $color-tertiary

      &.color-secondary.active
        background: $color-accent

      &.color-accent.active
        background: $color-primary
