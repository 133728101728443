h1, h2, h3, h4, h5
  color: black
  display: block
  margin-inline-start: 0px
  margin-inline-end: 0px
  font-weight: bold
  padding: inherit

h1
  font-size: 2em
  margin-block-start: 0.67em
  margin-block-end: 0.67em

h2
  font-size: 1.5em
  margin-block-start: 0.83em
  margin-block-end: 0.83em

h3
  font-size: 1.17em
  margin-block-start: 1em
  margin-block-end: 1em

h4
  font-size: 1em
  margin-block-start: 1.33em
  margin-block-end: 1.33em

h5
  font-size: 0.83em
  margin-block-start: 1.67em
  margin-block-end: 1.67em

p
  display: block
  margin-block-start: 1em
  margin-block-end: 1em
  margin-inline-start: 0px
  margin-inline-end: 0px
  font-size: 1em

blockquote
  display: block
  margin-block-start: 1em
  margin-block-end: 1em
  margin-inline-start: 40px
  margin-inline-end: 40px

code
  font-family: monospace

strong, b
  font-weight: bold
  font-family: inherit
  line-height: 1

a, .link
  color: blue
  text-decoration: underline
  cursor: pointer

ul
  display: block
  list-style-type: disc
  margin-block-start: 1em
  margin-block-end: 1em
  margin-inline-start: 0px
  margin-inline-end: 0px
  padding-inline-start: 40px

  li
    list-style-type: disc

ol
  display: block
  list-style-type: decimal
  margin-block-start: 1em
  margin-block-end: 1em
  margin-inline-start: 0px
  margin-inline-end: 0px
  padding-inline-start: 40px

  li
    list-style-type: decimal

li
  display: list-item

form
  display: block
  margin-top: 0em

input, textarea, button
  text-rendering: auto
  color: initial
  letter-spacing: normal
  word-spacing: normal
  text-transform: none
  text-indent: 0px
  text-shadow: none
  text-align: start
  margin: 0em

button
  align-items: flex-start
  text-align: center
  cursor: pointer
  color: buttontext
  background-color: buttonface
  box-sizing: border-box
  padding: 2px 6px 3px
  border-image: initial
  border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186)
  border-style: solid
  border-width: 1px

input
  -webkit-appearance: textfield
  background-color: white
  -webkit-rtl-ordering: logical
  cursor: text
  padding: 1px
  border-width: 2px
  border-style: inset
  border-color: initial
  border-image: initial

input[type="radio" i]
  -webkit-appearance: radio
  box-sizing: border-box
  background-color: initial
  cursor: default
  margin: 3px 0.5ex
  padding: initial
  border: initial

input[type="checkbox" i]
  background-color: initial
  cursor: default
  margin: 3px 0.5ex
  padding: initial
  border: initial
  -webkit-appearance: checkbox
  box-sizing: border-box

textarea
  -webkit-appearance: textarea
  background-color: white
  -webkit-rtl-ordering: logical
  flex-direction: column
  resize: auto
  cursor: text
  white-space: pre-wrap
  overflow-wrap: break-word
  border-width: 1px
  border-style: solid
  border-color: initial
  border-image: initial
  padding: 2px