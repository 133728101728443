.text-content
  width: 60rem
  max-width: 100%
  margin: 1.5em auto
  position: relative
  z-index: 0
  transition: margin ease $speed-fastest
  opacity: 0
  animation: showFade $speed-fast $speed-faster forwards ease

  @media (min-width: $hugescreen)
    width: 64rem

  @media (min-width: $epicscreen)
    width: 70rem

  @media (max-width: $smscreen)
    width: 100%
    padding: 0

  @import 'reset'

  h1, h2, h3, h4, h5, p
    font-weight: inherit

  h1
    font-size: 3em

  h2
    font-size: 2.5em

  h3
    font-size: 2em

  h4
    font-size: 1.5em

  h5
    font-size: 1.25em

  &:hover
    .card-delete
      animation: showCardActiveRight $speed-faster forwards ease

  &.editing
    margin: 2.5em auto

    @media (max-width: $smscreen)
      margin: 1.5em auto

    &.empty
      .text-content-edit .text-edit-btn
        display: none

    .text-content-edit 
      .content-edit, .text-save-btn, .text-delete-btn
        display: block

      .text-edit-btn
        display: none

    .text-content-display
      display: none

      .content-display
        content: initial

  &.empty
    .content-display
      display: none

    .text-content-edit
      .text-edit-btn
        display: block

  img
    max-width: 100%
    max-height: 100%

  h1, h2, h3, h4, h5
    margin-left: 0
    margin-right: 0
    padding-left: 0
    padding-right: 0
    font-weight: normal
    text-align: left

  h1, h2, h3, h4, h5, p
    &.ql-align-center
      text-align: center

    &.ql-align-right
      text-align: right

  li
    margin: 0.5em 0

  .text-content-edit
    margin: 0 -1em

    @media (max-width: $lgscreen)
      margin: 0 -0.5em

    .text-edit-wrap
      box-shadow: 0 0.25em 0.5em rgba(black, 0.085)

    .content-edit, .text-edit-btn, .text-save-btn, .text-delete-btn
      display: none

    .content-edit
      text-align: left
      background: rgba($color-lightest, 0.5)
      border-radius: $radius-border $radius-border 0 0

      .ql-toolbar, .ql-container
        border: none

      .ql-toolbar
        background: rgba($color-lightest, 0.5)
        box-shadow: 0 1px 1px rgba(black, 0.085)
        padding: 0.25em
        border-radius: $radius-border $radius-border 0 0

        @media (max-width: $smscreen)
          padding: 0.5em 3em 0.5em 0.5em

        button
          box-shadow: none
          border-radius: $radius-border
          padding: 0.25em
          height: 1.67em
          width: 1.75em
          text-align: center

          @media (max-width: $smscreen)
            padding: 0.33em
            height: 2em
            width: 2.15em

          &:hover
            box-shadow: 0 2px 2px rgba(black, 0.15)

        line.ql-transparent
          opacity: 1

        .ql-formats
          @media (max-width: $smscreen)
            margin-right: 0.5em

      .ql-editor
        padding: 1em
        max-height: 75vh
        
        // h1, h2, h3, h4, h5, h6, p
        //   max-width: 54rem

        @media (max-width: $smscreen)
          padding: 1em

      .ql-picker-label
        &:before
          display: inline-block
          white-space: nowrap
          overflow: hidden
          text-overflow: ellipsis
          width: calc(100% - 1em)

      .ql-tooltip
        border: none
        box-shadow: 0 0.15em 0.33em rgba(black, 0.25)
        padding: 0.5em 1em

        input
          border: none
          padding: 0.5em

      .ql-action
        font-family: 'muliregular'
        color: $color-primary

    .text-edit-btn, .text-save-btn
      text-align: center
      
      .icon
        font-size: 1.5em

    .text-edit-btn
      background: transparent
      padding: 0.5em
      transition: background ease $speed-faster
      border-radius: $radius-btn
      cursor: pointer
      position: relative

      &:before, &:after
        content: ""
        position: absolute
        height: 0
        border-top: 1px dashed rgba(black, 0.75)
        width: calc(50% - 1.67em)
        top: 0
        bottom: 0
        margin: auto
        opacity: 0.25
        transition: opacity ease $speed-faster, border ease $speed-slow

      &:before
        left: 0

      &:after
        right: 0

      &:hover
        &:before, &:after
          border-top-style: solid
          opacity: 1

        .icon
          opacity: 1
          transform: scale(1.33)
          
      .icon
        opacity: 0.25
        transition: opacity ease $speed-faster, transform ease $speed-faster
        text-shadow: 0 1px 0 rgba(white, 0.5)
        transform: scale(1)
        display: block

    .text-save-btn
      background: $color-success
      padding: 0.5em
      cursor: pointer
      transition: background ease $speed-faster
      border-radius: 0 0 $radius-border $radius-border

      &:hover
        background: adjust-hue($color-success, 15deg)

      .icon
        color: white
        text-shadow: 0 1px 0 rgba(black, 0.15)

    .text-delete-btn
      .card-delete-wrap
        right: -1em
        border-radius: 0 $radius-border 0 0
        animation: showFade $speed-faster forwards ease
        pointer-events: auto

        @media (max-width: $lgscreen)
          right: -0.5em

  .text-content-display
    display: block

    .content-display
      padding: 0.5em 1em
      margin: 0 -1em
      border-radius: $radius-btn
      transition: background ease $speed-fastest

      @media (max-width: $lgscreen)
        margin: 0 -0.5em
        padding: 0.5em 1em

      &:hover
        background: rgba(black, 0.075)
        cursor: pointer

      // h1, h2, h3, h4, h5, h6, p
      //   max-width: 54rem

.item-section > .text-content 
  .content-display
    @media (max-width: $smscreen)
      margin: 0 -0.5em

#styleguide, #showcase
  .text-content
    margin: 3em 0
    text-align: left
    width: 100%
    font-size: 1rem

    &:empty
      margin: 0

    @import 'reset'

    h1, h2, h3, h4, h5, p
      font-weight: inherit

    h1
      font-size: 3em

    h2
      font-size: 2.5em

    h3
      font-size: 2em

    h4
      font-size: 1.5em

    h5
      font-size: 1.25em
