.item-screens
  .component-section-wrap.done
    .screens-list
      .card.selected
        flex: 100%
        margin-top: 2em
        margin-bottom: 2em

      .sticky-holder, .file-detail
        display: none

.screens-list
  .cards-list
    margin-bottom: -0.5em

    &.empty
      margin-top: 0
      margin-bottom: 0

  .card.selected
    width: 100%
    padding: 0
    margin-top: 3em
    margin-bottom: 3em
    cursor: default
    animation: showFade ease $speed-fast forwards

    &:hover
      .card-reorder
        animation: showCardActiveMiddle $speed-faster forwards ease

    &:before, &:after
      display: none

    .sticky-holder
      opacity: 0
      animation: showFade $speed-faster forwards ease
      position: relative

  .screen-background
    .file-img
      padding: 1em

      @media (max-width: $mdscreen)
        padding: 0.5em

      &.scale-2
        padding: 2em

        @media (max-width: $mdscreen)
          padding: 1em

      &.scale-3
        padding: 3em

        @media (max-width: $mdscreen)
          padding: 1.5em

      &.scale-4
        padding: 4em

        @media (max-width: $mdscreen)
          padding: 2em

      &.scale-5
        padding: 5em

        @media (max-width: $mdscreen)
          padding: 2.5em

      &.scale-6
        padding: 6em

        @media (max-width: $mdscreen)
          padding: 3em

      &.scale-7
        padding: 7em

        @media (max-width: $mdscreen)
          padding: 3.5em

      &.scale-8
        padding: 8em

        @media (max-width: $mdscreen)
          padding: 4em

      &.scale-9
        padding: 9em

        @media (max-width: $mdscreen)
          padding: 4.5em

      &.scale-10
        padding: 10em

        @media (max-width: $mdscreen)
          padding: 5em

      &.scale-11
        padding: 11em

        @media (max-width: $mdscreen)
          padding: 5.5em

      &.scale-12
        padding: 12em

        @media (max-width: $mdscreen)
          padding: 6em

  .screen-desktop, .screen-mobile
    .screen-content
      text-align: center

    .file-img
      display: inline-flex
      vertical-align: bottom
      background: black
      margin: 4em auto
      position: relative
      box-shadow: 0 0 0 2px $color-dark, 0 0 0 4px white, 0 1em 2em -0.15em rgba(black, 0.25)

      &:after
        content: ""
        position: absolute
        box-shadow: 0 0 0 1px rgba(white, 0.175) inset
        pointer-events: none
        border-radius: $radius-btn * 2

        @media (max-width: $smscreen)
          border-radius: $radius-btn

    .file-img-wrap, .file-img-preview
      overflow: auto
      display: inline-flex
      align-items: center
      justify-content: flex-start
      border-radius: $radius-btn * 2
      position: relative
      background-color: $color-lightest

      @media (max-width: $smscreen)
        border-radius: $radius-btn

      img
        width: auto
        height: auto
        max-height: initial

  .screen-desktop
    .file-img-wrap, .file-img-preview
      width: 67vw
      height: 37.6875vw

      @media (min-width: $hugescreen)
        width: 52rem
        height: 29.25rem

      @media (max-width: $lgscreen)
        width: 75vw
        height: 42.1875vw

      @media (max-width: $mdscreen)
        width: 80vw
        height: 45vw

    .file-img
      padding: 1em
      border-radius: $radius-btn * 3.33

      @media (max-width: $mdscreen)
        padding: 0.925em

      @media (max-width: $smscreen)
        padding: 0.575em
        border-radius: $radius-btn * 2

      &:after
        top: 1em
        left: 1em
        height: calc(100% - 2em)
        width: calc(100% - 2em)

        @media (max-width: $mdscreen)
          top: 0.925em
          left: 0.925em
          height: calc(100% - 1.85em)
          width: calc(100% - 1.85em)

        @media (max-width: $smscreen)
          top: 0.525em
          left: 0.525em
          height: calc(100% - 1.15em)
          width: calc(100% - 1.15em)

  .screen-mobile
    .file-img-wrap, .file-img-preview
      width: 25vw
      height: 54.133vw

      @media (min-width: $hugescreen)
        width: 21rem
        height: 45.472rem

      @media (max-width: $mdscreen)
        width: 50vw
        height: 108.267vw

    .file-img
      padding: 0.66em
      border-radius: $radius-btn * 4

      @media (max-width: $smscreen)
        padding: 0.5em
        border-radius: $radius-btn * 2

      &:after
        top: 0.66em
        left: 0.66em
        height: calc(100% - 1.33em)
        width: calc(100% - 1.33em)

        @media (max-width: $smscreen)
          top: 0.5em
          left: 0.5em
          height: calc(100% - 1em)
          width: calc(100% - 1em)

  .file-content
    background: white

  .file-img
    display: flex
    flex-flow: column wrap
    transition: background ease $speed-fast, padding ease $speed-faster

    img
      max-width: 100%
      max-height: 100%
      min-width: 100%
      height: auto
      width: auto
      filter: drop-shadow(0 0.25em 0.5em rgba(black, 0.15))

  .file-img-wrap, .file-img-preview
    flex-flow: column wrap

    &.loading
      img
        opacity: 0

    &.done
      img
        opacity: 0
        animation: showFade $speed-fast ease forwards

  .file-img-preview
    position: relative

  .sticky
    z-index: 3

    .screen-controls-wrap
      background: transparent

    .file-background
      box-shadow: 0 -0.15em 0.5em rgba(black, 0.2)

  .file-background
    background: white
    display: flex
    flex-flow: row nowrap
    align-items: center
    justify-content: center

    @media (max-width: $mdscreen)
      flex-flow: row wrap

  .file-scale
    display: flex
    flex-flow: column wrap
    align-items: center
    justify-content: center
    padding: 1em 1.5em

    @media (max-width: $smscreen)
      padding: 1em

    label
      margin: 0 0 0.75em

    .screen-image-zoom, .screen-image-zoom:focus
      flex: 1
      box-shadow: none
      -webkit-appearance: none
      appearance: none
      width: 100%
      height: 0.5em
      margin: 0.425em 0
      background: darken($color-light, 5%)
      outline: none
      padding: 0
      border-radius: $radius-btn
      font-size: 1em

    .screen-image-zoom::-webkit-slider-thumb
      -webkit-appearance: none
      appearance: none
      width: 1.5em
      height: 1.5em
      margin: -0.25em 0
      background: $color-accent-dark
      box-shadow: 0 1px 2px rgba(black, 0.15)
      border-radius: 100%

  .file-palette
    display: flex
    flex-flow: row wrap
    align-items: center
    justify-content: center
    background: white
    padding: 0.825em 1.5em

    @media (max-width: $smscreen)
      padding: 1em

    .palette
      width: 3.15em
      height: 3.15em
      margin: 0.5em
      border-radius: 100%
      box-shadow: 0 0.15em 0 rgba(black, 0.085), 0 0 0.5em rgba(black, 0.075), 0 0 0 1px white inset
      transition: box-shadow ease $speed-faster
      cursor: pointer
      display: flex
      align-items: center
      justify-content: center
      position: relative

      @media (max-width: $lgscreen)
        width: 3em
        height: 3em

      @media (max-width: $mdscreen)
        width: 2.85em
        height: 2.85em

      &.custom
        border: 0.075em solid $color-light

        &.active:after
          content: "(custom)"
          position: absolute
          top: calc(100% + 0.5em)
          color: $color-text-min
          font-size: 0.67em

        .ui, .dropdown
          display: flex
          justify-content: center
          width: 100%
          height: 100%
          position: relative

        .ui.menu .menu
          background: white
          bottom: calc(100% + 0.75em)
          left: auto
          right: -1.5em
          margin: auto
          padding: 0 0 0.5em

          &:before
            display: none

        .sketch-picker:before
          top: 100%
          border-color: white transparent transparent
          bottom: auto
          left: auto
          right: 1.85em

      &.active
        border: 0.15em solid white

      .palette-active-indicator
        height: 0
        width: 0
        bottom: calc(100% + 0.25em)
        left: calc(50% - 0.5em)
        position: absolute
        border-width: 0.5em
        border-style: solid
        border-color: transparent transparent black
        filter: drop-shadow(0 0 1px rgba(black, 0.2))
        border-radius: 0.15em
        animation: showContentFadeUp $speed-faster ease forwards
        opacity: 0

      .icon
        font-size: 1.5em
        color: $color-darker
        text-shadow: 0 1px 0 white

      .palette-custom-trigger
        position: absolute
        height: 100%
        width: 100%
        top: 0
        left: 0
        border-radius: 100%
        display: flex
        align-items: center
        justify-content: center

  .file-detail
    height: auto
    text-align: right
    position: relative
    background: white

    &:after
      display: none

    p
      color: $color-text-min
      position: relative
      padding: 0 1.5em 1em
      text-shadow: none
      width: auto

      .file-text, .file-size
        position: relative
        display: inline-block
        width: auto
        margin: 0
        top: auto
        right: auto
        bottom: auto
        left: auto

  .file-loading
    background-size: 95% 95%
    filter: drop-shadow(0 0.15em 0.33em rgba(black, 0.25))

.screen-controls-wrap
  display: flex
  width: 100%
  flex-flow: row nowrap
  justify-content: center
  align-items: center
  position: relative
  background: linear-gradient(rgba(black, 0.15), white 0.5em)

  .screen-controls
    display: inline-flex
    box-shadow: 0 0.15em 0.33em rgba(black, 0.215), 0 -0.15em 0.33em rgba(black, 0.215)
    margin: 2em auto 1em
    border-radius: $radius-btn
    overflow: hidden

  .screen-control
    display: flex
    align-items: center
    justify-content: center
    padding: 0.75em 1em
    background: $color-lightest
    transition: background ease $speed-faster, color ease $speed-faster
    color: $color-body-text
    cursor: pointer
    position: relative

    &:hover
      background: $color-accent-dark
      color: white

    &.active
      background: $color-accent
      color: white
      cursor: default

    .icon
      font-size: 1.67em

.palette-values
  > span
    display: block
    margin: 0.5em 0
    font-size: 0.85em

    &:first-child
      font-size: 1em

.popup-color
  &.top
    margin-bottom: 0.25em