.projects, .styleguides, .showcases, .personas
  &.limit #header
    margin-top: 3em

  &.limit-dismiss, &.limit-dismiss.limit
    #header
      margin-top: 0

    .items-limit
      animation: none
      display: none

#projects, #styleguides, #showcases, #personas
  padding-bottom: 6em

  .item-inner
    min-height: 48em

  .items-header
    display: flex
    flex-flow: row nowrap
    align-items: center
    margin: 1.25em 0 0.5em
    
    &.archive
      .items-view .dropdown .trigger
        animation: showFadeNext $speed-normal ease forwards

    h1
      margin: 0.25em 0
      font-size: 2.75em

      @media (max-width: $smscreen)
        font-size: 2.5em

      @media (max-width: $xsscreen)
        font-size: 2em

    .items-header-trigger
      display: inline-block
      padding: 0

      &:hover h1
        color: $color-secondary

      .icon
        font-size: 0.425em
        top: -0.15em

    .items-view
      flex: 1

      .dropdown
        display: inline-block
        position: relative

        &.active
          .menu
            opacity: 1
            animation: showFade $speed-fast ease forwards

        .menu
          right: -1.15em
          margin-top: -0.25em
          opacity: 0
          animation: hideFade 0s $speed-normal ease forwards

          @media (max-width: $smscreen)
            right: -1.225em

        .menu .menu-item
          text-align: left
          font-size: 1em

  .items-controls
    display: flex
    flex-flow: row wrap
    align-items: center
    justify-content: center
    margin: 0 0.5em

    @media (max-width: $smscreen)
      margin: 0.5em 0 0

    > div:last-child
      margin-right: 0

    .items-search, .items-cols-trigger, .items-sort-trigger
      opacity: 0
      animation: showFade $speed-fast $speed-normal ease forwards
      position: relative

  .items-search
    flex: 1
    margin: 0 0.5em
    position: relative

    .icon
      font-size: 1.25em
      color: $color-primary-dark
      padding: 0.5em
      cursor: pointer
      transition: color ease $speed-fast
      position: relative
      z-index: 1

      @media (max-width: $smscreen)
        font-size: 1.5em

      &.icon-cross
        color: $color-alert

      &:hover
        color: $color-secondary

    .input
      position: absolute
      top: -0.7925em
      right: -0.5em
      width: 21em
      z-index: 0

      @media (max-width: $smscreen)
        top: -1.33em
        width: calc(100vw - 9.25em)

      input
        font-size: 0.925em
        opacity: 0
        animation: showFade $speed-fast ease forwards
        padding-right: 3.75em

        @media (max-width: $smscreen)
          background-color: rgba(white, 0.95)
          font-size: 16px
          padding-right: 3em

  .items-cols
    position: relative
    margin: 0 0.75em 0 0.33em

    @media (max-width: $mdscreen)
      display: none

    div.dropdown .menu
      padding: 0.75em 0.5em
      right: -0.5em
      margin-top: 0.33em

      &:before
        right: calc(50% - 0.5em)

      .cols-item
        &:hover > span
          border-color: $color-primary-light
          
        > span
          border-color: white

    .item-cols
      min-width: 3em

      &.cols-1
        .cols-item > span
          height: 0.67em

      &.cols-3
        .cols-item > span
          height: 0.85em

    .cols-item
      display: flex
      flex-flow: row nowrap
      padding: 0.15em

      &.cols-1
        > span
          height: 0.67em

      &.cols-3
        > span
          height: 0.85em

      &:hover > span
        border-color: $color-secondary

      > span
        border: 1px solid $color-primary-dark
        height: 1em
        margin: 0.125em
        flex: 1
        min-width: 0.64em
        border-radius: 2px
        transition: border ease $speed-faster

  .items-sort
    position: relative
    font-size: 1em
    margin-left: auto
    margin: 0 0.75em

    @media (max-width: $smscreen)
      font-size: 1.15em

    div.dropdown .menu
      padding: 0.75em 1.25em 0.75em 1em
      right: -1.25em
      margin-top: -0.5em
      font-size: 0.85em

      @media (max-width: $mdscreen)
        font-size: 0.925em

      @media (max-width: $smscreen)
        font-size: 1em
        right: -1.33em

      a, .link
        text-align: left
        text-transform: none

  .btn-item-add
    height: 3.85em
    width: 3.85em
    padding: 0
    display: flex
    align-items: center
    justify-content: center
    margin-left: 1.5em
    margin-right: -0.75em
    opacity: 0
    animation: showContentFade $speed-normal ease forwards

    @media (max-width: $smscreen)
      position: fixed
      bottom: 1.5em
      right: 1.5em
      height: 5em
      width: 5em
      margin: 0
      z-index: 1

    &[disabled]
      background: rgba($color-dark, 0.25)
      animation: none

      @media (max-width: $smscreen)
        background: rgba($color-dark, 0.75)
        pointer-events: auto

      &:hover .icon
        animation: none

    &:hover
      .icon
        animation: spinAround $speed-fast ease forwards
        text-shadow: 0.075em 0 0.15em rgba(black, 0.33)

    .icon
      font-size: 1.75em
      animation: spinBack $speed-fast ease forwards

      @media (max-width: $smscreen)
        font-size: 2.25em

.items-list, .items-archive
  &.archiving, &.unarchiving, &.deleting
    .item-card
      animation-delay: 0s
      animation-duration: 0s
      opacity: 1
      cursor: initial

      &.archive, &.unarchive, &.delete
        animation: hideCard $speed-normal forwards ease

      &.unarchive, &.delete
        .card-delete
          opacity: 0

.items-list
  &.searching
    .item-card
      animation-delay: 0s
      animation-duration: $speed-fast

  &.duplicating
    .item-card.duplicate
      &:before, &:after
        animation: showFade $speed-fast forwards ease

  .item-card.duplicate
    pointer-events: none

    &:before, &:after
      content: ""
      position: absolute
      top: 0
      left: 0
      height: 100%
      width: 100%
      opacity: 0
      animation: hideFade $speed-fast forwards ease
      z-index: 101

    &:before
      background: rgba(black, 0.75)
      border-radius: $radius-border

    &:after
      background-image: url('../images/loading-md.svg')
      background-position: center center
      background-size: 12em
      background-repeat: no-repeat

.items-archive
  .archived-none
    text-align: center
    margin: 1.5em auto
    color: $color-text-min

  .archive-list
    position: relative
    font-size: 0.67em

    .item-card
      width: calc(33.33% - 3em)

      @media (max-width: $mdscreen)
        width: calc(50% - 2em)

      @media (max-width: $smscreen)
        width: calc(100% - 2em)

      &:hover
        cursor: default

        .card-delete
          animation: showCardActiveRight $speed-faster forwards ease

      .card-delete-wrap
        animation: showFade $speed-faster $speed-faster forwards ease
        pointer-events: initial
        font-size: 1.25em

      .item-footer .btn
        margin: 0.25em auto 0.5em
        font-size: 1.25em

      h3
        padding-right: 2em
        position: relative

        span:empty:before
          content: "Untitled"

.items-zero
  text-align: center
  opacity: 0
  animation: showFade $speed-slow $speed-fast forwards ease
  margin-top: 3em

  &.archive
    animation: showFadeNext $speed-slow $speed-fast forwards ease

    .items-zero-img
      max-width: 24em

      @media (max-width: $xsscreen)
        max-width: 20em

    h3
      color: $color-text-min

  h3
    font-size: 2.33em

    @media (max-width: $xsscreen)
      font-size: 2.25em

.items-zero-img
  display: block
  margin: 0 auto
  padding: 0.75em 1.5em
  max-width: 24em

  @media (max-width: $mdscreen)
    padding-top: 1.5em

  @media (max-width: $xsscreen)
    max-width: 20em

.items-search-none
  text-align: center
  padding: 2em

  .icon
    font-size: 8em
    color: $color-alert
    opacity: 0
    animation: showContentFade $speed-slow ease forwards

  p
    font-size: 2.25em

.item-cards
  display: flex
  min-width: 100%
  flex-flow: row wrap
  align-items: stretch
  margin: 0 -1.5em

  @media (max-width: $mdscreen)
    margin: 0 -1em

  &.sorted
    .item-card
      animation: none
      opacity: 1
      z-index: 0

  &.item-one-col
    .item-card
      width: 100%
      margin-top: 0.875em
      margin-bottom: 0.875em
      display: flex
      opacity: 1
      animation: none

      @media (max-width: $smscreen)
        width: calc(100% - 2em)
        margin-top: 0.75em
        margin-bottom: 0.75em

      h3
        font-size: 1.33em

      .item-nav-trigger
        font-size: 1.5em

      .item-menu
        position: relative
        order: 2
        z-index: unset

      .project-item-content, .styleguide-item-content, .showcase-item-content
        display: none

      .item-progress
        top: calc(100% - 0.2875em)
        border: 0 none
        box-shadow: none
        border-radius: 1rem
        margin-left: 0.075em
        width: calc(100% - 0.15em)
        height: 0.275em

        .progress-bar
          border-radius: 0 0 1rem 1rem

      .item-footer
        flex-flow: row nowrap
        order: 1
        z-index: 0

      .item-users
        margin: auto 2em
        display: flex

        .item-user
          .profile-img-wrap
            height: 2.5rem
            width: 2.5rem

      .item-updated-date
        padding-top: 1em
        padding-bottom: 1em
        white-space: nowrap
        min-width: 9.25rem

  &.item-three-col
    font-size: 0.725em

    @media (max-width: $mdscreen)
      font-size: 1em

    .item-card
      width: calc(33.33% - 3em)

      @media (max-width: $mdscreen)
        margin: 1.5em 1em
        width: calc(50% - 2em)

      @media (max-width: $msscreen)
        margin: 1.5em 1em
        width: calc(100% - 2em)

      .item-menu div.dropdown .menu
        font-size: 1.25em
        right: 0.115em

        @media (max-width: $smscreen)
          font-size: inherit
          right: 0.5em

      .item-users
        margin: -1.25em 1.25em 0.75em

      .item-user .profile-img-wrap
        height: 2.5rem
        width: 2.5rem
        border-width: 2px

      .item-updated-date
        font-size: 0.825em

.item-card
  width: calc(50% - 3em)
  margin: 1.5em
  background: white
  border-radius: $radius-btn
  box-shadow: 0 0.525em 0 -0.2em rgba(black, 0.075)
  transition: box-shadow ease $speed-faster
  position: relative
  transform: none
  opacity: 0
  animation: showItem $speed-slower forwards ease

  @media (max-width: $mdscreen)
    margin: 1.5em 1em
    width: calc(50% - 2em)

  @media (max-width: $msscreen)
    margin: 1.5em 1em
    width: calc(100% - 2em)

  @media (max-width: $smscreen)
    transition: none

  @for $i from 1 to 100
    &:nth-child(#{$i})
      animation-delay: $i * 0.075s

  &:hover
    box-shadow: 0 0.75em 0.75em rgba(black, 0.2)
    cursor: pointer

  &.published
    .item-progress
      display: none

  &.item-invite
    text-align: center

    .item-content
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      padding-top: 4.925em
      opacity: 0.25
      filter: blur(3px)

      .item-palette
        top: 4.925em

    h3
      padding: 1em 1em 2.425em
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap

    .item-card-btn
      margin: 1em 0

      &[disabled]
        pointer-events: none

      .btn
        display: inline-block

    .btn-text
      font-size: 0.925em
      margin: 3.5em 0 0
      padding: 0
      text-shadow: 0 1px 0 white, 0 0 0.25em white, 0 0 0.5em white

  h3
    position: relative
    margin: 0
    padding: 1em 3em 1em 1em
    width: 100%
    font-size: 1.5em
    font-family: "mulilight"
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

    &:empty:before
      content: "Untitled"

  .item-card-link
    content: ""
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%
    z-index: 1

  .item-menu
    display: block
    position: absolute
    right: 0
    top: 0
    z-index: 3

    .item-nav-trigger
      font-size: 1.67em
      padding: 1em 0.85em
      cursor: pointer
      color: $color-primary-dark
      transition: color ease $speed-faster

      &:hover
        color: $color-primary
        
    div.dropdown .menu
      padding: 0.5em
      right: 0.5em
      margin-top: -1em

      .item-download
        @media (max-width: $mdscreen)
          display: none

    .menu-item
      padding: 0.67em 1em 0.67em 0.75em
      color: $color-primary-dark
      cursor: pointer
      font-size: 0.85em
      transition: color ease $speed-faster
      font-family: "muliregular"

      @media (max-width: $smscreen)
        font-size: 1.075em

      &:hover
        color: $color-accent

      .icon
        margin-right: 0.75em
        font-size: 1.15em
        position: relative
        top: 0.075em

  .item-progress
    top: 4.67em
    height: 0.2em
    background: rgba(white, 0.85)
    box-shadow: 0 1px 0 white, 0 -1px 0 white, 0 2px 1px 0.5px rgba(black, 0.15)

    .progress-bar
      box-shadow: none

  .item-updated-date
    padding: 1.5em 1.5em 1.5em 0
    font-size: 0.75em
    text-align: right
    flex: 1
    display: flex
    justify-content: flex-end
    align-items: center

    @media (max-width: $smscreen)
      font-size: 0.825em

    label
      color: $color-text-min

  .project-item-content, .styleguide-item-content, .showcase-item-content, .persona-item-content
    display: flex

  .showcase-item-content
    background-color: $color-lightest

  .item-images
    display: inline-block
    width: 100%
    height: 10.5em
    position: relative
    overflow: hidden

    &:after
      content: ""
      position: absolute
      top: 0
      left: 0
      height: 100%
      width: 100%
      background: linear-gradient(188deg, transparent 60%, rgba(black, 0.25))

    .has-one
      .item-image.loading
        background-size: 18em

    .has-two, .has-three
      .item-image
        width: 67%

        &.item-image-1.loading
          background-size: 16em

    .has-three
      .item-image.item-image-2
        height: 50%

    .item-image
      content: ""
      position: absolute
      background-size: cover
      background-position: center center
      background-repeat: no-repeat
      box-shadow: 0 0 0 0.25em white, -0.15em 0 0.75em rgba(black, 0.15)
      top: 0
      left: 0
      height: 100%
      width: 95%

      &.item-image-1 img
        display: none

      &.item-image-2, &.item-image-3
        width: 28%
        right: 5%
        left: auto

      &.item-image-2
        height: 100%

      &.item-image-3
        height: 50%
        top: auto
        bottom: 0

    .item-image-featured
      display: none

    .item-image-empty
      content: ""
      position: absolute
      top: 0
      left: 0
      height: 100%
      width: 100%
      background: $color-lightest

      .icon
        font-size: 7.5em
        color: $color-light
        position: absolute
        top: 50%
        left: -0.33em
        right: 0
        top: 0
        bottom: 0
        width: 1em
        height: 0.957em
        line-height: 1
        margin: auto

  .item-palette
    position: absolute
    right: 0
    top: 4.925em
    height: 10.5em
    width: 5%
    background: rgba(white, 0.925)
    padding: 0 0 0 0.25em

    @media (max-width: $mdscreen)
      bottom: 4.15em
      padding: 0 0 0 0.2em

    &:empty
      background: transparent

    .palette
      flex-flow: column wrap
      height: 100%
      width: 100%

      .color
        height: 33.333%
        width: 100%

        .hex
          display: none

  .item-logos
    display: inline-block
    width: 100%
    height: 10.5em
    position: relative
    overflow: hidden

    .item-logo
      content: ""
      position: absolute
      top: 0
      left: 0
      height: 100%
      width: 95%
      display: flex
      align-items: center
      background: $color-lightest
      overflow: hidden

      &.white.done
        background: $color-darkest

      img
        max-height: 67%
        max-width: 67%
        height: auto
        width: auto
        margin: auto
        left: 0
        right: 0

    .item-logo-empty
      content: ""
      position: absolute
      top: 0
      left: 0
      height: 100%
      width: 95%
      background: $color-lightest

      .icon
        font-size: 7.5em
        color: $color-light
        position: absolute
        top: 50%
        left: 0
        right: 0
        top: 0
        bottom: 0
        width: 1em
        height: 0.957em
        line-height: 1
        margin: auto

  .item-screens
    display: inline-block
    width: 95%
    height: 10.5em
    position: relative
    overflow: hidden

    .screen-desktop, .screen-mobile
      display: inline-flex
      background-color: black
      box-shadow: 0 0 0 1px $color-dark, 0 0 0 2px white, 0 1em 2em -0.15em rgba(black, 0.25)

      .item-screen
        position: relative
  
        img
          border-radius: $radius-btn $radius-btn 0 0

          &.done
            box-shadow: 0 0 0 1px rgba(white, 0.175)
          
    .screen-desktop
      margin: 1em 1.5em 0
      height: calc(100% - 1em)
      width: calc(100% - 3em)
      padding: 0.67em
      border-radius: $radius-btn * 2 $radius-btn * 2 0 0

    .screen-mobile
      margin: 0.825em 24% 0
      height: calc(100% - 0.825em)
      width: 52%
      padding: 0.525em
      border-radius: $radius-btn * 2 $radius-btn * 2 0 0

    .item-screen
      content: ""
      position: absolute
      top: 0
      left: 0
      height: 100%
      width: 100%
      background-size: cover
      background-position: center top
      display: flex
      align-items: flex-start
      justify-content: center

      &.short
        align-items: center

      &.background
        &.scale-1
          padding: 0.25em 0.33em

        &.scale-2
          padding: 0.5em 0.67em

        &.scale-3
          padding: 0.825em 1em

        &.scale-4
          padding: 1.15em 1.33em

        &.scale-5
          padding: 1.5em 1.67em

        &.scale-6
          padding: 1.5em 2em 1.825em

        &.scale-7
          padding: 1.5em 2.33em 2.15em

        &.scale-8
          padding: 1.5em 2.67em 2.5em

        &.scale-9
          padding: 1.5em 3em 2.825em

        &.scale-10
          padding: 1.5em 3.33em 3.15em

        &.scale-11
          padding: 1.5em 3.67em 3.5em

        &.scale-12
          padding: 1.5em 4em 3.825em

      img
        max-width: 100%
        height: auto
        width: auto

      &.loading
        background-size: 8.5em
        background-position: center center

        &.background
          padding: 0

        img
          opacity: 0

      &.done
        img
          opacity: 0
          animation: showFade $speed-fast ease forwards
          filter: drop-shadow(0 0.25em 0.5em rgba(black, 0.15))

    .item-screen-empty
      content: ""
      position: absolute
      top: 0
      left: 0
      height: 100%
      width: 100%
      background: $color-lightest

      .icon
        font-size: 7.5em
        color: $color-light
        position: absolute
        top: 50%
        left: 0
        right: 0
        top: 0
        bottom: 0
        width: 1em
        height: 0.957em
        line-height: 1
        margin: auto

  .item-fonts
    position: absolute
    right: 5%
    top: 4.925em
    height: 10.5em
    width: 15%
    display: flex
    flex-flow: column wrap

    .item-font
      text-align: center
      flex: 1
      background: white

      &:nth-child(2)
        border-top: 0.2em solid $color-lightest

      p
        position: relative
        top: 50%
        transform: translateY(-50%)
        margin: 0
        padding: 0
        font-size: 1.75em
        color: $color-darker
        line-height: 1

    .inline-loading
      height: 100%
      width: 100%
      background-position: center center
      display: block


  .item-footer
    display: flex
    flex-flow: row wrap

    &.disabled .btn
      font-style: italic

      .icon
        position: relative
        margin-left: 0.33em
        top: 0.125em

  .item-users
    margin: -1em 1.5em 1em

    @media (max-width: $mdscreen)
      margin-bottom: 1em

    .item-user
      display: inline-block
      font-size: 0.33em
      margin: 0.75em -0.75em 0
      position: relative
      white-space: nowrap

      @media (max-width: $mdscreen)
        margin: 0.75em -1em 0

      @for $i from 2 to 25
        &:nth-child(#{$i})
          z-index: calc(25 - #{$i})

      &.user-me
        float: left
        z-index: 100

      &.user-count .profile-img-wrap
        background: $color-primary-dark

      .profile-img-wrap
        height: 3rem
        width: 3rem

    .profile-img-wrap
      margin: 0 auto
      box-shadow: 0 -0.15em 0.25em rgba(black, 0.15)
      border-width: 3px

      &:hover
        border-color: white

#projects .item-card
  &.no-images .item-images
    &:after
      display: none

  &.no-palette .item-images
    .item-image
      width: 100%

    .item-image-empty .icon
      left: 0

    .has-two, .has-three
      .item-image
        width: 70%

        &.item-image-2, &.item-image-3
          width: 30%
          right: 0

#styleguides .item-card
  &.no-palette
    &.has-fonts
      .item-logos 
        .item-logo, .item-logo-empty
          width: 85%

      .item-fonts
        right: 0

    .item-logos 
      .item-logo, .item-logo-empty
        width: 100%

  &.has-fonts
    .item-logos 
      .item-logo, .item-logo-empty
        width: 80%

#showcases .item-card
  &.no-palette
    &.has-fonts
      .item-screens
        width: calc(85% - 0.2em)

      .item-fonts
        right: 0

    .item-screens 
      width: calc(100%)

  &.has-fonts
    .item-screens
      width: calc(80% - 0.2em)

.items-limit
  text-align: center
  opacity: 0
  animation: showSlideDown $speed-normal $speed-faster ease forwards
  height: 3em
  position: fixed
  top: 0
  left: 0
  z-index: 1
  background: rgba($color-accent, 0.925)
  width: 100%
  box-shadow: 0 0.075em 0.15em rgba(black, 0.25)
  transition: background ease $speed-faster

  &:hover
    background: darken($color-accent, 7.5%)

  p
    font-size: 0.925em
    color: white

    .icon
      margin-right: 0.5em
      position: relative
      top: 0.15em
      font-size: 1.25em
      line-height: 0.75

    span
      text-decoration: underline
      margin-left: 0.15em

      &:hover
        text-decoration: none

  > .icon
    position: absolute
    right: 0.5em
    top: 0.175em
    color: white
    font-weight: 600
    padding: 0.75em
    transition: color ease $speed-faster

    &:hover
      color: $color-primary-light

#item-download-wrap
  position: relative
  max-height: 0
  width: 100%
  overflow: auto
  opacity: 1
  z-index: 3