.item-theme
  .component-section-wrap
    margin-top: 0
    padding-top: 8.5em

    &:before
      display: none

  h2
    text-align: center

  .component-theme-wrap
    display: flex
    justify-content: center
    width: 100%

.component-theme, .component-theme.card
  width: 27.5%
  background: $color-lightest
  border-radius: $radius-btn
  margin: 1em 1.5em
  overflow: hidden
  color: $color-text-min
  padding: 0

  @media (max-width: $smscreen)
    width: 60%

  &.theme-light
    background: white

  &.theme-dark
    background: darken($color-darkest, 4%)

    .styleguide .theme-header,
    .showcase .theme-header
      background: black

    .theme-image
      background-color: rgba(white, 0.15)

  &.selected
    box-shadow: 0 0 0 0.25em $color-success, 0 0.5em 0 -0.125em rgba(black, 0.085)

  h4
    text-align: center
    color: $color-darker
    margin: 0.5em
    font-size: 1.25em
    font-weight: 600
    text-transform: capitalize

.theme-layout > div
  display: flex
  justify-content: center

.theme-layout
  width: 100%

  &.project
    .theme-header
      margin-bottom: 1.5em
      background: $color-darker

      h4
        color: white

    .theme-audience
      display: block
      margin: 1em 0

      .theme-line
        margin: 0.5em auto
        background: $color-darker
        display: flex
        align-items: center
        justify-content: center

        .icon
          position: absolute
          color: white
          border-radius: 100%
          padding: 0.5em
          height: 2em
          width: 2em
          font-size: 0.67em
          text-align: center
          font-weight: 900
          background: $color-darker

    .theme-palette
      padding: 0 0.5em

    .theme-problem
      display: block

      .theme-line
        margin: -0.25em auto 0
        height: 0.25em

  &.styleguide
    .theme-font
      padding-bottom: 0.25em

  &.styleguide, &.showcase
    .theme-palette .theme-color
      font-size: 1.25em

  &.showcase
    padding-bottom: 0.75em

    .theme-files
      margin-top: 0
      margin-bottom: 0.5em

      .theme-file
        font-size: 1.15em

.theme-header
  padding: 0.75em
  background: rgba(white, 0.75)

.theme-files
  display: flex
  width: 100%
  flex: 100%
  justify-content: center
  margin: 0.75em 0

  .theme-file
    height: 1.875em
    width: 2.25em
    margin: 0.25em
    background: $color-text-min
    position: relative
    overflow: hidden
    display: flex
    align-items: center
    justify-content: center
    border-radius: $radius-border

    &:before
      content: ""
      position: absolute
      height: 3em
      width: 3em
      background: rgba(white, 0.15)
      transform: rotate(45deg) translateX(-2.25em)
      left: 0
      top: 0

    .icon
      color: white
      font-size: 0.85em

.theme-logos
  padding: 0 0.75em
  text-align: center

  > img
    max-width: 80%
    max-height: 2.75em
    margin: 0.25em auto 1.25em

  .theme-logo
    height: 1.5em
    margin: 0 auto 1em
    width: 60%
    background: $color-darker
    position: relative
    border-radius: 1em

.theme-buttons
  display: flex
  flex-flow: column wrap
  padding: 0 1.5em
  margin: 0.5em auto 1.5em

  .btn-group
    display: flex
    flex-flow: row nowrap
    margin: 0.15em -0.15em

  .btn
    font-size: 0.575em
    padding: 0.75em 1em
    flex: 1
    margin: 0.33em 1.5em
    display: flex
    align-items: center
    justify-content: center
    text-shadow: none
    box-shadow: none

.theme-palette
  .theme-color
    height: 2em
    width: 2.25em
    margin: 0.25em 0.175em
    position: relative
    background: $color-dark
    border-radius: calc($radius-border / 2)

    &:before
      content: ""
      position: absolute
      bottom: 0
      left: 0
      width: 100%
      height: 33%
      background: linear-gradient(90deg, rgba(black, 0.125), rgba(white, 0.25))

.theme-font
  padding: 0.5em 0.75em
  font-size: 1.25em

  .theme-aa
    font-size: 1.25em

  .theme-line
    margin: 0.5em
    width: 42.5%

.theme-line
  height: 0.5em
  width: 33%
  background: $color-text-min

  &.line-long
    width: 70%

.theme-images
  margin: 0.5em 2.5em 1.5em
  flex-flow: row wrap

.theme-image
  width: calc(50% - 0.5em)
  margin: 0.25em
  height: 3em
  background-color: rgba(black, 0.15)
  background-size: cover
  background-position: center center
  background-repeat: no-repeat

.theme-icons
  padding: 0.5em

  .theme-icon
    height: 1em
    width: 1em
    background: $color-text-min
    border-radius: 100%
    margin: 0.5em

.theme-screens
  flex-flow: column wrap
  margin: 0 0 1em

  &.desktop, &.mobile
    .theme-screen
      border-radius: $radius-btn
      // border: 1px solid $color-dark

  &.desktop
    padding: 0.25em 2em 1em

    .theme-screen
      max-height: 8.5em
      box-shadow: 0 0 0 0.367em $color-darkest, 0 0 0 calc(0.367em + 1px) white, 0 0.5em 0.5em rgba(black, 0.5)

  &.mobile
    padding: 0.25em 2em 1em

    .theme-screen
      width: 6em
      margin: 0 auto
      height: 12em
      box-shadow: 0 0 0 0.25em $color-darkest, 0 0 0 calc(0.25em + 1px) white, 0 0.5em 0.5em rgba(black, 0.5)

  &.background
    padding: 0.5em

    &.scale-2
      padding: 0.75em

    &.scale-3
      padding: 1em

    &.scale-4
      padding: 1.25em

    &.scale-5
      padding: 1.5em

    &.scale-6
      padding: 1.75em

    &.scale-7
      padding: 2em

    &.scale-8
      padding: 2.25em

    &.scale-9
      padding: 2.5em

    &.scale-10
      padding: 2.75em

    &.scale-11
      padding: 3em

    &.scale-12
      padding: 3.25em

    .theme-screen
      max-height: 10.5em
      box-shadow: 0 0.25em 0.67em -0.15em rgba(black, 0.33)

  &.normal
    .theme-screen
      max-height: 11em

.theme-screen
  width: 100%
  overflow: hidden
  display: flex

  img
    width: 100%
    height: auto

// DARK THEME

body.dark
  background-color: lighten($color-darkest, 3%)

  @media (max-width: $smscreen)
    background-color: lighten($color-darkest, 4%)

  .item-drag
    background-color: $color-darkest

    &.element-button
      background-color: transparent

    &.image-drag
      background-color: $color-lightest

    .file-icon i
      color: white

    .alt-color
      box-shadow: 0 0 0 0.25em lighten($color-darkest, 6%) inset

  .card-expand, .card-download, .card-link, .card-clear, .card-edit
    &:hover:before
      background-color: darken($color-darkest, 2%)

    &:before
      background-color: black

    .icon
      color: $color-light

  .content-outer .fonts-typekit-list
    color: $color-darkest

    label
      color: $color-darkest

.dark, .theme-dark
  .ui.popup
    color: $color-lighter
    background: lighten($color-darkest, 10%)

    &.bottom:before
      border-color: transparent transparent lighten($color-darkest, 10%)

    &:before
      border-top-color: lighten($color-darkest, 10%)

    h5
      color: $color-primary-light

  .ui.menu div.dropdown .menu
    background: $color-lighter
    filter: drop-shadow(0 0.125em 0.25em rgba(black, 0.5))

    &:before
      border-bottom-color: $color-lighter

    .menu-item
      color: $color-primary-darker

      &:hover
        color: $color-primary-access

    .item-menu-admin
      border-top-color: rgba(black, 0.2)

  .item-menu div.dropdown .menu .menu-item.item-delete
    color: $color-alert

    &:hover
      color: darken($color-alert, 10%)

  #elements .edit-element .ui.menu .link
    color: white

    &:hover
      color: $color-accent

  .elements-wrap#elements .elements-toggle
    background: $color-darkest

    h3
      color: $color-lighter
      border-bottom-color: rgba(white, 0.15)

      .btn-alert
        color: lighten($color-alert, 10%)

        &:hover
          color: $color-alert

      .btn-success
        color: lighten($color-success, 10%)

        &:hover
          color: $color-success

  .text-content .text-content-display .content-display:hover
    background: rgba(white, 0.025)

  .text-content .text-content-edit 
    .text-edit-btn
      &:hover
        &:before, &:after
          border-color: rgba(white, 0.5)

      &:before, &:after
        border-color: rgba(white, 0.33)

      .icon
        text-shadow: 0 1px 0 rgba(black, 0.75)
        opacity: 0.15

    .content-edit
      background: rgba($color-darkest, 0.67)

      .ql-toolbar
        background: $color-darkest

    .quill .ql-snow
      button.ql-active
        .ql-stroke
          stroke: $color-primary

        .ql-fill
          fill: $color-primary

      .ql-picker .ql-picker-label
        color: $color-lighter

      .ql-stroke
        stroke: $color-lighter

      .ql-fill
        fill: $color-lighter

  .files-dropzone
    background: $color-darkest

    &:hover
      background: black

.theme-dark.card .theme-thumb .theme-header h4
  color: $color-light

#projects, #styleguides, #showcases
  .items-list .item-card, .archive-list .item-card
    &.theme-dark
      background: lighten($color-darkest, 2%)

      h3, .item-menu .item-nav-trigger
        color: white

      .item-progress
        background: black
        box-shadow: 0 1px 0 black, 0 -1px 0 black

      .item-menu .item-nav-trigger:hover
        color: $color-primary-light

      .item-updated-date label
        color: $color-dark

      .profile-img-wrap
        background-color: $color-darkest
        border-color: lighten($color-darkest, 5%)

      .item-images .item-image
        box-shadow: 0 0 0 0.25em black, -0.15em 0 0.75em rgba(black, 0.15)

      .item-palette
        background: transparent

      .item-logos 
        .item-logo, .item-logo-empty
          background-color: black

        .item-logo-empty i
          color: lighten($color-darkest, 10%)

      .item-fonts .item-font
        background: lighten($color-darkest, 2%)
        border-top-color: black

        p
          color: white

      .item-footer .btn
        color: $color-primary-light

#project, #project-edit,
#styleguide, #styleguide-edit, 
#showcase, #showcase-edit
  &.theme-dark
    color: $color-lightest

body, #project, #project-edit,
#styleguide, #styleguide-edit, 
#showcase, #showcase-edit
  &.dark, &.theme-dark
    .card-expand, .card-download, .card-link, .card-clear, .card-edit
      &:hover:before
        background-color: darken($color-darkest, 2%)

      &:before
        background-color: black

      .icon
        color: $color-light

    .btn.btn-white, .card-reorder
      background: darken($color-darkest, 4%)
      color: white

      &:hover
        background: $color-darkest

    .btn.btn-light
      background: $color-darkest
      color: white

      &:hover
        background: darken($color-darkest, 4%)

    .cards-list .card:hover
      box-shadow: 0 0.415em 0.67em rgba(black, 0.5)

    .type-select-wrap .type-select-autocomplete > div
      background: rgba(black, 0.925)

    .fonts-custom-list .card.font-primary-drop
      background: transparent

    .file-icon i
      color: white

#project, #styleguide, #showcase
  &.theme-dark
    .item-header
      h1
        color: $color-light

    .item-body
      h2, h3
        color: $color-light

      .item-palette-report
        background: $color-darkest

        h3
          color: $color-light

        a, .link
          color: $color-primary-light

          &:hover
            color: $color-secondary-light

      .item-palette-report-wrap
        .btn.btn-light
          background: darken($color-darkest, 1%)

          &:hover
            background: darken($color-darkest, 3%)

        .palette-alt-report
          border-top-color: rgba(white, 0.15)

    .item-header
      background-color: darken($color-darkest, 1%)

    .item-body
      background-color: lighten($color-darkest, 1%)

    .item-header, .item-body
      label, .font-wrap h3
        // color: $color-dark

    .item-details-due .details-due-date
      color: $color-darkest

    .item-content.no-paletteHeader .item-body .styleguide-nav > ul
      background: darken($color-darkest, 4%)

      > li
        color: white

    .item-publish-nav .item-back-btn > .icon,
    .item-publish-nav .menu .item-nav-trigger > .icon
      color: $color-light
      text-shadow: 0 2px 0 rgba(black, 0.25)

    .item-publish-nav .item-back-btn,
    .item-publish-nav .menu .item-nav-trigger
      &:hover .icon
        color: inherit

    .sticky-holder
      .sticky ul
        background: rgba($color-darkest, 0.925)

        li
          color: white
          text-shadow: 0 1px 1px rgba(black, 0.15)

          &:hover
            box-shadow: 0 -0.15em 0 white inset

    .sticky-holder .sticky .palette-header ul
      background: rgba(white, 0.075)

    .publish-section .sub-content > h2
      color: $color-light

    .publish-section p
      color: $color-lightest

    .item-palette-color
      box-shadow: 0 0 0 0.15em black, 0 0.5em 0 -0.15em rgba(black, 0.05)

    .item-logos-wrap .item-logo,
    .item-inspiration-font .item-publish-font,
    .publish-section:nth-child(even) .item-inspiration-font .item-publish-font,
    .item-publish-images .item-image,
    .item-inspiration-icons .icons-grid,
    .item-inspiration-elements .content-headers,
    .item-inspiration-elements .content-paragraph
      background-color: $color-darkest

    #elements .btn.btn-element-css,
    .item-icons-wrap .item-icons .icons-grid .icons-download .btn.btn-download-icons
      background-color: lighten($color-darkest, 5%)
      color: rgba(white, 0.5)
      box-shadow: 0 0.33em 0 -0.15em rgba(black, 0.15)

      &:hover
        box-shadow: 0 0.5em 1em -0.15em rgba(black, 0.75)
        color: rgba(white, 0.75)

      h3
        color: $color-lighter

    .item-icons-wrap .item-icons .icons-grid .icons-upload-nodata
      background: rgba(black, 0.5)
      
#project.theme-dark
  .publish-section
    &:nth-child(odd)
      background: darken($color-darkest, 4%)
      box-shadow: 0 0 0.75em rgba(black, 0.5)

    &:nth-child(even)
      background: rgba(black, 0.125)
      box-shadow: none

    &.item-publish-members
      box-shadow: 0 0.5em 1em rgba(black, 0.5) inset
      background: darken($color-darkest, 1%)

    .publish-icon-wrap.icon-wrap-small:after
      background: $color-darkest

    h2, h3
      color: $color-light

#styleguide.theme-dark
  .item-body.no-palette
    box-shadow: 0 -0.25em 0.75em rgba(0, 0, 0, 0.15)

    .sticky-holder
      position: relative

      ul
        background: darken($color-darkest, 2%)

        li
          color: $color-lightest
          text-shadow: 0 1px 0 rgba(black, 0.5)

          &:hover
            box-shadow: 0 -0.15em 0 $color-darker inset

          &.active
            box-shadow: 0 -0.15em 0 $color-dark inset

  .item-element
    .element-css
        background: darken($color-darkest, 4%)

        &:before
          border-bottom-color: darken($color-darkest, 4%)

  .text-content
    h1, h2, h3, h4, h5, p
      color: white

#project-edit
  &.theme-dark
    .item-palette .palette-list
      .card:hover
        background: lighten($color-darkest, 10%)

#project-edit, #styleguide-edit, #showcase-edit
  &.theme-dark
    .icons-upload-nodata a
      color: $color-primary-light

      &:hover
        color: $color-secondary-light

    .item-theme .component-section-wrap
      .component-theme
        &.card:hover
          box-shadow: 0 0.5em 1.5em black

        &.card.selected, &.card.selected:hover
          box-shadow: 0 0 0 0.25em $color-success, 0 0.25em 0 -0.125em rgba(black, 0.875)

#project-edit, #styleguide-edit, #showcase-edit, body
  &.theme-dark, &.dark
    .section-edit-wrap:before
      background: rgba(white, 0.033)

    .section-edit-wrap
      &:hover
        cursor: url('../images/cursor-edit-md_white.svg'), auto

      .icon
        background: rgba(black, 0.25)
        color: rgba(white, 0.5)
        text-shadow: 0 1px 0 black

    .btn-text
      color: $color-primary-light

      &:hover
        color: $color-secondary-light

    .btn-under-input
      color: $color-lighter

      &:hover
        color: $color-primary-light

    .type-select-wrap .type-select-content .type-select-item input
      background: rgba(black, 0.75)

      &:hover
        background: rgba(black, 0.85)
        box-shadow: 0 0.33em 0.5em rgba(black, 0.25), 3px 0 0 $color-primary inset

      &:focus
        background: rgba(black, 0.925)
        box-shadow: 0 0.25em 0.5em rgba(black, 0.25), 5px 0 0 $color-accent inset

    .content-outer
      input, textarea
        background: lighten($color-darkest, 2%)
        color: $color-light

        &:hover
          background: lighten($color-darkest, 4%)
          box-shadow: 0 0.25em 0.5em black, 3px 0 0 $color-primary inset

        &:focus
          background: lighten($color-darkest, 6%)
          box-shadow: 0 0.25em 0.5em rgba(black, 0.25), 5px 0 0 $color-accent inset

    .component-section-inner
      > h2, h2.with-help, h2 .header-help .icon
        color: $color-light

    .sticky .search-input
      background: rgba(black, 0.5)

      &:hover
        background: rgba(black, 0.75)

      &:focus
        background: rgba(black, 0.925)

    .images-list-search .image-search-results
      p
        color: $color-text-min

      .icon
        background: rgba(black, 0.925)

        &:hover
          background: $color-alert

    .search-input-icon,
    .type-select-search-icons .icon
      color: $color-darkest
      background: $color-primary-light
      text-shadow: 0 1px 0 rgba(black, 0.5)

      &:hover
        background: $color-darkest
        color: $color-primary-light

      &.active
        color: white

    .type-select-wrap .type-select-nav li
      background: $color-darkest
      color: $color-primary-light
      text-shadow: 0 1px 0 rgba(black, 0.5)

      &:hover
        color: $color-secondary-light
        background: darken($color-darkest, 4%)

      &.active
        color: white

    .item-nav-btns
      background: rgba(black, 0.5)

      @media (max-width: $smscreen)
        background: rgba(black, 0.925)

    .component-section-wrap:before, 
    .section-btn-done .btn, 
    .section-btn-done .btn .icon
      background-color: lighten($color-darkest, 7.5%)

    .component-section-wrap.done
      .component-section-wrap:before
        background-color: transparent

    .section-btn-done .btn
      &:hover, &:hover .icon
        background-color: $color-success
        color: white

    .section-btn-done .btn .icon, 
    .images-list-selected h2
      color: lighten($color-success, 5%)

    .item-name #item-name-input
      color: $color-light
      background: transparent

      &:hover
        background: rgba(black, 0.33)

      &:focus
        background: rgba(black, 0.67)

    .adjectives-list, .palette-list, .fonts-list, .fonts-custom-list, .audience-list, .details-list
      .card
        background-color: lighten($color-darkest, 4%)

        &:hover
          background-color: lighten($color-darkest, 6%)

      .card.selected
        background-color: $color-darkest

    .fonts-custom-list
      &:hover .font-detail
        background: transparent

      .font-variants-list .font-detail
        background: rgba(white, 0.05)
        
        .font-aa, .font-content
          opacity: 0.33

        &.active
          background: transparent

          .font-aa, .font-content
            opacity: 1

    .files-list
      .card.selected
        background-color: $color-darkest

      h5
        color: $color-light

    .item-palette
      .color-indicator
        border-top-color: lighten($color-darkest, 2%)

    .palette-alt-wrap .palette-alt.palette-alt-add
      background-color: lighten($color-darkest, 2%)

      &:hover 
        label, .icon
          color: $color-primary-light

      label, .icon
        color: $color-light

    .file-detail p.file-detail-content
      text-shadow: 0 1px 0 black
      color: $color-light

    .font-name
      background-color: lighten($color-darkest, 3%)
      color: lighten($color-primary-light, 5%)

      label
        color: lighten($color-success, 5%)

    .font-aa
      color: white

    .palette-alt-wrap .card
      background-color: rgba(black, 0.75)

      .alt-color
        box-shadow: 0 0 0 0.25em lighten($color-darkest, 6%) inset

    .sketch-picker
      background: lighten($color-darkest, 6%) !important

      &:before
        border-bottom-color: lighten($color-darkest, 6%)

      .flexbox-fix span
        color: $color-lighter !important

    .palette-list.custom .card-palette-custom .palette .color
      background: lighten($color-darkest, 10%)

      &:hover
        background: lighten($color-darkest, 2%)

      &.editing
        background: lighten($color-darkest, 2%)

      &.color-secondary
        box-shadow: 1px 0 0 lighten($color-darkest, 6%), -1px 0 0 lighten($color-darkest, 6%), 1px 0 0 lighten($color-darkest, 6%) inset, -1px 0 0 lighten($color-darkest, 6%) inset

      .color-picker-btn
        border-color: lighten($color-darkest, 6%)

    .palette-list.custom .card-palette-custom .palette,
    .item-palette .custom-palette-picker .color-picker-logo,
    .color-picker-alt,
    .color-picker-logo,
    .color-picker-brand
      background: lighten($color-darkest, 6%)

      label
        color: $color-light

    .font-content, .font-example, .content-outer label
      color: white

    .item-palette-color
      label
        color: inherit

    .edit-element-btn
      background: $color-darkest

    .font-variants-list
      background: lighten($color-darkest, 4%)

    .edit-element-btn:hover
      background: rgba(black, 0.5)

    .font-detail:hover
      background: lighten($color-darkest, 4%)

    #header-element .header-wrap .edit-element
      background: lighten($color-darkest, 5%)

    #header-element .header-wrap .edit-element:before
      border-bottom-color: lighten($color-darkest, 5%)

    .item-elements
      .done .elements-item .elements-content 
        .element, .element.active
          background: $color-darkest

      .elements-item .elements-content .element
        background: $color-darkest

        &.active
          background: lighten($color-darkest, 2%)

        &.disabled
          background: black

      .sketch-picker input
        background: lighten($color-darkest, 2%)
        color: $color-light

    #elements .element-button .element-button-drag, 
    .button-drag .element-button-drag
      &:hover
        background: black

    #elements .edit-element .edit-item-input > input
      background: rgba(black, 0.5)
      color: white

    .ui.menu > label
      background: transparent
      color: white

    #elements .edit-element .edit-item-colors > label
      background: rgba(black, 0.75)
      color: white

    #elements .edit-element .edit-item-input > label
      background: transparent
      color: white

    #elements .checkbox input ~ label, 
    #elements .checkbox input:checked ~ label
      color: $color-light

    #elements .checkbox.disabled input ~ label
      color: $color-text-min

#showcases
  .item-card.theme-dark
    .showcase-item-content
      background: black

    .item-screens 
      .screen-desktop, .screen-mobile
        background: white
        box-shadow: 0 0 0 1px $color-dark, 0 0 0 2px $color-darkest, 0 1em 2em -0.15em rgba($color-dark, 0.5)

#showcase-edit, .showcases
  .card.theme-dark .theme-screens
    &.desktop, &.mobile
      .theme-screen
        box-shadow: 0 0 0 0.33em $color-lightest, 0 0 0 calc(0.33em + 1px) $color-dark, 0 0.5em 0.5em rgba($color-darker, 0.5)

#showcase, #showcase-edit
  &.theme-dark, &.dark
    .screens-list
      .file-content
        background: black

      .screen-controls-wrap, .file-background, .file-palette, .file-detail
        background: $color-darkest

      .file-palette .icon
        color: white
        text-shadow: 0 1px 0 black

      .sticky .screen-controls-wrap
        background: transparent

      .screen-desktop, .screen-mobile
        .file-img
          background: white
          box-shadow: 0 0 0 2px $color-dark, 0 0 0 4px $color-darkest, 0 1em 2em -0.15em rgba($color-dark, 0.5)

        .file-img-wrap
          box-shadow: 0 0 0 1px rgba(black, 0.15)