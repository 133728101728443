.item-images
  transition: opacity ease $speed-faster

  .component-section-wrap.done
    .file-detail
      display: none

    .images-list-search, .images-files-dropzone, .images-advanced-toggle
      display: none

    .images-list-selected.active
        margin: 0
        padding: 0

        .card.selected
          flex: 0

    .images-files-upload
      margin: 0

  .images-advanced-toggle
    text-align: center

    .btn
      margin: 1em auto 0
      font-size: 1em

  .file-img-bg
    background-size: cover

.images-files-upload
  margin-bottom: 4.5em

.images-list-selected
  display: none
  margin: 0

  &.active
    display: block
    margin: -8.5em 0 6em
    padding-top: 8.5em

    @media (max-width: $smscreen)
      margin-top: -2.5em
      padding-top: 2.5em

    .images-list
      position: relative
      min-height: 13em

  .card.selected
    display: block
    animation: none
    opacity: 1
    transition: inherit
    user-select: none
    min-height: 12em
    transition: background ease $speed-faster

    &:before, &:after
      @media (max-width: $smscreen)
        display: none

    &:hover
      cursor: grab

      .card-delete, .card-expand
        opacity: 1
        transition: none

        @media (max-width: $smscreen)
          animation: none

    &:first-child
      // background-color: white

      &:before
        border-right-color: $color-success
        border-top-color: $color-success

    .card-delete, .card-expand
      opacity: 0
      transition: opacity linear $speed-faster

  .card.image-featured-drop
    position: absolute
    top: 0
    left: 0
    height: 12em
    padding: 0
    pointer-events: none
    background: transparent
    box-shadow: none
    z-index: 2

    &:before
      content: ""
      position: absolute
      top: 0
      left: 0
      height: 100%
      width: 100%
      border-radius: $radius-border
      z-index: 1
      box-shadow: 0 0 0 0.125em $color-success inset

    p
      background: darken($color-success, 5%)
      font-size: 0.75em
      font-family: "muliregular"
      color: white
      position: absolute
      left: 0.15em
      bottom: 0.15em
      text-align: center
      padding: 0.5em 0.75em 0.67em
      margin: 0
      line-height: 1
      border-radius: 0 $radius-border 0 calc(#{$radius-border} - 1px)
      z-index: 1

  h2
    color: darken($color-success, 10%)
    text-align: center
    font-size: 2em
    margin-bottom: 1em

    span
      font-size: 0.64em
      margin-top: 0.75em
      display: block
      color: $color-darker

.images-list-search
  display: block
  margin-top: 1em

  &.loading
    .images-list .card.selected
      animation-duration: 0s
      animation-delay: 0s

  &.search-on
    .images-list
      display: none

    .search-input-icon
      opacity: 0

    .image-search-results
      z-index: 1

      p, .icon
        opacity: 1

    .search-input
      padding-right: 9em

    .images-search-list
      display: flex

    .images-list-more
      .btn
        display: none

      .images-search-btn
        display: block

  &.search-error
    .search-input-icon
      opacity: 0

    .image-search-results
      z-index: 1

      p, .icon
        opacity: 1

    .search-input
      padding-right: 9em

  .images-loading
    display: block
    width: 100%
    text-align: center
    margin: 1.5em 0 0

    img
      height: 8.5em
      width: auto

  .images-list-header
    position: relative
    z-index: 1
    opacity: 0
    animation: showFade $speed-normal $speed-fastest ease forwards

    h2
      margin-bottom: -0.5em

      @media (max-width: $smscreen)
        margin-bottom: 1em

    .sticky
      &:hover .search-input
        background: rgba(white, 0.75)

      .images-search-bar
        filter: drop-shadow(0 0.15em 0.33em rgba(black, 0.2))

      .search-input
        background: rgba(white, 0.25)
        padding-top: 1em
        padding-bottom: 1em

        &:focus
          background: rgba(white, 0.925)

      .search-input-icon
        padding: 1.25em 1.5em

        @media (max-width: $smscreen)
          padding: 1.5em

      .image-search-results 
        p
          margin-top: 2.25em

          @media (max-width: $smscreen)
            margin-top: 2.67em

        .icon
          padding: 1.25em 1.5em

          @media (max-width: $smscreen)
            padding: 1.5em

  .images-search-bar
    position: relative
    padding: 4.5em 0 0
    filter: none
    transition: filter ease $speed-normal

    @media (max-width: $smscreen)
      padding-top: 1em

  .search-input
    margin: 0
    font-size: 1.136em
    padding-right: 4.5em

    @media (max-width: $mdscreen)
      font-size: 16px

  .search-input-icon
    position: absolute
    z-index: 1
    right: 0
    top: 4.5em
    opacity: 1
    height: calc(100% - 4.5em)
    padding: 1.5em
    color: white
    background: $color-primary
    transition: color ease $speed-faster, background ease $speed-faster

    @media (max-width: $smscreen)
      transition: none
      top: 1em
      height: calc(100% - 1em)

    &:before
      font-size: 1.5em

    &:hover
      color: $color-primary
      background: white
      cursor: pointer

  .image-search-results
    position: absolute
    top: 4.5em
    right: 0
    height: calc(100% - 4.5em)
    vertical-align: middle
    z-index: -1

    @media (max-width: $smscreen)
      top: 1em
      height: calc(100% - 1em)

    p, .icon
      opacity: 0
      position: relative

    p
      display: inline-block
      font-size: 0.75em
      color: $color-darker
      margin: 2.5em 0.5em 0 0
      vertical-align: top
      transition: opacity ease $speed-faster

      @media (max-width: $smscreen)
        transition: none
        margin-top: 2.67em

    .icon
      color: $color-alert
      padding: 1.5em
      display: inline-block
      vertical-align: top
      margin-left: 0.5em
      background: white
      height: 100%
      max-height: 4.525em
      transition: color ease $speed-faster, background ease $speed-faster

      @media (max-width: $smscreen)
        transition: none
        max-height: 4.75em

      &:hover
        color: white
        background: $color-alert
        cursor: pointer

      &:before
        font-size: 1.5em

  .sticky
    text-align: center

    .images-count
      @media (max-width: $smscreen)
        position: relative
        top: -5.25em

      p
        animation: showImageCount $speed-normal forwards ease

  .images-count-wrap
    min-height: 3em
    position: relative

    @media (max-width: $smscreen)
      min-height: 0
      max-height: 0

    .sticky
      max-width: 16em
      left: 0
      right: 0
      margin: auto

  .images-count
    display: inline-block
    margin: 0 auto 0.75em
    text-align: center

    p
      display: inline-block
      margin: 0
      padding: 0.67em 1em
      background: $color-success
      color: white
      border-radius: $radius-border
      transform: none
      opacity: 0
      cursor: pointer
      box-shadow: 0 0 0.33em rgba(black, 0.075), 0 0.33em 0 -0.15em rgba(black, 0.15)
      transition: background ease $speed-faster, box-shadow ease $speed-faster

      &:hover
        background: $color-secondary
        box-shadow: 0 0.15em 0.33em rgba(black, 0.25)

      .images-count-click
        cursor: pointer
        margin-left: 0.33em
        font-family: "muliregular"

.images-list
  margin-top: 3.5em

  &.images-search-list
    display: none

  &.images-match-list, &.images-search-list
    .card.selected
      cursor: default
      box-shadow: 0 0 0 0.25em $color-success inset
      background: $color-success

      .file-img-bg
        animation: dimFade $speed-faster forwards ease
        opacity: 1
        pointer-events: none

  h3
    color: $color-alert
    font-size: 1.75em
    text-align: center
    width: 100%

  .card
    padding: 0.25em
    position: relative
    flex: 0 1
    min-width: calc(33.333% - 1em)
    animation-delay: 0s
    animation-duration: $speed-normal
    height: 12em
    border-radius: $radius-border

    @media (max-width: $mdscreen)
      min-width: calc(50% - 1em)

    @media (max-width: $xsscreen)
      min-width: calc(100% - 1em)

    &:hover
      .card-expand
        animation: showCardActiveLeft $speed-faster forwards ease

    &.selected

      &:hover
        animation-delay: 0s

        .card-delete
          animation: showCardActiveRight $speed-faster forwards ease   

      .card-delete-wrap
        animation: showFade $speed-faster $speed-faster forwards ease
        pointer-events: initial

.images-list-more
  text-align: center

  &.done
    .btn
      display: none

  .images-search-btn
    display: none

  .btn
    margin: 3em 0 0

    @media (max-width: $mdscreen)
      margin: 2em 0

    @media (max-width: $smscreen)
      margin: 1.5em 0

.ui.modals .image-featured-modal
  margin: 0
  padding: 0
  overflow: auto
  height: auto
  width: auto
  left: 50%
  right: auto
  transform: translateX(-50%)
  animation: none
  opacity: 1

  &:hover
    cursor: pointer

    .card-close
      animation: showCardActiveLeft $speed-faster forwards ease

  .image-featured
    display: block
    height: auto
    width: auto

  .content
    padding: 0

  img
    display: block
    width: auto
    height: auto
    max-height: 95vh
    max-width: 95vw

  .card-close, .card-delete
    font-size: 1.5em

  .image-attribute
    position: absolute
    bottom: 0
    left: 0
    width: 100%

    p
      margin: 0
      padding: 1.25em 1.5em 1.33em
      font-size: 0.75em
      background: rgba(black, 0.75)
      line-height: 1
      color: white

      a, .link
        color: $color-primary-light

        &:hover
          color: $color-accent