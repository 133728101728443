#root #settings
  .item-settings
    padding: 2em
    overflow: auto
    position: relative
    height: 100%

    h5
      font-size: 1.15em
      margin: 0.25em 0 2em
      color: $color-primary-light

      .icon
        margin-right: 0.5em
        font-size: 1.15em
        line-height: 0.75
        position: relative
        top: 0.0925em

    .item-setting
      margin: 1.5em 0 0
      display: flex
      flex-flow: row wrap
      align-items: center
      padding: 0 0 1.5em
      border-bottom: 1px dashed rgba(white, 0.25)

      &:last-child
        border: none

      &.sorting
        .order-list-item
          transition: inherit

      label
        margin: 0.5em 0
        display: flex
        align-items: center
        flex: 1
        color: white

        .icon
          margin: 0 0 0 0.67em
          position: relative
          font-size: 0.925em

      .toggle
        label:before
          border: 1px solid $color-light

        input:checked ~ label:before
          border-color: lighten($color-success, 15%)

      .ui.dropdown
        font-size: 0.925em
        margin: 0.5em 0
        background: darken($color-darkest, 2%)
        color: white

        > .menu
          background: $color-lightest
          color: $color-darkest
          padding: 0.5em 0 0
          border-radius: 0
          box-shadow: 5px 0 0 $color-accent inset

          &:before
            right: 1.5em
            border-bottom-color: $color-lightest

        .icon
          color: white

  .order-list
    margin: 0.5em 0
    padding: 0
    list-style: none
    flex: 100%

  .setting-css
    position: relative

    .css-edit
      flex: 100%
      margin: 1em 0
      background: $color-darkest
      color: $color-light
      font-family: monospace
      font-size: 0.85em
      min-height: calc(100vh - 40em)
      max-height: calc(100vh - 4em)
      line-height: 1.25
      tab-size: 2

      &::selection
        background: $color-lighter
        color: $color-darker

      &:focus
        background: black

    .sticky-holder
      position: relative
      width: 100%

      .sticky .btn
        border-radius: 0

    .btn
      width: 100%
      font-size: 0.925em
      height: 3.33em
      padding: 0
      display: flex
      justify-content: center
      align-items: center
      position: relative

      &:disabled
        background: $color-lighter

      &.saved
        color: darken($color-success, 8%)
        text-shadow: none

      .inline-loading
        position: absolute
        background-size: 5em
        background-position: center center

.order-list-item
  box-shadow: 0 0.075em 0.075em rgba(black, 0.15)
  list-style: none
  padding: 0.75em 1em
  margin: 0.25em 0
  line-height: 1.25
  background: $color-primary-dark
  color: white
  font-size: 0.925em
  border-radius: $radius-border
  text-transform: capitalize
  cursor: grab
  transition: background ease $speed-faster, box-shadow ease $speed-faster
  position: relative

  &:hover
    background: darken($color-primary-dark, 10%)
    box-shadow: 0 0.15em 0.33em rgba(black, 0.2)

  .item-reorder
    display: inline-block
    position: relative
    content: ""
    width: 0.75em
    height: 10px
    border-top: 4px double white
    border-bottom: 4px double white
    margin-right: 1em

.order-drag.order-list-item
  z-index: 5
  font-size: 0.925em

  @media (max-width: $smscreen)
    font-size: 1.15em