.item-elements
  .component-section-wrap.done
    .elements-item .elements-content .element.active
      box-shadow: 0 0.25em 0 -0.125em rgba(black, 0.075)

    .card-edit-wrap, .edit-element, .edit-element-btn, .edit-element-css
      display: none

    #header-element.active
      .edit-element.active
        display: none

    #elements #button-element.active
      .element-button-edit-wrap, .buttons-custom, .buttons-restore, .element-button-drag, .element-button-delete
        display: none

    #elements .element.disabled,
    #elements .elements-toggle
      display: none

  .elements-wrap
    font-size: 16px

    &#elements .elements-toggle
      background: $color-lightest
      padding: 1.25em 3em
      border-radius: $radius-border

      @media (max-width: $smscreen)
        padding: 1.25em 1em

      h3
        font-size: 1.33em
        font-family: "muliregular"
        font-style: normal
        font-weight: normal
        color: $color-body-text
        margin-top: 0.5em
        border-bottom: 1px dashed rgba(black, 0.25)
        padding-bottom: 0.5em

        @media (max-width: $smscreen)
          font-size: 1.15em

        @media (max-width: $xsscreen)
          font-size: 0.85em

        .btn
          float: right
          font-size: 0.67em
          margin-left: 1.25em

          @media (max-width: $smscreen)
            font-size: 0.575em
            margin-left: 1em
            margin-top: 0.225em

          @media (max-width: $xsscreen)
            font-size: 0.67em

          &.btn-alert
            color: $color-alert

            &:hover
              color: darken($color-alert, 10%)

          &.btn-success
            color: $color-success

            &:hover
              color: darken($color-success, 10%)

    .elements-toggle-list
      display: flex
      flex-flow: row wrap
      align-items: center
      margin: 1.5em 1em 0.5em

      @media (max-width: $smscreen)
        margin: 1.5em 0 0.5em

      > div
        white-space: nowrap
        margin: 0.75em 0
        width: 33.333%

        @media (max-width: $smscreen)
          width: 50%
          font-size: 0.925em

        @media (max-width: $xsscreen)
          font-size: 0.85em

      label
        margin: 0.25em 0 0 4.5em

  .elements-item
    &.elements-text
      .element
        &.element-lists .text-group
          display: inline-block
          margin: 0 2em

    &.elements-form
      .elements-content .element .element-content
        padding: 1em 2em

        @media (max-width: $mdscreen)
          padding: 1em 1.5em
        
        @media (max-width: $smscreen)
          padding: 1em

    .elements-content .element
      position: relative
      background: $color-lightest
      margin: 2.25em 0
      border-radius: $radius-border
      box-shadow: 0 0.25em 0 -0.125em rgba(black, 0.075)
      transition: box-shadow ease $speed-faster, opacity ease $speed-faster, background ease $speed-faster

      &:hover, &.active
        box-shadow: 0 0.415em 0.67em rgba(black, 0.2)

        .card-edit-wrap .card-edit
          animation: showCardActiveRight $speed-faster forwards ease

      &.active
        .element-content
          cursor: inherit

          .element-toggle
            z-index: -1

        .edit-element
          animation: showSlideDown $speed-fast ease

        .edit-element-btn
          animation: showSlideUp $speed-fast ease

      &.css
        .edit-element-css
          animation: showSlideDown $speed-faster ease

        .edit-element-btn
          background: lighten($color-darkest, 5%)
          color: white
          border-radius: 0
          transition-duration: 0s

          &:hover
            background: lighten($color-darkest, 2.5%)

      &.element-paragraph .element-content
        min-height: 8.5em

      &.element-toggles .element-content
        display: flex
        flex-flow: row wrap

      .element-content
        padding: 1em 3em
        transition: background ease $speed-faster
        border-radius: $radius-border
        cursor: pointer

        @media (max-width: $mdscreen)
          padding: 1em 2em

        @media (max-width: $smscreen)
          padding: 1em 1.5em

        .element-toggle
          position: absolute
          top: 0
          left: 0
          height: 100%
          width: 100%
          z-index: 1

          display: none

          &:hover
            cursor: pointer

        .element-button-edit-wrap
          display: none

        .element-button-edit
          display: none
          padding: 0.5em 0.67em
          margin: 0 1em 1.5em
          position: relative
          animation: showAboutText $speed-fast ease
          max-width: calc(100vw - 4.5em)
          flex-flow: row wrap
          align-items: center
          justify-content: center

          @media (max-width: $mdscreen)
            width: 100%

          &:before
            content: ""
            position: absolute
            bottom: 100%
            left: 0
            right: 0
            margin: auto
            border-top-color: transparent
            border-left-color: transparent
            border-right-color: transparent
            border-style: solid
            border-width: 0.67em
            height: 0
            width: 0

      .card-edit-wrap
        position: absolute
        top: 0
        right: 0
        height: 4em
        width: 4em
        overflow: hidden
        border-radius: 0 $radius-border 0 0

        .card-edit
          right: 0
          left: auto
          animation: showCardInactiveRight $speed-faster forwards ease

          .icon
            left: auto
            right: 0.5em

      *
        font-weight: normal
        font-size: 1em

      h1, h2, h3, h4, h5, p, blockquote, ul, ol, li, code, button, input, textarea
        all: initial
        font-family: system-ui
        box-sizing: border-box

      @import '../reset'

      p, blockquote, code
        // max-width: 48rem

      code
        margin: 1.5em 0
        display: block

      .checkbox, .ui.menu, .input, .textarea
        display: inline-block
        position: relative

      .btn-group, .input-group, .textarea-group, .dropdown-group
        display: flex
        flex-flow: row wrap
        margin: 1em 0

        > div, > button, > input, > textarea
          margin: 0.5em 1rem

        > input, > textarea, > .menu, > .checkbox
          flex: 1

        > button
          flex: calc(25% - 2rem)
          max-width: calc(25% - 2rem)

          @media (max-width: $mdscreen)
            flex: calc(50% - 2rem)
            max-width: calc(50% - 2rem)

          @media (max-width: $smscreen)
            flex: calc(100% - 2rem)
            max-width: calc(100% - 2rem)

        > input, > textarea
          max-width: calc(33.333% - 2rem)

          @media (max-width: $mdscreen)
            width: 100%
            flex: 100%
            max-width: 100%

        > .menu
          @media (max-width: $mdscreen)
            flex: calc(50% - 2rem)
            max-width: calc(50% - 2rem)

          @media (max-width: $smscreen)
            max-width: 100%
            flex: 100%
            margin: 1em 0

      .btn-group
        margin: 1em 0
        position: relative
        z-index: 0

        @media (max-width: $smscreen)
          margin: 1.5em 0

      .dropdown-group
        margin-bottom: 1em
        margin-top: 1em

        button.btn
          width: 100%
          text-align: left
          position: relative

          &.trigger
            display: flex
            align-items: center

          &:hover
            color: inherit

        .dropdown > .menu
          margin-top: 0.285em
          padding: 0.5em 0
          box-shadow: none

          &:before
            filter: none

          .item .link
            text-align: left
            margin: 0
            padding: 0.67em 1.5em

      .toggle-group
        flex: 1
        display: flex
        flex-flow: column wrap
        align-items: center
        margin: 1em 0

        > div
          margin: 1em
          white-space: nowrap

        label
          display: inline-block
          font-size: 0.925em
          color: $color-darkest

#elements
  .edit-element, .element-button-edit
    .ui.menu .link
      font-family: "muliregular"
      color: white
      transition: color ease $speed-faster
      font-size: 0.925em
      font-weight: normal
      font-style: normal
      text-decoration: none

      &:hover
        color: $color-primary-lightest
        background: transparent

    .ui.menu
      label
        padding: 0.425em 0 0.33em 1.425em

    .ui.checkbox
      margin-top: 0.75em

      label
        display: inline-block
        margin: 0
        padding: 0.25em 0 0.25em 1em
        color: $color-darker
        font-family: "muliregular"
        font-style: normal

      input:checked ~ label:after
        background: $color-primary
        border-color: $color-primary

    .dropdown .menu
      box-shadow: none

      &:before
        filter: none

    .dropdown .trigger
      padding: 1.5em 1em 0.75em 1em
      border: 0 none
      background: white
      display: flex

    .edit-item-input
      position: relative
      margin: 0.75em

      @media (max-width: $smscreen)
        margin: 0.5em
        flex: 0

      input
        font-family: "muliregular"
        font-style: normal
        font-size: 1em
        margin: 0
        padding: 0.75em 0.5em 0.125em 0.75em
        position: relative
        border: 0 none
        height: 100%
        min-height: 3.25em
        max-width: 12em
        min-width: calc(10.5em + 2px)
        overflow-x: auto
        transition: box-shadow ease $speed-fast

        @media (max-width: $smscreen)
          padding: 1em 0.5em 0.5em 0.75em
          max-width: 100%
          min-width: calc(12em + 2px)
          width: 100%

      > label
        padding: 0.425em 0 0.33em 1.075em

    .sketch-picker input
      overflow: visible

    .ui.menu, .edit-item-input, .edit-item-colors
      > label
        position: absolute
        top: 2px
        left: 2px
        font-size: 0.64em
        background: rgba(white, 0.75)
        line-height: 1
        border-radius: $radius-border $radius-border 0 0
        width: calc(100% - 4px)
        pointer-events: none
        color: $color-darker

      &.edit-font-size
        &:after
          content: 'px'
          position: absolute
          right: 1.575em
          top: 1.67em
          font-family: "muliregular"
          font-style: normal
          font-size: 0.925em
          pointer-events: none

      &.edit-font-size, &.edit-line-height
        input
          width: 100%
          min-width: 5.25em

          @media (max-width: $smscreen)
            min-width: 8.925em

    .edit-item-colors
      position: relative
      margin: 0.75em

      @media (max-width: $smscreen)
        margin: 0.5em
        min-width: 8.925em

      &:before
        content: ""
        position: absolute
        top: 0
        left: 0
        height: calc(100% - 1px)
        width: 100%
        border-radius: $radius-btn
        background: $color-lightest

      &.no-brand
        .item-palette-picker .ui.menu .menu
          width: 220px

      &.background, &.borderColor, &.boxShadowColor
        .sketch-picker  > div:last-child > div:first-child
          display: block

      &.boxShadowColor
        @media (max-width: $smscreen)
          min-width: 0

        .sketch-picker:before
          @media (max-width: $smscreen)
            left: 7.425em

        .ui.menu .menu
          @media (max-width: $smscreen)
            left: -7em

      > label
        border-radius: $radius-btn
        padding: 0.375em 0.15em
        background: rgba(white, 0.95)
        text-align: center
        box-shadow: 0 0 1px rgba(black, 0.15)
        font-size: 9.5px

      .color-picker-brand
        label
          margin: 0.25em 0 0.75em
          font-size: 0.75em
          padding: 0

    .edit-box-shadow
      input
        padding-right: 3em

      label
        background: transparent
        
      .edit-item-colors
        position: absolute
        right: 6px
        top: 6px
        height: calc(100% - 12px)
        width: 2.5em
        margin: 0
        border: 1px solid rgba(black, 0.75)
        border-radius: $radius-btn
        box-shadow: none

        .ui.menu
          margin: 0

          .menu
            top: 2.85em

        .item-palette-picker
          height: 100%
          width: 100%

          input
            min-width: 0

    label, button
      font-family: "muliregular"
      font-style: normal
      line-height: 1

    button, input, .dropdown .trigger
      box-shadow: 0 0.33em 0 -0.175em rgba(0, 0, 0, 0.075)
      border-radius: $radius-btn
      transition: box-shadow ease $speed-faster

      &:hover
        box-shadow: 0 0.25em 0.425em rgba(black, 0.15)

      &:active
        box-shadow: 0 0.15em 0 -0.075em rgba(black, 0.075)

  .element-button-edit
    .edit-item-input input
      font-size: 0.95em
      padding: 0.5em 0.75em
      background: transparent
      color: inherit
      box-shadow: 0 0.33em 0 -0.175em rgba(0, 0, 0, 0.075), 0 0 0 1px rgba(white, 0.25) inset, 0 0 0 1px rgba(black, 0.25)
      transition: box-shadow ease $speed-faster

      &:hover
        box-shadow: 0 0.25em 0.425em rgba(black, 0.15)

      &:active
        box-shadow: 0 0.15em 0 -0.075em rgba(black, 0.075)

  .edit-element-css
    code
      margin: 0
      width: calc(100% - 4em)
      color: white
      padding: 2em
      display: inline-block

      @media (max-width: $smscreen)
        padding: 1.5em 1em
        width: calc(100% - 2em)

  .element-content 
    .input-group input:focus, .textarea-group textarea:focus,
    .input-group input.focus, .textarea-group textarea.focus
      border-color: $color-primary

  .checkbox.toggle input:checked ~ label:before,
  .checkbox.slider input:checked ~ label:before
    background: rgba($color-success, 0.425)

  .checkbox.toggle input:checked ~ label:after,
  .checkbox.slider input:checked ~ label:after
    background: darken($color-success, 3%)

  #dropdown-element.element-dropdowns .element-content
    .ui.menu div.dropdown .menu
      z-index: 0
      filter: none
      float: right

      &.visible
        @media (max-width: $smscreen)
          position: relative

    .ui.menu:nth-child(2)
      z-index: 1

    .ui.menu:nth-child(3), .ui.menu:nth-child(4)
      div.dropdown .menu
        position: relative

      &:before
        filter: drop-shadow(0 -2px 1.5px rgba(black, 0.085))

  #dropdown-element .element-content
    .ui.item.active .menu
      opacity: 1
      pointer-events: initial

      &:before
        opacity: 1

    .dropdown .item.hover .link, .dropdown .item:hover .link
      background: $color-darkest

  .element-buttons
    &.active
      user-select: none

      .element-button-drag, .element-button-delete
        display: block

      .element-button-wrap
        &.normal
          .element-button-edit-wrap
            justify-content: flex-start
            display: flex

          .element-button-edit.normal
            display: flex

            &:before
              left: 12.5%
              right: auto

              @media (max-width: $smscreen)
                left: 0
                right: 0

        &.hover
          .element-button-edit-wrap
            justify-content: center
            display: flex

          .element-button-edit.hover
            display: flex
            left: -12.5%

            @media (max-width: $mdscreen)
              left: 0

            &:before
              @media (max-width: $mdscreen)
                left: auto
                right: 12.5%

              @media (max-width: $smscreen)
                left: 0
                right: 0

        &.active
          .element-button-edit-wrap
            justify-content: center
            display: flex

          .element-button-edit.active
            display: flex
            left: 12.5%

            @media (max-width: $mdscreen)
              left: 0

            &:before
              left: 0
              right: 0

              @media (max-width: $mdscreen)
                right: auto
                left: 12.5%

              @media (max-width: $smscreen)
                right: 0
                left: 0

        &.disabled
          .element-button-edit-wrap
            justify-content: flex-end
            display: flex

          .element-button-edit.disabled
            display: flex

            &:before
              left: 78.5%
              right: auto

              @media (max-width: $smscreen)
                left: 0
                right: 0

      .element-button-edit-wrap
        display: block
        position: relative

        .edit-item-input
          @media (max-width: $mdscreen)
            flex: 100%

          input
            @media (max-width: $mdscreen)
              max-width: 100%
              width: 100%

        .edit-item-colors
          @media (max-width: $smscreen)
            min-width: calc(50% - 1em)

            .item-palette-picker .ui.menu .menu
              left: -2.67em

            .sketch-picker:before
              left: 5em

          &.background, &.boxShadowColor
            @media (max-width: $smscreen)
              .item-palette-picker .ui.menu .menu
                left: auto
                right: -2.67em

              .sketch-picker:before
                left: auto
                right: 5em

    h3
      color: $color-text-min
      font-family: "mulilight"
      font-weight: normal
      font-style: normal
      text-align: center

    .buttons-list
      padding: 0 0.5em

    .element-content
      padding: 1.5em 2em

      @media (max-width: $mdscreen)
        padding: 1em 1.5em

      &.sorting, &.deleting
        .btn-group button
          transition-duration: 0s
          transition-delay: 0s

        .element-button-drag, .element-button-delete
          animation-duration: 0s

      &.one-btn
        .element-button-drag, .element-button-delete
          display: none

    .element-button
      button
        word-break: break-word

    .buttons-custom, .buttons-restore
      text-align: center
      opacity: 0
      animation: showSlideDown $speed-faster $speed-fastest ease forwards

      a, .link
        padding: 1em
        margin: 0 auto
        display: inline-block

        .icon
          position: relative
          top: 0.1em
          margin-right: 0.5em
          display: inline-block
          font-weight: 600

    .buttons-custom
      margin: 1.5em 0 0

      a, .link
        font-size: 0.925em

    .buttons-restore
      margin: -2.5em 0 0
      float: right

      @media (max-width: $smscreen)
        margin: 0
        float: none

      a, .link
        font-size: 0.75em
        color: $color-text-min

  .element
    &.disabled
      background-color: rgba(black, 0.067)
      box-shadow: none
      opacity: 0.5

      *, > *
        pointer-events: none
 
      .edit-element,
      .edit-element-btn, 
      .edit-element-css,
      .element-button .element-button-wrap .element-button-edit-wrap,
      .buttons-custom,
      .buttons-restore
        display: none

      .card-edit-wrap
        visibility: hidden

.element-button-drag, .element-button-delete
  display: none

.element-buttons.active, .element-button-drag
  .element-drag-icon:before
    content: ""
    position: absolute
    left: 0.825em
    top: 50%
    transform: translateY(-50%)
    height: 10px
    width: 0.75em
    border-top: 4px double $color-dark
    border-bottom: 4px double $color-dark

    @media (max-width: $mdscreen)
      left: calc(50% - 0.4em)

#elements .element-button, .button-drag
  .element-button-drag, .element-button-delete
    position: absolute
    top: 50%
    transform: translateY(-50%)
    padding: 0.75em
    margin: 0
    opacity: 0
    animation: showFade $speed-faster forwards
    cursor: pointer
    transition: color ease $speed-faster, background ease $speed-faster
    border-radius: 100%
    height: 2.375em
    width: 2.375em
    text-align: center

    .icon
      font-weight: 600
      font-size: 0.85em
      position: relative
      top: -0.075em

  .element-button-drag
    left: -1.925em
    color: $color-body-text
    cursor: grab

    &:hover
      background: white

      .element-drag-icon:before
        border-color: $color-accent

  .element-button-delete
    right: -1.925em
    color: $color-alert

    @media (max-width: $smscreen)
      right: -1.75em

    &:hover
      background: $color-alert
      color: white

.edit-element
  background: rgba(black, 0.025)
  padding: 1em 3em
  box-shadow: 0 0.15em 0.33em -0.15em rgba(black, 0.15) inset
  position: relative

  @media (max-width: $mdscreen)
    padding: 1em 2em 2em

  @media (max-width: $smscreen)
    padding: 1em 1.5em 2em

  .edit-item
    margin: 0 -1em
    border-bottom: 1px dashed rgba($color-primary-dark, 0.25)

    &:last-child
      border-bottom: 0 none

    &.edit-parent
      margin-bottom: 0
      padding-bottom: 1em

      .edit-item-sub
        display: flex
        flex-flow: row wrap

    &.edit-child
      > label
        padding: 1.5em 0.75em
        cursor: pointer
        transition: color ease $speed-faster

        &:hover
          color: $color-secondary

      .edit-item-sub
        display: none
        flex-flow: row wrap

      &.active
        padding-bottom: 0.75em

        label
          padding-bottom: 1em

        label .icon
          transform: scaleY(-1)

        .edit-item-sub
          display: flex

    > label
      font-size: 1em
      padding: 1.25em 0.75em 1em
      margin: 0

      .icon
        float: left
        top: 0.075em
        position: relative
        transform: none
        transform-origin: center center
        transition: transform ease $speed-faster
        margin-right: 1em

    .ui.menu
      margin: 0.75em

      @media (max-width: $smscreen)
        margin: 0.5em
        flex-grow: 1
        width: 100%

      &.item-font-name .btn
        min-width: 12em

      .btn
        text-align: left
        text-transform: capitalize

        @media (max-width: $smscreen)
          width: 100%

      .menu
        padding: 0.5em 0 0
        margin-top: 0.33em

        &:before
          right: 1em

        .item a, .item .link
          padding: 0.5em 1.5em 0.5em 1.75em
          text-align: left
          position: relative

        .item.active a, .item.active .link
          &:after
            content: "\e934"
            font-family: "icomoon"
            position: absolute
            left: 0.75em
            top: 1em
            font-size: 0.75em
            font-weight: 600

#header-element
  &.active
    .edit-element.active
      display: inline-block
      animation: showAboutText $speed-faster ease
      margin: -0.5em 0 1.5em

  &.disabled
    .edit-element.active
      display: none

  .element-content
    padding-top: 1.5em
    padding-bottom: 1.5em

  .header-wrap
    h1, h2, h3, h4, h5
      border-radius: 0.25rem
      text-shadow: none
      transition: text-shadow ease $speed-faster
      display: inline-block
      margin: 1rem 0

      &:hover
        cursor: pointer
        text-shadow: 0 0.25rem 0.5rem rgba(black, 0.15)

    .edit-element
      background: $color-lighter
      padding: 1em 1.5em
      margin: 0
      box-shadow: none
      display: none
      border-radius: $radius-border
      position: relative

      @media (max-width: $smscreen)
        padding: 0.5em 1em 

      &:before
        content: ""
        position: absolute
        height: 0
        width: 0
        left: 1.5em
        border-width: 0.5em
        border-style: solid
        bottom: 100%
        border-color: transparent transparent $color-lighter
        
      .edit-item
        display: flex
        flex-flow: row wrap

        .btn
          color: inherit
          font-family: "muliregular"
          font-size: inherit
          font-style: normal

          &:hover
            color: $color-secondary

#blockquote-element
  .element-content
    @media (max-width: $smscreen)
      padding: 0.5em 1em

.edit-item-colors
  > label
    position: relative

  .item-palette-picker
    position: relative
    display: block
    height: 3.25em
    width: 5.25em
    border-radius: $radius-btn
    box-shadow: 0 0.33em 0 -0.175em rgba(black, 0.075), 0 0 0 1px rgba(black, 0.075), 0 0 0 1px rgba(white, 0.15) inset
    transition: all ease $speed-faster

    @media (max-width: $smscreen)
      width: 100%

    &:hover
      box-shadow: 0 0.25em 0.425em rgba(black, 0.15), 0 0.25em 0.5em rgba(black, 0.075)

    &:active
      box-shadow: 0 1px 0 1px rgba(black, 0.075)

    &.transparent
      &:before, &:after
        content: ""
        position: absolute
        pointer-events: none

      &:before
        top: 50%
        width: calc(100% + 8px)
        height: 2px
        background: $color-alert
        transform: rotate(-30deg)
        border-radius: $radius-border

        @media (max-width: $smscreen)
          width: calc(65% + 8px)
          left: 15%

      &:after
        top: 0
        left: 0
        height: 100%
        width: 100%
        box-shadow: 0 0 0 1px white inset
        border-radius: $radius-btn

    .ui.menu, .item-color-trigger
      margin: 0
      top: 0
      left: 0
      height: 100%
      width: 100%
      position: absolute
      cursor: pointer

      .dropdown
        top: 0
        left: 0
        height: 100%
        width: 100%

      .menu
        top: 3.67em
        left: 50%
        transform: translateX(-50%)
        width: calc(220px + 5.25em)
        position: absolute
        margin: 0
        padding: 0

        @media (max-width: $smscreen)
          transform: none
          left: -1.5em

        &.visible
          .color-picker
            opacity: 0
            animation: showSlideDownSm $speed-fast ease forwards

          .sketch-picker
            display: block

          .color-picker-brand
            opacity: 1
            pointer-events: initial

        &:before
          display: none

        .sketch-picker
          display: none

          &:before
            pointer-events: none

            @media (max-width: $smscreen)
              right: auto
              left: 4.75em

      .color-picker-brand
        right: 0
        left: auto
        transition: none

    .sketch-picker:before
      border-width: 0.75em
        
.edit-item-decoration
  margin: 0.75em 1em

.edit-box-shadow
  position: relative

.edit-element-css
  background: $color-darkest
  color: white
  padding: 1em 3em
  border-radius: 0 0 $radius-border $radius-border

  @media (max-width: $mdscreen)
    padding: 1em 1.5em

  @media (max-width: $smscreen)
    padding: 1em 0.5em

.element-css
  columns: 2
  user-select: all

  @media (max-width: $smscreen)
    columns: 1

  code
    font-size: 1.15em

    .css-name
      color: lighten($color-secondary, 28%)
      margin-right: 0.75em

    div
      margin: 0.15em 0 0.15em 1.5em
      color: lighten($color-tertiary, 20%)

      @media (max-width: $smscreen)
        margin-left: 0.75em

      .css-key
        color: lighten($color-primary, 20%)

      .css-punct
        color: white

.edit-element-btn
  padding: 0.925em 0 1.2em
  text-align: center
  border-radius: 0 0 $radius-border $radius-border
  cursor: pointer
  background: $color-lighter
  transition: background ease $speed-faster
  box-shadow: 0 0.15em 0.25em -0.15em rgba(black, 0.15) inset

  &:hover
    background: $color-lightest

  span
    font-family: "muliregular"
    font-style: normal
