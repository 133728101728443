.palette-list
  margin: -0.5em -1em 1.5em

  &.active
    &.custom .card, &.custom .card:hover, .card.selected
      filter: none

    &.custom.custom-done .card, .card
      filter: opacity(0.25) grayscale(0.925)

      &:hover, &.selected
        filter: opacity(1)

    .card.selected:hover .card-edit
      animation: showCardActiveLeft $speed-faster forwards ease

    .card.selected .card-edit
      @media (max-width: $smscreen)
        animation: none

  &.no-adjectives
    .card.unmatched
      display: block

  &.show-all
    .card, .card-unmatched
      display: block

  &.custom
    &.custom-done
      .palette .color .color-drag,
      .palette .color:hover .color-drag
        z-index: -1

    .instructions
      display: inline-block
      width: 100%
      text-align: center
      margin: 1.5em 0 0.5em

      @media (max-width: $smscreen)
        display: none

    .card-palette-custom
      width: calc(100% - 2em)
      padding: 0
      margin-bottom: 0
      cursor: default
      overflow: visible
      filter: none
      box-shadow: 0 0.25em 0 -0.125em rgba(black, 0.075)
      transition: box-shadow ease $speed-faster, background ease $speed-faster, filter ease $speed-faster, margin ease $speed-faster

      @media (max-width: $smscreen)
        transition: none

      &:hover .card-delete
        animation: showCardActiveRight $speed-faster forwards ease

      &.selected
        &:before, &:after
          animation: none
          transform: none

          @media (max-width: $smscreen)
            display: none

      &.editing
        margin-bottom: 16em

        @media (max-width: $smscreen)
          margin-bottom: 24em

      .card-delete-wrap
        animation: showFade $speed-faster $speed-faster forwards ease
        pointer-events: initial

      .card-delete
        display: block

      .card-clear-wrap
        pointer-events: none

      .sticky-holder
        display: block

      .palette-custom-icon
        display: none

      .palette
        display: flex
        padding: 0.33em
        background-color: white
        border-radius: $radius-btn

        .color:hover, .color.editing
          background: $color-lightest

        .color.editing p
          color: $color-primary

          .color-num
            border-color: $color-primary

      .sticky
        z-index: 3

        .palette
          box-shadow: 0 -0.15em 0.33em rgba(black, 0.15)

        .color-picker, .color-picker-btn > *
          visibility: hidden

      .color.selected
        .card-clear-wrap
          pointer-events: initial

    .palette
      user-select: none

      .color:hover .color-drag
        opacity: 1
        z-index: 1

  .card
    margin: 1em
    padding: 0.33em
    width: calc(25% - 2em)

    @media (max-width: $mdscreen)
      width: calc(33.333% - 2em)

    @media (max-width: $smscreen)
      width: calc(50% - 2em)

    &.unmatched
      display: none

      &.selected
        display: block

    &.selected
      .card-edit
        pointer-events: initial

    .hex
      opacity: 0

    .card-edit
      pointer-events: none

  .color-toggle
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%
    z-index: 1

  .instructions
    display: none

.palette
  display: flex

  .color
    flex: 1
    height: 8.5em
    position: relative
    overflow: visible

    @media (max-width: $xsscreen)
      height: 5em

    .color-drag
      content: ""
      position: absolute
      top: 0
      left: 0
      height: 100%
      width: 100%
      border-radius: $radius-btn
      text-align: center
      opacity: 0
      z-index: -1
      transition: opacity ease $speed-faster

      @media (max-width: $smscreen)
        transition: none

      .icon
        color: white
        font-size: 2.5em
        position: relative
        top: calc(50% - 0.425em)
        text-shadow: 0 1px 0 rgba(black, 0.25)

.item-palette
  .component-section-wrap.done
    &.no-palette
      .palette-list
        margin-bottom: -0.5em

    .palette-list
      margin: 0 -1em

      .card-edit
        display: none

    .palette-list.no-adjectives
      .card.unmatched
        display: none

        &.selected
          display: block

    .palette-list.show-all
      .card, .card.unmatched
        display: none

        &.selected
          display: block

    .instructions
      display: none

    .card-palette-custom .palette-custom, .palette-list
      .palette .color
        height: 8.5em
        box-shadow: none

  .palette .color
    height: 6.5em

.card-palette-custom
  .palette-custom
    width: 100%
    height: 100%
    position: relative

    &.has-recommended .palette
      .color-secondary, .color-accent
        &.editing
          .color-picker .color-picker-alt
            pointer-events: initial

        .color-picker
          left: calc(50% - 2.575em)

          .sketch-picker
            border-radius: $radius-border 0 0 $radius-border !important

            &:before
              left: 5em

          .color-picker-alt
            opacity: 1

    .sticky-holder
      display: none

    .palette-custom-icon
      padding: 0.5em 0
      height: 6em
      width: 100%
      font-size: 1em
      cursor: pointer
      text-align: center

      @media (max-width: $xsscreen)
        height: 5em
        padding: 0.25em 0 0

      .icon
        font-size: 2.75em
        display: block
        margin: 0 0 0.25em

    .color-secondary
      // box-shadow: 1px 0 0 white, -1px 0 0 white, 1px 0 0 white inset, -1px 0 0 white inset
      // z-index: 1

    .palette
      display: none

      .color
        background: $color-lighter
        height: 10.5em
        position: relative
        transition: background-color ease $speed-faster

        @media (max-width: $smscreen)
          transition: none

        &.selected
          &:hover .card-clear
            animation: showCardActiveLeft $speed-faster forwards ease

            @media (max-width: $smscreen)
              animation: none

          .card-clear-wrap
            position: absolute
            top: 0
            left: 0
            height: 3.67em
            width: 3.67em
            overflow: hidden
            animation: showFade $speed-faster $speed-faster forwards ease

            @media (max-width: $smscreen)
              animation: none
              opacity: 1

          .card-clear
            animation-delay: 0s

            @media (max-width: $smscreen)
              animation: none

          p
            display: none

          &.editing .color-picker-btn .icon:before
            content: "\e934"

          .color-picker-btn
            border: none

          .color-picker-btn:hover .icon
            opacity: 1

        &.editing
          .color-picker
            z-index: 1
            opacity: 1
            pointer-events: initial

        &.color-primary
          .color-picker
            @media (max-width: $smscreen)
              left: calc(50% + 3.25em)

              .sketch-picker:before
                left: -6.67em

        &.color-accent
          .color-picker
            @media (max-width: $smscreen)
              left: calc(50% - 8.5em)

              .sketch-picker:before
                right: -12.25em

      .color-picker
        position: absolute
        top: calc(100% - 1.5em)
        left: 50%
        transform: translateX(-50%)
        z-index: -1
        pointer-events: none
        opacity: 0
        transition: opacity ease $speed-faster
        filter: drop-shadow(0 0.15em 0.25em rgba(black, 0.15))

        @media (max-width: $smscreen)
          top: calc(100% - 0.75em)
          transition: none

      .color-picker-btn
        position: absolute
        top: 0
        left: 0
        height: 100%
        width: 100%
        cursor: pointer
        border: 0.2em solid white
        transition: box-shadow ease $speed-faster

        @media (max-width: $smscreen)
          transition: none

        .icon
          opacity: 0
          transition: opacity ease $speed-faster
          font-size: 2.5em
          height: 1em
          position: absolute
          top: 0
          bottom: 0
          margin: auto
          width: 100%
          text-align: center
          text-shadow: 0 0.075em 0.125em rgba(black, 0.25)
          color: white
          pointer-events: none

          @media (max-width: $smscreen)
            transition: none

      p
        position: absolute
        top: 50%
        transform: translateY(-50%)
        text-align: center
        color: $color-text-min
        width: 100%
        margin: 0
        padding: 0.5em

        @media (max-width: $smscreen)
          line-height: 1

        .color-num
          display: block
          font-size: 1.75em
          height: 1.67em
          width: 1.67em
          margin: 0 auto 0.25em
          line-height: 1.5
          border-radius: 100%
          border: 2px solid $color-text-min

          @media (max-width: $smscreen)
            font-size: 1.5em

  .card-clear-wrap
    opacity: 0
    transition: none

  .card-delete
    display: none

.sketch-picker
  box-shadow: none !important

  &:before
    content: ""
    position: absolute
    bottom: 100%
    left: 0
    right: 0
    margin: auto
    width: 0
    border-style: solid
    border-width: 1em
    border-color: transparent transparent white

  > div:last-child
    > div:first-child
      display: none

  input
    text-align: center
    min-width: 100%

    &:focus
      outline: 2px $color-primary solid

  div[title="rgba(0,0,0,0)"]
    position: relative
    overflow: hidden

    &:before
      content: ""
      top: 50%
      left: -2px
      width: calc(100% + 4px)
      height: 2px
      background: $color-alert
      transform: rotate(-36deg)
      position: absolute

.color-picker-alt, .color-picker-logo, .color-picker-screen, .color-picker-brand
  position: absolute
  left: 100%
  height: 100%
  top: 0
  background: white
  padding: 0.5em 0
  border-radius: 0 $radius-border $radius-border 0
  opacity: 0
  transition: opacity ease $speed-faster
  pointer-events: none
  overflow: auto
  width: 5.25em
  text-align: center

  @media (max-width: $smscreen)
    left: 100%
    transition: none

  label
    font-size: 0.75em
    margin: 0.25em 0 0.75em
    text-align: center
    font-family: "muliregular"

  .color-alt, .color-logo, .color-screen, .color-brand
    display: block
    height: 1.64em
    width: 3.5em
    margin: 0.33em auto
    border-radius: $radius-border
    box-shadow: 0 0 0 1px rgba(black,0.15) inset
    cursor: pointer

    @media (max-width: $smscreen)
      height: 2.425em


// STYLEGUIDE + SHOWCASE PALETTE

.styleguide .item-palette, .showcase .item-palette
  .component-section-wrap.done
    .color-picker, .color-indicator
      display: none

    .custom-palette-picker
      margin-bottom: 0

    .palette-alt-wrap
      justify-content: center

      &.has-alt
        margin-top: 0.5em

      .card
        display: block
        padding: 0

        &.palette-alt-add
          display: none

        &.empty
          display: none

  #custom-palette
    margin-bottom: 0.5em

    .color-picker-btn
      border: none

  .custom-palette-picker
    margin-bottom: 3em
    max-height: 25em
    opacity: 1
    transition: max-height ease $speed-faster, opacity ease $speed-fast $speed-fastest, margin ease $speed-faster

    @media (max-width: $smscreen)
      max-height: 36em

    &.primary
      .color-indicator
        left: calc(16.825% - 1.25em)

      .color-picker.has-recommended .sketch-picker
        max-width: 100%

      .color-picker.has-recommended.has-logo .sketch-picker,
      .color-picker.has-recommended.has-screen .sketch-picker
        max-width: calc(100% - 20px - 6em)

    &.secondary
      .color-indicator
        left: calc(50% - 1.25em)

    &.accent
      .color-indicator
        left: calc(83.125% - 1.25em)

    &.picker-off
      max-height: 0
      overflow: hidden
      opacity: 0
      margin-bottom: 0
      transition: max-height ease $speed-faster, opacity ease $speed-faster, margin ease $speed-faster

      .color-indicator
        display: none

    .color-picker
      position: relative

      &.has-recommended, &.has-logo, &.has-screen
        .sketch-picker
          max-width: calc(100% - 20px - 6em)

          @media (max-width: $smscreen)
            max-width: calc(100% - 20px)

      &.has-recommended.has-logo, &.has-recommended.has-screen
        .sketch-picker
          max-width: calc(100% - 20px - 12em)

          @media (max-width: $smscreen)
            max-width: calc(100% - 20px)

        .color-picker-alt
          right: 6em

          @media (max-width: $smscreen)
            right: 0

    .sketch-picker
      box-shadow: 0 0.525em 0 -0.2em rgba(0, 0, 0, 0.075) !important
      transition: width ease $speed-normal

      @media (max-width: $smscreen)
        margin-bottom: 0.67em

      &:before
        display: none

      > div
        &:nth-child(1)
          padding-bottom: 12em !important
          transform: none

          @media (max-width: $smscreen)
            padding-bottom: 8.5em !important

        &:nth-child(2)
          > div div
            min-height: 1.25em

        &:nth-child(3)
          input
            min-width: 100%
            font-size: 14px !important
            text-align: center

        &:nth-child(4)
          > div
            min-width: calc(6.25% - 10px)
            min-height: 2.25em

            @media (max-width: $smscreen)
              min-height: 1.5em
              min-width: calc(9% - 10px)

    .color-picker-alt, .color-picker-logo, .color-picker-screen
      left: auto
      right: 0
      width: 5.15em
      border-radius: $radius-border
      box-shadow: 0 0.525em 0 -0.2em rgba(0, 0, 0, 0.075)
      pointer-events: initial
      animation: showContentFade $speed-normal forwards ease

      @media (max-width: $smscreen)
        position: relative
        display: inline-block
        width: 100%
        margin: 0.5em 0

      .color-alt, .color-logo, .color-screen
        height: 1.925em

        @media (max-width: $smscreen)
          display: inline-block
          width: calc(11.11% - 0.67em)
          margin: 0.33em 0.25em

.color-indicator
  position: absolute
  bottom: calc(100% - 0.15em)
  content: ""
  border-color: $color-lightest transparent transparent
  border-width: 1.25em
  border-style: solid
  transition: left ease $speed-normal, border-color ease $speed-faster
  opacity: 0
  filter: drop-shadow(0 0.185em 0.0225em rgba(black, 0.075))
  animation: showSlideDown $speed-fast $speed-fastest ease forwards

  @media (max-width: $smscreen)
    transition: left ease $speed-normal 

.palette-alt-wrap
  align-items: center
  position: relative
  z-index: 1
  margin-top: 0

  &.editing
    margin-bottom: 20em

    @media (max-width: $smscreen)
      margin-bottom: 18em

  &.deleting
    *
      pointer-events: none

    .color-picker
      display: none

    .palette-alt
      animation-duration: 0s
      animation-delay: 0s

      .alt-color
        transition-duration: 0s

  &.sorting
    transition: inherit

    .palette-alt
      transition: inherit

      *
        pointer-events: none

      .alt-color
        transition-duration: 0s

  .palette-alt
    animation: none
    opacity: 1
    height: 8.5em
    position: relative
    overflow: visible
    z-index: 1
    transform-origin: center center
    cursor: grab
    width: calc(33.333% - 1em)
    user-select: none

    @media (max-width: $lgscreen)
      &:nth-child(3n)
        .color-picker
          left: auto
          right: -1em
          transform: none

          &.has-recommended.has-logo, &.has-recommended.has-screen 
            .sketch-picker:before
              right: calc(-25% + 3.33em)

          .sketch-picker:before
            right: calc(-25% + 6.33em)
            
      &:nth-child(3n + 1)
        .color-picker
          right: auto
          left: -1em
          transform: none

          &.has-recommended.has-logo, &.has-recommended.has-screen 
            .sketch-picker:before
              right: calc(25% - 2.5em)

          .sketch-picker:before
            right: calc(25% - 6.33em)

    @media (max-width: $smscreen)
      width: calc(50% - 1em)

      &:nth-child(2n)
        .color-picker
          left: auto
          right: -1em
          transform: none

          &.has-recommended.has-logo, &.has-recommended.has-screen
            .sketch-picker:before
              right: calc(-25% - 6.33em)

          .sketch-picker:before
            right: calc(-25% - 2.5em)
            
      &:nth-child(2n + 1)
        .color-picker
          right: auto
          left: -1em
          transform: none

          &.has-recommended.has-logo, &.has-recommended.has-screen 
            .sketch-picker:before
              right: calc(25% + 6.5em)

          .sketch-picker:before
            right: calc(25% + 2.85em)

    &:hover
      .card-delete-wrap
        .card-delete
          animation: showCardActiveRight $speed-faster forwards ease
          pointer-events: initial

          @media (max-width: $mdscreen)
            animation: none
            opacity: 1

    &.editing
      z-index: 2
      cursor: pointer

      .color-picker-btn .icon
        top: -1.25em

        @media (max-width: $smscreen)
          top: -1.67em

        &:before
          content: "\e934"

      .color-name
        display: block
        animation: showFade $speed-faster forwards ease

    &.deleted
      animation: deleteCard $speed-faster forwards ease

    &.done
      box-shadow: 0 0.25em 0 -0.125em rgba(black, 0.075)

    &.empty
      &:hover
        .alt-color
          background: $color-lightest

        .color-picker-btn .icon
          color: $color-secondary

      .color-picker-btn .icon
        color: $color-primary-dark
        opacity: 1

    &.empty.editing .color-picker-btn .icon
      &:before
        content: "\e60d"

    &.palette-alt-add
      text-align: center
      cursor: pointer

      &:hover
        label
          color: $color-secondary

        .icon
          color: $color-secondary

      label
        transition: color ease $speed-faster
        margin-top: 0.75em
        pointer-events: none

      .icon
        font-size: 2.5em
        color: $color-primary-dark
        margin: 0.33em auto 0
        display: block
        transition: color ease $speed-faster

    .card-delete-wrap
      animation: showFade $speed-faster $speed-faster forwards ease
      pointer-events: initial

    .color-picker-btn
      position: absolute
      top: 0
      left: 0
      height: 100%
      width: 100%

      &:hover
        .icon
          opacity: 1
          transition: opacity ease $speed-faster, color ease $speed-faster

      .icon
        opacity: 0
        font-size: 2em
        height: 1em
        position: absolute
        top: 0
        bottom: 0
        margin: auto
        width: 100%
        text-align: center
        text-shadow: 0 0.075em 0.125em rgba(black, 0.25)
        color: white
        pointer-events: none

        @media (max-width: $smscreen)
          transition: none

  .color-picker-wrap
    display: flex
    width: 100%
    justify-content: center

  .color-picker
    position: absolute
    top: calc(100% + 0.367em)
    width: calc(220px + 5.15em)
    filter: drop-shadow(0 0.15em 0.25em rgba(black, 0.15))
    opacity: 0
    animation: showFade $speed-normal ease forwards
    display: flex

    &.has-recommended, &.has-logo, &.has-screen
      .sketch-picker
        border-radius: $radius-border 0 0 $radius-border !important

      .color-picker-alt, .color-picker-logo, .color-picker-screen
        opacity: 1
        pointer-events: initial
        position: relative
        left: auto
        height: auto
        max-height: 21.25em

        @media (max-width: $lgscreen)
          max-height: 24.25em

        @media (max-width: $smscreen)
          max-height: 28.25em

        .color-alt, .color-logo, .color-screen
          @media (max-width: $lgscreen)
            height: 2em

          @media (max-width: $mdscreen)
            height: 2.175em

          @media (max-width: $smscreen)
            height: 2.42em

    &.has-recommended.has-logo, &.has-recommended.has-screen
      width: calc(220px + 10.3em)

      .color-picker-alt
        border-radius: 0

  .color-name
    position: absolute
    bottom: 0.5em
    left: 0.5em
    width: calc(100% - 1em)
    opacity: 0
    display: none

    input
      padding: 0.575em 1.25em

      @media (max-width: $smscreen)
        font-size: 16px
        padding-left: 0.75em
        padding-right: 0.75em

.alt-color
  position: absolute
  top: 0
  left: 0
  height: 100%
  width: 100%
  box-shadow: 0 0 0 0.25em white inset
  background-color: darken($color-lightest, 4%)
  transition: background ease $speed-faster
  border-radius: $radius-btn