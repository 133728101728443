// DEMO

.demo-wrap
  opacity: 0
  animation: showFade $speed-fast forwards

.demo-list-outer
  opacity: 0
  animation: showFade $speed-fast forwards

.demo-list-outer, .home-header-thumbs, .about-types
  .theme-thumb
    &.projects
      .theme-header
        margin-bottom: 1.25em

    &.projects, &.styleguides
      .theme-images .theme-image
        height: 2.75em

    &.styleguides, &.showcases
      .theme-layout
        padding-top: 1.75em

      .theme-header
        display: none

    &.styleguides
      .theme-palette
        margin-top: 0.25em

        .theme-color
          font-size: 1.5em

      .theme-buttons .btn
        font-size: 0.67em

    .theme-layout
      background: $color-lightest

      .theme-header
        > h4
          margin: 0.5em 0
          font-size: 1.25em
          font-family: "muliregular"

  .theme-layout
    background: white
    overflow: hidden
    margin: 0
    border-radius: $radius-btn
    box-shadow: 0 0.5em 1em -0.25em rgba(black, 0.15)
    width: 100%
    height: 21em
    max-width: 16em
    transition: box-shadow ease $speed-fast, margin ease $speed-faster

    @media (max-width: $mdscreen)
      font-size: 0.925em
      margin: 1.5em 0 -3em

    .theme-screens
      margin: 0 0 0.75em
      padding-top: 0

      .theme-screen
        height: 7em

    .theme-font
      color: $color-darker

.demo-types
  position: fixed
  top: 0.25em
  right: 0.25em
  z-index: 4
  opacity: 0
  animation: showFade ease $speed-fast $speed-slow forwards

  @media (max-width: $smscreen)
    bottom: 0.75em
    top: auto

  .ui.dropdown.item
    .btn.trigger
      padding: 0.67em 1.25em 0.75em
      color: white
      transition: box-shadow ease $speed-faster

      @media (max-width: $smscreen)
        padding-top: 1em
        padding-bottom: 1.15em

      &:after
        opacity: 0

      &:hover:after
        opacity: 1

      .demo-type-name
        color: white
        position: relative
        z-index: 1

      .icon
        font-size: 0.925em
        margin-right: -0.25em
        top: 0.075em

    .menu
      top: calc(100% + 0.75em)
      padding: 0
      border-radius: $radius-btn

      @media (max-width: $smscreen)
        bottom: calc(100% + 0.75em)
        top: auto

        &:before
          bottom: auto
          top: 100%
          border-color: $color-darkest transparent transparent
          border-bottom: none

      .menu-item
        text-align: left
        font-size: 1.25em
        padding: 0.75em 1.5em
        margin: 0

        @media (max-width: $smscreen)
          font-size: 1.5em

        &:first-child
          border-radius: $radius-btn $radius-btn 0 0

        &:last-child
          border-radius: 0 0 $radius-btn $radius-btn

        &.project:hover
          color: $color-primary

        &.styleguide:hover
          color: $color-secondary-lighter

        &.showcase:hover
          color: $color-accent-light

        &.persona:hover
          color: $color-accent-light

        &.active
          cursor: default

          &:after
            opacity: 0

          .demo-type-name
            color: white

.demo-list
  position: relative
  top: 0
  left: 0
  width: 100%
  min-height: calc(100vh - #{$height-header})
  display: flex
  flex-flow: column nowrap
  transform: skewY(-1.5deg)

  @media (max-width: $mdscreen)
    flex-flow: column nowrap

  .demo-type
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    padding: 5em 0
    flex-grow: 1
    min-height: 8em

    &:hover
      .demo-type-name
        text-shadow: 0 0.15em 0.15em rgba(black, 0.33)

      .theme-thumb
        opacity: 0.5
        margin-top: 4em
        font-size: 1em

    &:first-child
      padding-top: 9em
      margin-top: -4em

    &:last-child
      padding-bottom: 8em
      margin-bottom: -2em

      @media (max-width: $mdscreen)
        padding-bottom: 7em
        margin-bottom: -2em

    .demo-type-name
      color: white
      font-size: 7em
      text-shadow: 0 0.075em 0.15em rgba(black, 0.25)
      transition: text-shadow ease $speed-fast, color ease $speed-fast

      @media (max-width: $mdscreen)
        font-size: 5em

  .demo-type-content
    display: flex
    flex-flow: row nowrap
    align-items: center
    justify-content: center
    position: relative
    z-index: 1
    transform: skewY(1.5deg)

    .theme-thumb
      display: inline-flex
      justify-content: center
      min-width: 21em
      font-size: 1.25em
      align-items: center
      position: absolute
      z-index: -1
      opacity: 0.2
      transition: margin ease $speed-faster, opacity ease $speed-faster, font-size ease $speed-faster

.demo-types, .demo-list
  .demo-type, .trigger, .menu-item.active
    position: relative
    overflow: hidden
    opacity: 0
    animation: showContentFadeMid ease $speed-slower forwards

    &.project
      animation-delay: $speed-fast

    &.styleguide
      animation-delay: $speed-fast * 1.5

    &.showcase
      animation-delay: $speed-fast * 2

    &.persona
      animation-delay: $speed-fast * 2.5

    &:after
      content: ""
      position: absolute
      top: 0
      left: 0
      height: 100%
      width: 100%
      transition: opacity ease $speed-normal
      z-index: 0
      opacity: 0

    &:hover:after
      opacity: 1

    &:hover
      &.project .demo-type-name
        color: white

      &.styleguide .demo-type-name
        color: white

      &.showcase .demo-type-name
        color: white

      &.persona .demo-type-name
        color: white

      .theme-layout
        box-shadow: 0 1em 2em -0.25em rgba(black, 0.25)

    &.project
      background: linear-gradient(192deg, $color-secondary 15%, $color-primary)

      &:after
        background: $color-secondary

    &.styleguide
      background: linear-gradient(192deg, $color-accent 15%, $color-secondary)

      &:after
        background: $color-accent

    &.showcase
      background: linear-gradient(192deg, $color-tertiary 15%, $color-accent)

      &:after
        background: $color-tertiary

    &.persona
      background: linear-gradient(192deg, $color-quaternary 15%, $color-primary)

      &:after
        background: $color-primary

.demo-auth
  text-align: center
  padding: 4em

  @media (max-width: $mdscreen)
    padding: 4em 6em

  @media (max-width: $smscreen)
    padding: 4em 2em

  h1, h3
    margin: 0 auto 0.5em

  .btn
    margin-top: 1.5em
    display: inline-block

.demo
  header, footer
    display: none

  .demo-wrap,
  .content-outer
    min-height: 100vh
    width: 100%

  .publish-bar .item-nav-btns
    .btn-signup
      animation: hideFade ease $speed-fast forwards
      pointer-events: none

  nav.item-edit-nav.publish
    z-index: 5

  .item-nav-btns
    .btn-signup
      margin-top: 0.275em
      position: absolute
      left: 50%
      transform: translateX(-50%)
      opacity: 0
      animation: showFade ease $speed-fast forwards

      @media (max-width: $smscreen)
        font-size: 1em
        margin-top: 1.225em

    .btn-publish .icon
      margin-left: -1.5em

.link-example
  position: fixed
  bottom: 1.5em
  font-size: 0.925em
  padding: 0.5em 0.75em
  border-radius: $radius-btn
  display: flex
  justify-content: center
  align-items: center
  left: 50%
  transform: translateX(-50%)
  opacity: 0
  animation: showFade ease $speed-fast $speed-normal forwards

  @media (max-width: $smscreen)
    bottom: 8em
    font-size: 1em

  .icon
    transform: rotate(180deg)
    margin-left: 0.5em
