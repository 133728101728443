.app-wrap.touch
  #styleguide
    .item-content .styleguide-nav:not(.palette-header)
      position: fixed
      top: 0
      width: 100%
      transition: top ease $speed-fastest

      &.hidden
        top: -$height-styleguide-nav

      ul
        background: rgba(white, 0.925)
        flex: 1

        li
          color: $color-darkest
          text-shadow: 0 1px 0 rgba(white, 0.185)

          &:hover
            box-shadow: none

#project, #styleguide, #showcase, #persona
  max-height: 0

  &.download
    filter: blur(4px)

    .item-loading
      display: none

  &.loaded
    max-height: 100%

    .item-header
      animation: showContentFadeMid $speed-normal $speed-normal forwards ease

    .item-body
      opacity: 1
      animation: showBodyFadeMid $speed-normal * 3 ease

    .item-publish-nav
      animation: showFade $speed-normal $speed-normal * 3 forwards ease

    .item-loading
      position: fixed
      top: 0
      left: 0
      width: 100%
      animation: hideFade $speed-faster forwards ease

  .item-details-files .files-list,
  .item-files .files-list
    font-size: 0.75em
    justify-content: center

    .card.selected
      cursor: pointer

      &:before, &:after
        display: none

      a, .link
        cursor: pointer

#project
  &.no-icons
    .publish-icon-wrap
      display: none

    .publish-section
      padding: 4em 0

      &:first-child
        padding-top: 2em
      
  .item-nav-trigger, .item-back-btn
    .icon
      color: white

      &:hover
        text-shadow: 0 1px 0 rgba(black, 0.125)

  .publish-section:nth-child(even)
    background: white
    box-shadow: 0 0 0.75em rgba(black, 0.125)

    .publish-icon-wrap.icon-wrap-small:after
      background: white

    .item-inspiration-font .item-publish-font
      background-color: $color-lightest

  .item-publish-members
    flex-flow: row wrap
    padding: 1em 0 1.5em
    position: relative

    &.publish-section
      box-shadow: 0 0.5em 1em rgba(black, 0.2) inset

    &.no-palette
      background: $color-text-min

    .item-image-wrap
      opacity: 0.067
      filter: contrast(1.33) grayscale(0.85) brightness(0.925)
      position: absolute
      top: 0
      left: 0
      height: 100%
      width: 100%

    .item-image
      position: absolute
      content: ""
      top: 0
      left: 0
      height: 100%
      width: 100%
      background-size: cover
      background-position: center bottom
      z-index: 0

    h3
      flex: 100%
      font-size: 1.5em
      padding: 0.5em 1em 1em
      color: white
      text-align: center
      text-shadow: 0 0.25em 0.5em rgba(black, 0.33)

    .item-publish-member-wrap
      position: relative
      z-index: 1
      display: flex
      flex-wrap: wrap
      align-items: center
      justify-content: center

    .item-publish-member
      text-align: center
      margin: 1em 3em
      flex-grow: 0

      &.owner
        order: -1

    .profile-img-wrap
      font-size: 0.33em
      margin-bottom: 0.75em
      cursor: default

      &:hover
        border-color: white

    .item-publish-member-name
      color: white
      display: block
      text-shadow: 0 0.15em 0.25em rgba(black, 0.25)
      font-size: 1.025em

  .item-publish-font h3
    font-family: inherit

#project, #projects, #styleguides, #styleguide, #showcase, #showcases, #persona, #personas
  &.download
    .item-header,
    .item-body
      opacity: 1
      animation-duration: 0s
      animation-delay: 0s
      transition: none

    .item-header
      position: absolute

    .parallax
      transform: none !important

    .item-body
      box-shadow: none
      background-image: none
      background-color: $color-lightest
      height: 100%

      *, *:before, *:after
        text-shadow: none !important
        filter: none !important
        box-sizing: border-box !important

    .item-nav-holder,
    .item-publish-nav,
    .item-inspiration-palette .btn,
    #elements .btn.btn-element-css,
    .item-elements-wrap .item-elements .element-css,
    .item-inspiration-font .item-publish-font h2 .font-btns
      display: none

    .item-elements-wrap .item-elements .element-content
      margin: 4em auto

    .item-palette-report
      border-radius: $radius-border

      .palette-report-wrap
        font-size: 1em
        opacity: 1

    .item-details-due .details-due-date
      background: transparent
      box-shadow: none

    .progressive, .progressive.done
      animation: none

    .item-loading, .card-download, .card-download:before, .file-detail:after
      display: none
      opacity: 0

    .item-palette-report a,
    .palette-report-wrap .color-alt-text
      border: 0 none

    .item-palette-header .item-header-color
      &.header-palette-primary
        height: 12em

      &.header-palette-secondary
        height: 9em

      &.header-palette-accent
        height: 6em

.item-publish
  &.dimmed
    #root
      // filter: none

    .item-body, .item-header, .item-nav-trigger, .item-back-btn
      // filter: blur(4px)

  .item-body, .item-header, .item-nav-trigger, .item-back-btn
    transition: filter ease $speed-slow

  &.projects, &.styleguides
    header, footer
      display: inline-block

    header, footer, #projects > .content-wrap, #styleguides > .content-wrap
      // filter: blur(4px)

  header, footer
    display: none

  .item-outer
    position: relative
    min-height: 100vh
    width: 100%
    top: 0
    left: 0
    z-index: 3
    overflow-x: hidden
    overflow-y: auto
    -webkit-overflow-scrolling: touch
    box-shadow: 0 0 3em rgba(black, 0.15)
    transition: left ease $speed-fastest

    @media print
      box-shadow: none

  .item-publish-nav
    position: fixed
    top: 0.425em
    left: 1.5em
    width: calc(100% - 3em)
    z-index: 4
    opacity: 0
    pointer-events: initial

    @media (max-width: $mdscreen)
      top: auto
      bottom: 0.5em
      font-size: 1.25em

    @media print
      display: none

    .item-back-btn
      position: absolute
      top: 0
      left: 0
      padding: 0.5em

      @media (max-width: $mdscreen)
        display: none

      &:hover .icon
        text-shadow: 0 1px 0 rgba(white, 0.5)

      .icon
        font-size: 2.5em
        text-shadow: 0 2px 0 rgba(black, 0.25)
        transition: color ease $speed-faster, text-shadow ease $speed-faster

    .item-nav-trigger
      font-size: 2.15em
      height: 2em
      width: 2em
      position: absolute
      top: 0
      right: 0
      line-height: 2.15
      text-align: center
      border-radius: 100%
      opacity: 0.75
      transition: opacity ease $speed-faster, background ease $speed-faster * 2, box-shadow ease $speed-faster
      cursor: pointer

      @media (max-width: $mdscreen)
        top: auto
        bottom: 0.5em
        background: $color-primary
        opacity: 0.925

      &:hover
        opacity: 1
        background: white
        box-shadow: 0 0.25em 0.5em rgba(black, 0.15)

        .icon
          color: $color-body-text

      .icon
        text-shadow: 0 2px 0 rgba(black, 0.5)
        transition: color ease $speed-faster, text-shadow ease $speed-faster

    .item-nav-menu div.dropdown .menu
      margin-top: 4em
      margin-right: 0.5em

      @media (max-width: $mdscreen)
        margin-top: 0
        bottom: calc(100% + 5.5em)

        &:before
          top: 100%
          bottom: auto
          transform: rotate(180deg)

      a, .link
        text-align: left

        &:last-child
          margin-bottom: 0.15em

        &.card-download
          @media (max-width: $smscreen)
            display: none

      .icon
        font-size: 1.15em
        margin-right: 0.85em
        position: relative
        top: 0.085em
        line-height: 0.75

  .item-loading
    position: fixed
    padding-top: 10%
    padding-left: 2em
    padding-right: 2em
    top: 0
    left: 0
    width: 100%
    filter: drop-shadow(0 0.215em 0.425em rgba(black, 0.085))
    animation: showFade $speed-normal forwards ease
    pointer-events: none

    &.error
      pointer-events: initial
      background: none

      h1
        color: $color-alert
        font-size: 2.75em
        opacity: 0
        animation: showFade $speed-normal forwards ease

    h1
      font-size: 3.33em
      color: $color-accent

    .icon
      display: block
      font-size: 5.75em
      margin: 0.15em auuto 0.25em
      color: $color-alert
      opacity: 0
      animation: showContentFadeBig $speed-normal $speed-faster ease forwards

    p
      font-size: 1.5em

.modals .share-modal.modal
  &.active
    .share-container
      animation: showFade $speed-normal $speed-faster forwards ease

  .header
    text-align: center
    font-size: 2.25em
    padding: 0.85em 1.5em 0.15em

    @media (max-width: $smscreen)
      font-size: 2em

    > span
      font-family: "muliextralight"
      font-weight: normal
      font-style: normal

  .content
    padding: 0 2em 1.5em

    @media (max-width: $smscreen)
      padding: 0 1em 1.5em

.share-container
  padding: 1.5em
  opacity: 0

  @media (max-width: $smscreen)
    padding: 1em

  .share-toggle
    margin: 0 0 1em
    padding: 1em 0 0.33em
    position: relative

    .icon
      font-size: 0.725em
      margin-left: 0.75em
      font-weight: 500

  .share-help
    display: inline-block

  label
    display: inline-block
    font-size: 1.15em
    margin: 0.25em 0

  .ui.checkbox, .ui.checkbox.toggle
    float: right
    width: 4.25em

  .share-elements, .share-embed
    button
      width: 4.5rem
      border-radius: 0 $radius-btn $radius-btn 0
      min-width: 0
      font-size: 0.85em
      text-align: center
      padding: 1em 0.5em

      @media (max-width: $smscreen)
        width: 3.33rem

  .share-elements
    display: flex
    position: relative
    border-top: 1px dashed rgba($color-primary-dark, 0.5)
    padding-top: 1.5em
    margin-top: 0.5em

    input
      font-size: 1.15em
      text-indent: -2.75em

  .share-input
    flex: 1

  .share-embed-wrap
    margin-top: 1.67em
    position: relative

    .share-embed
      display: flex

    label
      margin-bottom: 0.75em
      font-size: 1em

    textarea
      resize: none
      padding: 0.5em 1em
      font-size: 0.925em
      max-height: 5.25em
      flex: 1

      @media (max-width: $smscreen)
        max-height: 5em

    .tooltip
      right: -1.15em
      top: -0.925em
      z-index: 1

  .copied
    .tooltip
      animation: showFade $speed-normal forwards ease

  .tooltip
    position: absolute
    right: -1em
    top: -1.525em
    color: white
    border-radius: $radius-border
    font-size: 0.85em
    font-family: "muliregular"
    font-weight: normal
    font-style: normal
    background: $color-success
    padding: 1em
    filter: drop-shadow(0 0.125em 0.15em rgba(black, 0.15))
    opacity: 0
    animation: hideFade $speed-faster forwards ease
    pointer-events: none

    &:before
      content: ""
      position: absolute
      top: 100%
      height: 0
      width: 0
      left: 0
      right: 0
      margin: auto
      border-style: solid
      border-width: 0.5em
      border-color: $color-success transparent transparent

    .icon
      margin-right: 0.5em
      font-size: 1.25em
      position: relative
      top: 0.1em

  p
    font-size: 0.75em
    padding-top: 0.25em

  .btn-done
    margin: 3em auto 0
    font-size: 0.925em
    display: block

.share-outer, .item-unpublish-outer
  .icon-large
    font-size: 12em
    display: block
    text-align: center
    padding: 0.33em 0 0
    color: $color-primary-dark

  h1
    margin: 0
    padding: 0.5em 1em 2em
    text-align: center

.item-content
  section:empty, div:empty
    padding: 0
    margin: 0

.item-header
  padding: 1.5em
  height: 36em
  position: fixed
  overflow: hidden
  width: 100%
  top: 0
  opacity: 0
  transform-origin: center top

  @media (max-width: $mdscreen)
    padding: 2em
    height: 24em

  @media print
    position: absolute

  &.not-fixed
    position: absolute

  &.dark
    .item-header-bg
      &.normal
        opacity: 0.64

      &.display
        display: block

  &.no-palette
    background: $color-text-min

  .item-header-bg
    content: ""
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%

    &.display
      display: none

  .parallax
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%
    transform: translate3d(0,0,0)

    @media print
      transform: none !important

    &.item-parallax-img
      filter: contrast(1.33) grayscale(0.85) brightness(0.925)

  .item-image
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-size: cover
    background-position: center center
    z-index: 0
    opacity: 0.15

  h1
    margin: 0
    padding: 1em 0
    color: white
    text-align: center
    font-size: 4.75em
    text-shadow: 0 0.075em 0.125em rgba(black, 0.15), 0 1px 2px rgba(black, 0.15)
    position: relative
    z-index: 1
    top: 50%
    transform: translateY(-50%)
    font-family: inherit

    @media (max-width: $mdscreen)
      font-size: 3.33em

.item-body
  background-color: $color-lightest
  background-image: url('../images/bg-brilliant.png')
  box-shadow: 0 -0.25em 0.75em rgba(black, 0.15)
  position: relative
  z-index: 1
  margin-top: 36em
  transform-origin: center top
  opacity: 0

  @media (max-width: $mdscreen)
    margin-top: 24em

  @media print
    background-image: none
    box-shadow: none

  h1, h2, h3, h4, h5
    margin: 0
    color: $color-body-text

  label
    color: $color-body-text

  .item-inner
    max-width: 67rem
    padding: 1em 2em
    margin: 0 auto

    @media (max-width: $smscreen)
      padding: 1em 1.25em

    @media print
      padding: 1em 3em

.publish-section
  position: relative
  padding: 2em 0 4em

  &:nth-child(1)
    z-index: 3

  &:nth-child(2)
    z-index: 2

  &:nth-child(3)
    z-index: 1

  &:last-child
    z-index: 0

  .publish-icon-wrap
    color: white
    font-size: 4.5em
    border-radius: 100%
    margin: 1em auto 0.75em
    position: relative
    line-height: 1
    text-align: center

    @media (max-width: $smscreen)
      margin: 0.5em auto 0.25em

    &.icon-wrap-small
      font-size: 2em
      padding: 0.5em
      margin: 3em auto 1.5em
      height: 2em
      width: 2em
      box-shadow: 0 0.085em 0 rgba(black, 0.085)
      background: $color-body-text

      &:before
        width: 50vw
        right: auto
        left: 50%
        transform: translateX(-50%)

        @media (max-width: $smscreen)
          width: 9em

      &:after
        content: ""
        position: absolute
        height: 125%
        width: 125%
        background: $color-lightest
        top: calc(-12.5% - 1px)
        bottom: auto
        left: calc(-12.5% - 1px)
        right: auto
        z-index: -1
        border-radius: 100%
        border: 1px dashed $color-darkest

    &:before
      content: ""
      position: absolute
      height: 0
      width: 12em
      border-top: 1px dashed $color-darkest
      top: 0
      right: 0
      bottom: 0
      left: 0
      margin: auto
      z-index: -1

      @media (max-width: $lgscreen)
        width: 10em

      @media (max-width: $mdscreen)
        width: 8.5em

      @media (max-width: $smscreen)
        width: 6em

    svg
      filter: drop-shadow(0 0.05em 0.05em rgba(black, 0.15))

      @media (max-width: $lgscreen)
        transform: scale(0.925)

      @media (max-width: $mdscreen)
        transform: scale(0.85)

      @media (max-width: $smscreen)
        transform: scale(0.75)

      rect
        fill: $color-body-text

  h2
    padding: 1em 0
    text-align: center
    line-height: 1.33
    font-size: 2em
    font-family: inherit

    @media (max-width: $smscreen)
      font-size: 1.75em

    .header-label
      font-family: "mulilight"
      font-weight: normal
      font-style: normal
      line-height: 1.5

    .header-text
      display: block
      font-size: 1.5em
      margin: 0.33em auto
      line-height: 1.25em
      padding: 0 0.5em

      @media (max-width: $smscreen)
        font-size: 1.33em

.section-sub
  margin: 2em 0
  font-size: 1.33em

  .sub-content
    text-align: center
    margin: 4em 0

    @media (max-width: $mdscreen)
      margin: 3em 0

    @media (max-width: $smscreen)
      margin: 2em 0

    h3
      font-size: 1.5em

    .sub-label
      margin: 4em 0 2em
      font-size: 1em
      font-weight: normal
      font-style: normal
      line-height: 1.5

      .popup
        border-bottom: 1px dashed

    .sub-text
      font-size: 1.5em
      margin: 0.33em auto
      line-height: 1.33em
      max-width: 30em

.item-publish-audience.has-definition
  padding-bottom: 0

  .item-audience-sub
    display: flex
    padding: 4em 0 0
    margin-bottom: 0

    @media (max-width: $mdscreen)
      padding-top: 3em

    @media (max-width: $smscreen)
      flex-flow: row wrap

    .sub-content
      flex: 1
      margin: 0
      padding: 0 0 4em

      @media (max-width: $smscreen)
        flex: 100%
        padding: 3em 1em

      &:nth-child(2)
        border-left-width: 1px
        border-left-style: dashed

        @media (max-width: $smscreen)
          border-left: none
          padding-bottom: 4em

      .icon-wrap-small
        margin: -1em auto 1.75em

        @media (max-width: $smscreen)
          margin-top: 0

      .sub-label, .sub-text
        padding-left: 1em
        padding-right: 1em

.item-details-category
  padding-top: 1em

.item-details-due
  padding: 0.5em
  display: flex
  flex-flow: column wrap
  align-items: center

  &.dark
    div a
      color: $color-darkest

      &:hover, &:hover i:before
        color: rgba(black, 0.5)

  label
    font-size: 1em

  .details-due-date
    display: inline-block
    background: white
    border-radius: $radius-border * 3
    overflow: hidden
    box-shadow: 0 0.25em 0.5em rgba(black, 0.15)
    margin: 0.75em auto 0.25em
    min-width: 8.5em
    font-family: "mulilight"
    font-weight: normal
    font-style: normal

    > span
      display: block
      line-height: 1

    .due-date-month
      background: $color-darkest
      color: white
      padding: 0.5em 1em
      font-size: 1em
      text-transform: uppercase

    .due-date-day
      font-size: 2.5em
      padding: 0.33em

    .due-date-year
      font-size: 1em
      padding: 0.5em
      background: $color-lightest
      box-shadow: 0 1px 1px rgba(black, 0.075) inset

  // add-to-calendar-button
  //   margin-top: 1em

  // .atcb-list-item
  //   font-size: 0.875em

.item-details-other
  padding-top: 0.25em

.item-inspiration-adjectives.sub-content
  h3
    font-family: "mulilight"
    font-size: 1.33em
    font-weight: normal
    font-style: normal

  .adjectives-list
    margin: 1.5em 0 5em

    .item-adjective
      display: inline-block
      margin: 0 1.5em
      text-align: center
      font-size: 1.15em
      font-weight: normal
      font-style: normal

      &.elegant
        span
          font-size: 2.75em
          line-height: 0.75
          position: relative
          top: 0

      &.modern
        span
          font-size: 2em

      &.bold
        span
          position: relative
          top: 0.05em

  span
    font-size: 2.25em
    margin: 0
    padding: 0.25em
    line-height: 1
    display: inline-block
    vertical-align: middle

.item-inspiration-palette
  &.sub-content
    margin-bottom: 6em

  .btn
    border-radius: 0 0 $radius-border $radius-border
    font-size: 0.85em
    margin-bottom: 2em

    @media print
      display: none

.item-palette-wrap
  display: flex
  flex-flow: row wrap
  margin: 3em -0.5em 3em
  align-items: flex-start
  justify-content: center

  &.palette-alt-wrap
    margin: 3em -0.5em 4.25em

    @media (max-width: $smscreen)
      margin: 3em 0 4em

    .palette-alt-color
      max-width: calc(33.333% - 1em)
      font-size: 0.85em
      margin: 0.75em 0.5em
      flex: 33.333%

      @media (max-width: $smscreen)
        max-width: 100%
        flex: 100%
        margin: 0.75em 0
        font-size: 0.925em

  .item-palette-color
    flex: 1
    margin: 0.25em 0.5em
    border-radius: $radius-border
    overflow: hidden
    box-shadow: 0 0 0 0.15em white, 0 0.5em 0 -0.15em rgba(black, 0.05)
    font-family: "muliregular"
    font-weight: normal
    font-style: normal

    @media (max-width: $smscreen)
      flex: 100%
      margin: 0.5em 0.5em 0.75em

    ::selection
      background: white
      color: black

    &.primary, &.secondary, &.accent
      .color label
        text-transform: capitalize

    > span
      font-size: 1em
      display: inline-block
      color: white
      text-shadow: 0 2px 1px rgba(black, 0.15)

      &.color
        width: 100%
        padding: 1.5em 0 0
        display: block

      &.color-normal
        width: 100%
        padding: 3em 1.5em

        @media (max-width: $smscreen)
          padding: 1.75em 0.5em

      &.color-dark, &.color-light
        width: 50%
        font-size: 0.75em
        padding: 1.75em 1em

        @media (max-width: $smscreen)
          padding: 1.33em 1em

      &.dark
        color: $color-darkest
        text-shadow: 0 2px 1px rgba(black, 0.075), 0 -1px 1px rgba(white, 0.25)

        *::selection
          background: black
          color: white

      label
        text-align: left
        color: inherit
        text-shadow: none
        margin: 0 1.5rem 3em
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis
        line-height: 1.25

      > span
        display: block
        font-size: 0.67em
        margin: 0.75em 1.5rem
        text-align: left

        &.color-hex
          font-size: 0.75em

  .color-vars
    margin: 1.5em 0 0
    display: flex
    height: 2em
    flex-flow: row nowrap

    @media (max-width: $smscreen)
      height: 2.25em

    &:empty
      margin: 1.5em 0 0

    .color-var
      flex: 1
      height: 100%

#project, #styleguide, #showcase, #persona
  .item-palette-report-wrap
    box-shadow: 0 0.15em 0.33em rgba(black, 0.085)
    margin: 5em 0

    div.btn
      font-family: "muliregular"
      font-weight: normal
      font-style: normal

  .item-palette-report
    padding: 1.5em 1em
    border-radius: $radius-border $radius-border 0 0
    background: white

    @media (max-width: $smscreen)
      padding: 1.5em 0.5em

    @media print
      border-radius: $radius-border

    &.hidden
      .palette-report-wrap
        opacity: 0
        font-size: 0

        @media print
          font-size: 1em
          opacity: 1

    h3
      font-family: inherit

      .palette-report-title
        display: block
        cursor: pointer
        transition: color ease $speed-faster

        &:hover
          color: $color-primary

      .palette-report-sub
        display: block
        font-size: 0.5em
        font-family: "muliregular"
        font-weight: normal
        font-style: normal
        padding: 0 1em
        max-width: 48em
        margin: 1em auto 0.5em
        line-height: 1.5

        a, .link
          border-bottom: 1px dotted $color-primary
          margin: 0 0.15em
          font-family: "muliregular"
          font-weight: normal
          font-style: normal

          &:hover
            border: 0 none

          @media print
            border: 0 none

.color-vars-details
  &.primary, &.secondary, &.accent
    h5
      text-transform: capitalize

  h5
    font-size: 1.15em
    margin: 0.25em 0 0.5em
    font-family: "muliregular"

  p
    span
      display: block
      margin: 0.5em 0

.palette-report-wrap
  display: flex
  flex-flow: row wrap
  padding: 0.5em 0
  margin-top: 1.25em
  font-size: 1em
  opacity: 1
  overflow: hidden
  transition: all ease $speed-faster

  @media (max-width: $smscreen)
    transition: none

  &.palette-alt-report
    border-top: 1px dashed rgba(black, 0.15)
    padding-top: 1em
    padding-bottom: 1em
    font-size: 0.925em
    justify-content: center

    @media (max-width: $mdscreen)
      font-size: 1em
      margin-top: 0
      padding-top: 1.5em

    .palette-report-col
      max-width: 33.333%
      flex: 33.333%
      padding: 1em 0 1em 1em

      &:last-child
        @media (max-width: $smscreen)
          margin-bottom: 0

      @media (max-width: $smscreen)
        flex: 100%
        max-width: 100%
        padding: 0.25em 0
        font-size: 1em

  .palette-report-col
    flex: 1
    text-align: left
    padding-left: 1em

    @media (max-width: $smscreen)
      flex: 100%
      text-align: center
      position: relative
      padding-left: 0
      margin-bottom: 2.25em

    @media (max-width: $xsscreen)
      padding-left: 15%

    .color-text
      display: block
      font-size: 0.75em
      padding: 0.67em 0
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      width: 100%

      @media (max-width: $mdscreen)
        padding: 0.5em

      @media (max-width: $smscreen)
        width: auto

      &.color-text-large
        font-size: 0.925em

        .icon
          font-size: 0.75em

    .report-pass .icon
      background: $color-success

    .report-fail .icon
      border-radius: 0
      position: relative
      text-align: center

      &:before
        z-index: 1
        position: relative
        top: 0.075em

      &:after
        content: ""
        position: absolute
        left: 0
        right: 0
        bottom: 0.25em
        border-width: 1em 
        border-style: solid
        border-color: transparent transparent $color-warning
        z-index: 0
        transform: scaleY(1.75)
        transform-origin: center bottom

    .icon
      padding: 0.5em 0.525em
      border-radius: 100%
      height: 2em
      width: 2em
      margin-right: 1em
      color: white
      display: inline-block

    .report-color-alt
      font-size: 0.67em
      line-height: 1.33em
      padding: 0.5em 0
      display: flex
      align-items: center
      width: 100%

      @media (max-width: $smscreen)
        justify-content: center

      &:first-child
        margin-top: 0.67em

      .color-alt
        display: inline-block
        width: 5.5em
        text-align: center
        padding: 0.33em
        margin-right: 0.85em
        font-size: 0.925em
        overflow: hidden
        border-radius: $radius-btn
        box-shadow: 0 0.33em 0 -0.15em rgba(black, 0.15)
        transition: box-shadow ease $speed-faster
        font-family: "muliregular"
        font-weight: normal
        font-style: normal

        &:hover
          box-shadow: 0 0.25em 0.5em rgba(black, 0.2)

        &::selection
          background: white
          color: black

      .color-alt-text
        border-bottom-width: 1px
        border-bottom-style: dotted
        margin: 0 0 0 0.25em
        display: inline-block
        position: relative
        cursor: pointer

        &:hover
          border-color: transparent

        @media print
          border: 0 none

  .palette-report-footer
    flex: 100%
    font-size: 0.67em
    color: $color-text-min
    margin: 3em 0 0
    padding: 0 0.5em
    font-family: "muliregular"
    font-weight: normal
    font-style: normal

    @media (max-width: $smscreen)
      margin-top: 1em

.item-inspiration-font
  .item-publish-font
    display: block
    margin: 3em auto 6em
    padding: 2.5em 5.5em
    background: rgba(white, 0.75)
    text-align: left
    border-radius: $radius-border
    position: relative
    overflow: hidden

    @media (max-width: $smscreen)
      padding: 1.5em

    @media (max-width: $xsscreen)
      padding: 1.5em 1em

    &:hover
      .card-download
        animation: showCardActiveLeft $speed-faster forwards ease 

      .card-link
        animation: showCardActiveRight $speed-faster forwards ease

    &:last-child
      margin-bottom: 1.5em

    h2
      margin: 0.5em 0
      text-align: left
      font-size: 2.75em
      padding: 0
      text-transform: capitalize

      @media (max-width: $smscreen)
        font-size: 2.5em

    h3
      font-size: 1.5em
      margin: 1.75em 0 0
      padding: 0

    p
      color: $color-body-text
      line-height: 1.5
      font-size: 0.825em

      &.font-characters
        font-size: 0.925em

    ul
      padding: 0
      vertical-align: top
      columns: 2

      li
        list-style: none
        margin: 0.5em 0
        font-size: 0.925em
        display: inline-block
        width: 100%

    .card-download, .card-link
      @media print
        display: none

      &.font-download
        @media (max-width: $smscreen)
          display: none

.item-publish-images
  width: calc(100% + 1em)
  margin: 3em -0.5em 1.5em
  font-size: 0.85em

  @media (max-width: $smscreen)
    margin: 2.5em 0
    width: 100%

  .item-image-wrap
    width: 50%
    padding: 0.67em 0.5em
    display: inline-block

    @media (max-width: $smscreen)
      width: 100%
      padding: 0.75em 0

  .item-image
    width: 100%
    position: relative
    overflow: hidden
    box-shadow: 0 0.67em 0.33em -0.33em rgba(black, 0.085)
    background: white
    padding: 0.15em

    &:hover
      .card-download
        animation: showCardActiveLeft $speed-faster forwards ease

    img
      width: 100%
      height: auto
      vertical-align: bottom

.content-download
  position: fixed
  top: 0
  left: 0
  height: 100%
  width: 100%
  z-index: 5
  opacity: 0
  animation: showFade $speed-normal forwards ease

  &.step-2 h1
    opacity: 0
    animation: showFade $speed-normal forwards ease

  &.step-3 h1
    opacity: 0
    animation: showFadeNext $speed-normal forwards ease

  .content-download-bg
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%
    content: ""
    z-index: -1
    opacity: 0.925
    background-color: $color-secondary

  .content-loading
    filter: drop-shadow(0 0.215em 0.425em rgba(black, 0.0925))

  h1
    color: white
    font-size: 3.33em
    text-shadow: 0 0.075em 0.15em rgba(black, 0.15)

#styleguide
  .item-content.no-paletteHeader 
    .item-header
      height: 100vh

      @media (max-width: $mdscreen)
        height: calc(100vh - (#{$height-styleguide-nav} * 1.5))

      > .parallax
        margin-top: -2.5em

    .item-body
      margin-top: calc(100vh - #{$height-styleguide-nav})

      @media (max-width: $mdscreen)
        margin-top: calc(100vh - (#{$height-styleguide-nav} * 2.5))

      .item-palette-header
        height: $height-styleguide-nav
        opacity: 0

      .item-nav-holder .sticky > ul > li
        box-shadow: inherit

      .styleguide-nav
        transition: top ease $speed-faster
        position: relative

        > ul
          background-color: white

          > li
            color: $color-body-text
            text-shadow: none

            &:hover
              box-shadow: 0 -0.15em 0 $color-darkest inset

            @media (max-width: $smscreen)
              min-width: 20%

  .item-body.no-palette
    box-shadow: 0 -0.25em 0.75em rgba(0, 0, 0, 0.125)

    .item-palette-header
      display: none

    .item-publish-inspiration, .item-inner
      padding-top: 0

    .item-nav-holder
      position: relative

      ul
        background: white

        li
          color: $color-body-text
          text-shadow: 0 1px 0 rgba(white, 0.5)

          &:hover
            box-shadow: 0 -0.15em 0 $color-dark inset

          &.active
            box-shadow: 0 -0.15em 0 $color-darkest inset

  .publish-section
    background: transparent
    z-index: 0

  .styleguide-nav
    &.no-palette
      ul li
        color: $color-darkest
        text-shadow: 0 1px 0 rgba(white, 0.25)

    ul
      height: $height-styleguide-nav

  .item-nav-holder .sticky .palette-header.dark,
  .styleguide-nav.dark
    ul li
      color: $color-darkest
      text-shadow: 0 1px 0 rgba(white, 0.25)

  .item-nav-holder
    width: 100%
    text-align: center
    display: inline-block
    position: absolute
    z-index: 1
    opacity: 0
    animation: showFade $speed-faster $speed-normal forwards ease

    .sticky
      .palette-header ul li:hover
        box-shadow: 0 -0.15em 0 white inset
        
      ul
        background: rgba(white, 0.925)
        flex: 1

        li
          color: $color-darkest
          text-shadow: 0 1px 0 rgba(white, 0.185)

          &:hover
            box-shadow: 0 -0.15em 0 $color-darkest inset

            @media (max-width: $smscreen)
              box-shadow: none

    ul, .sticky .palette-header ul
      display: flex
      margin: 0
      padding: 0 5em
      background: rgba(white, 0.075)
      box-shadow: 0 0.425em 0 -0.215em rgba(black, 0.085)
      transition: background ease $speed-faster
      flex-flow: row nowrap
      justify-content: center
      align-items: center
      overflow: auto

      @media (max-width: $mdscreen)
        padding: 0 0.5em
        justify-content: flex-start

    li, .sticky .palette-header li
      color: white
      text-shadow: 0 2px 0 rgba(black, 0.25)

    li
      flex: 1
      list-style: none
      margin: 0 0.25em
      padding: 0
      padding: 1.75em 2em
      font-size: 1.15em
      cursor: pointer
      box-shadow: none
      transition: box-shadow ease $speed-fastest, color ease $speed-fastest, text-shadow ease $speed-fastest
      position: relative
      max-width: 33.333%
      height: 100%
      display: inline-flex
      justify-content: center
      align-items: center

      @media (max-width: $mdscreen)
        padding: 1.75em 1em

      @media (max-width: $smscreen)
        padding: 1.75em 0
        min-width: 18%
        max-width: 100%

      &.active
        box-shadow: 0 -0.15em 0 $color-darkest inset

      &:hover
        box-shadow: 0 -0.15em 0 white inset
        color: white

  .sub-content
    margin: 2em 0
    padding-top: 4em
    padding-bottom: 4em

    @media (max-width: $smscreen)
      padding-top: 0
      padding-bottom: 2.5em

  .publish-section
    h2
      font-size: 2.85em
      margin: 0.75em 0 0.5em

      @media (max-width: $smscreen)
        margin-bottom: 0.25em

  .item-publish-nav
    @media (max-width: $mdscreen)
      top: auto

    @media (max-width: $smscreen)
      top: auto
      bottom: 0

    .item-back-btn, .item-nav-trigger
      > .icon
        text-shadow: 0 2px 0 rgba(white, 0.75)

      &:hover > .icon
        text-shadow: 0 1px 0 rgba(black, 0.33)

    .item-nav-trigger
      box-shadow: none

      @media (max-width: $mdscreen)
        background: rgba(white, 0.925)
        box-shadow: 0 0.175em 0 -0.075em rgba(black, 0.085)

      &:hover .icon
        color: white
        text-shadow: 0 1px 0 rgba(black, 0.33)

      > .icon
        @media (max-width: $mdscreen)
          color: white
          text-shadow: 0 1px 0 rgba(black, 0.33)

  .item-header
    background: white

    h1
      color: $color-body-text

    &.white
      background: $color-darkest

      h1
        color: white

    .item-parallax-logo, .item-parallax-name
      text-align: center

    .item-parallax-logo
      &.tall, &.taller, &.tallest
        img
          max-height: 100%
          min-width: 0

      &.tall 
        img
          max-height: 12em
          // min-height: 10em

      &.taller 
        img
          max-height: 14em
          // min-height: 12em

      &.tallest 
        img
          max-height: 16em
          // min-height: 14em

      img
        position: relative
        max-width: 50vw
        max-height: 10.5em
        //min-width: 60vw
        //min-height: 8.5em
        height: auto
        width: auto
        top: 50%
        transform: translateY(-50%)

        @media (max-width: $smscreen)
          max-width: 80vw

  .item-palette-header
    margin-bottom: 1.5em
    transition: opacity ease $speed-fast
    
    .item-header-color
      overflow: hidden
      position: relative

      &.header-palette-primary
        height: 48em

        @media (max-width: $smscreen)
          height: 36em

        .header-img
          height: 64em

          @media (max-width: $smscreen)
            height: 36em

      &.header-palette-secondary
        height: 32em

        @media (max-width: $smscreen)
          height: 24em

        .header-img
          height: 60em

          @media (max-width: $smscreen)
            height: 24em

      &.header-palette-accent
        height: 24em

        @media (max-width: $smscreen)
          height: 18em

        .header-img
          height: 58em

          @media (max-width: $smscreen)
            height: 18em

      .item-parallax-img
        position: absolute
        top: 0
        left: 0
        height: 100%
        width: 100%

      .header-img
        position: absolute
        top: 0
        left: 0
        width: 100%
        background-position: center center
        background-size: cover
        background-repeat: no-repeat
        filter: grayscale(0.925) brightness(0.875)
        opacity: 0.185

  .item-logos-wrap
    display: flex
    flex-flow: row wrap

    .item-logo
      position: relative
      overflow: hidden
      padding: 4em
      margin: 0.75em 0
      background: rgba(white, 0.67)
      border-radius: $radius-border
      flex: 100%
      width: 100%

      @media (max-width: $smscreen)
        padding: 2.5em 2em

      &.tall, &.taller, &.tallest
        img
          max-height: 100%

      &.tall
        padding: 1.5em

        &#item-logo-0
          img
            max-height: 7em

        img
          max-height: 5em

      &.taller
        padding: 1.25em 1.5em

        &#item-logo-0
          img
            max-height: 8em

        img
          max-height: 6em

          @media (max-width: $smscreen)
            max-height: 5em

      &.tallest
        padding: 1em 1.5em

        &#item-logo-0
          img
            max-height: 9em

        img
          max-height: 7em

          @media (max-width: $smscreen)
            max-height: 5em

      &:hover
        .card-download
          animation: showCardActiveLeft $speed-faster forwards ease

      &.white
        background: $color-darkest

      &#item-logo-0
        margin: 1em 0 2em

        img
          max-height: 6.67em
          max-width: 90%

          @media (max-width: $smscreen)
            max-width: 95%

      img
        max-width: 85%
        max-height: 6em
        width: auto
        height: auto

        @media (max-width: $smscreen)
          max-width: 90%

      .file-icon
        min-height: 5.25em
        display: block
        font-size: 1rem

        @media (max-width: $mdscreen)
          min-height: 5em

        @media (max-width: $smscreen)
          min-height: 4.75em

        .icon
          color: $color-body-text
          z-index: 1
          font-size: 5.25em
          top: -0.075em

          @media (max-width: $mdscreen)
            font-size: 4.25em

          @media (max-width: $smscreen)
            font-size: 4em
            top: -0.25em

      .file-detail
        &:after
          display: none

        .file-detail-content
          padding: 0
          left: -0.25em
          bottom: 0.75em
          font-family: "muliregular"
          font-style: normal
          font-weight: normal
          font-size: 0.64em

          @media (max-width: $smscreen)
            font-size: 0.575em

  .font-wrap
    .item-publish-font h2
      font-size: 2.5em
      margin: 0.5em 0 1em

  .item-icons-wrap
    .item-icons
      position: relative

      .icons-grid
        position: relative
        margin: 1.5em auto 3em
        background: rgba(white, 0.75)
        border-radius: $radius-border
        padding: 1.5em 0
        overflow: hidden

        @media (max-width: $smscreen)
          padding: 1em 0

        &:hover
          .card-download
            animation: showCardActiveLeft $speed-faster forwards ease 

        .icons-data
          position: relative

          i
            font-size: 1.75em

          img
            width: 3.5em

        .icons-upload-nodata
          background: rgba(white, 0.75)
          width: 100%
          border-radius: $radius-border
          text-align: center
          margin-bottom: 1em

          a
            padding: 2em
            display: block
            font-size: 0.85em

            .icon
              font-size: 1.25em
              margin-right: 0.5em
              margin-top: -0.25em
              position: relative
              display: inline-block
              top: 0.085em

        .icons-download
          display: block
          text-align: center
          width: 100%
          margin: 1.25em auto 1em

          .btn.btn-download-icons
            background: rgba(white, 0.75)
            color: $color-text-min
            border: 0 none
            margin: 3.25em auto 2em
            font-size: 0.75rem
            padding: 1em 1.5em
            box-shadow: 0 0.33em 0 -0.15em rgba(black, 0.085)
            text-shadow: none

            &:hover
              box-shadow: 0 0.5em 1em -0.15em rgba(black, 0.2)

            .icon
              font-size: 1.25em
              margin-right: 0.5em
              position: relative
              top: 0.15em
              line-height: 0.75

      a, .link
        color: $color-body-text

  .item-elements-wrap
    .item-elements .element-content
      margin: 2.5em auto
      text-align: left
      font-size: 16px

      *
        font-size: 1em

    .content-headers, .content-paragraph
      background: rgba(white, 0.75)
      padding: 1.5em 3em
      border-radius: $radius-border

      @media (max-width: $smscreen)
        padding: 1em

    .element-headers
      @import 'reset'

    .item-element
      &.element-headers .element-content h2
        text-align: left

      &.element-code .element-content code
        display: inline-block
        width: 100%

      &.element-buttons .element-content
        padding: 0

        .element-button-content
          display: flex
          margin: 0 -1em
          flex-flow: row wrap

          @media (max-width: $smscreen)
            margin: 3.5em -1em

          .btn
            flex: 1
            margin: 1rem
            text-shadow: none

            @media (max-width: $smscreen)
              width: 100%
              flex: 100%
              margin: 0.5rem 1rem

        .element-css-wrap
          @media (max-width: $smscreen)
            margin-top: -1.5em

      &.element-dropdowns .element-content
        .element-dropdown-content
          display: flex
          margin: 0 -1em
          flex-flow: row wrap

          @media (max-width: $smscreen)
            flex-flow: column wrap

        .ui.menu
          flex: 1
          margin: 1rem
          position: relative

          @media (max-width: $smscreen)
            margin: 1.5rem 1rem

          &:nth-child(2), &:nth-child(3)
            .dropdown > .menu
              position: relative
              opacity: 1

              &:before
                opacity: 1

          .dropdown > .menu.visible
            position: relative

          .btn
            width: 100%
            text-align: left

            .icon
              float: right
              font-size: 1rem

          .dropdown > .menu
            margin-top: 0.285em
            padding: 0.5em 0
            box-shadow: none
            font-size: 1em
            float: right
            filter: none

            &:before
              filter: none

            .item a, .item .link
              text-align: left
              margin: 0
              padding: 0.67em 1.5em
              font-size: 0.85em

      &.element-inputs .element-content
        .input-group, .textarea-group
          display: flex
          margin: 0 -1em
          flex-flow: row wrap

          input, textarea
            flex: 1
            margin: 1em

            @media (max-width: $smscreen)
              flex: 100%
              width: 100%

      &.element-toggles .element-content
        display: flex
        font-size: 1rem
        flex-flow: row wrap

        .toggle-group
          flex: 1
          display: flex
          flex-flow: column wrap
          align-items: center

          > div
            margin: 1em

            @media (max-width: $smscreen)
              margin: 1.5em 1em

          label
            padding-left: 1.33em
            padding-top: 0.15em

  #elements
    .element-css-wrap
      text-align: center
      flex: 100%
      width: 100%

    .btn.btn-element-css
      background: rgba(white, 0.75)
      color: $color-text-min
      border: 0 none
      margin: 3em auto 2em
      font-size: 0.75rem
      padding: 1em 1.5em
      box-shadow: 0 0.33em 0 -0.15em rgba(black, 0.085)
      text-shadow: none

      &:hover
        box-shadow: 0 0.5em 1em -0.15em rgba(black, 0.2)

      &.active
        background: $color-darkest
        color: white
        box-shadow: 0 0.075em 0.15em rgba(black, 0.5) inset

      .icon
        font-size: 1.25em
        margin-right: 0.5em
        position: relative
        top: 0.15em
        line-height: 0.75

    .element-dropdowns .element-content .dropdown
      .item:hover a, .item.hover a,
      .item:hover .link, .item.hover .link
        background: $color-darkest

  .item-elements-wrap .item-elements .element-css
    background: $color-darkest
    padding: 2em
    border-radius: $radius-border
    display: none
    position: relative
    margin: 0 -2em
    box-shadow: 0 0.15em 0.33em rgba(black, 0.15)
    transition: margin ease $speed-faster

    &:before
      content: ""
      position: absolute
      height: 0
      width: 0
      bottom: 100%
      left: 0
      right: 0
      margin: auto
      border-style: solid
      border-width: 0.75em
      border-color: transparent transparent $color-darkest

    &.active
      display: block
      animation: showContentFade $speed-faster ease forwards
      margin-bottom: 8.5em

    code
      margin: 0
      width: 100%
      color: white
      padding: 2em
      display: inline-block
      font-size: 0.785rem
      border: none
      background: none
      box-shadow: none
      text-align: left

#showcase
  .publish-section
    h2
      font-size: 2.85em
      margin: 0.75em 0 0.5em

      @media (max-width: $smscreen)
        margin-bottom: 0.25em

  .item-publish-nav
    @media (max-width: $mdscreen)
      top: auto

    @media (max-width: $smscreen)
      top: auto
      bottom: 0
      
    .item-back-btn, .item-nav-trigger
      > .icon
        text-shadow: 0 2px 0 rgba(white, 0.75)
        color: $color-body-text

      &:hover > .icon
        color: $color-accent
        text-shadow: 0 1px 0 rgba(black, 0.33)

    .item-nav-trigger
      @media (max-width: $mdscreen)
        background: rgba(white, 0.925)
        box-shadow: 0 0.175em 0 -0.075em rgba(black, 0.085)

      &:hover .icon
        text-shadow: 0 1px 0 rgba(black, 0.33)

      > .icon
        @media (max-width: $mdscreen)
          color: white
          text-shadow: 0 1px 0 rgba(black, 0.33)

  .item-header
    h1
      color: $color-body-text
      text-shadow: 0 0.075em 0.125em rgba(black, 0.075)

  .item-body
    margin: 0

    &.has-content
      padding-top: 4em

      .section-sub > .text-content,
      #screens > .text-content
        max-width: 67rem
        margin-left: auto
        margin-right: auto

    .item-inner
      max-width: 100%
      padding-top: 0

  .publish-section
    padding-top: 0

  .item-inspiration-sub,
  .section-sub .sub-content.index-0,
  .section-sub .sub-content.index-0 h2
    margin-top: 0

  #palette, #fonts, #files
    max-width: 67rem
    margin-left: auto
    margin-right: auto

  #screens .screens-list
    margin: 0 -1.5em
    background: white
    box-shadow: 0 0.25em 1em rgba(black, 0.15)

    @media (max-width: $lgscreen)
      margin: 0 -1.5em

    .screen-content
      min-height: 100vh
      display: flex
      align-items: center
      justify-content: center

    .screen-background
      .file-img
        width: 100%
        height: 100%
        min-height: 100vh
        display: flex
        justify-content: center
        align-items: center

    .screen-desktop
      .file-img-wrap, .file-img-preview
        width: 80vw
        height: 45vw

        @media (min-width: $hugescreen)
          width: 64rem
          height: 36rem

    .screen-normal
      .screen-content
        margin-bottom: 0
        min-height: unset

      .file-img-wrap
        min-width: 100vw

        img
          filter: none

.item-inspiration-files
  .styleguide-files
    margin: 3.5em 0 10.5em
