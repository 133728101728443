.item-logos
  .component-section-wrap.done
    .logos-files .files-list
      margin-top: -0.25em
      margin-bottom: -0.25em

      .card.selected
        display: block
        flex: 100%

        &.logo-primary-drop
          display: none

        .file-detail
          display: none

        .file-icon .icon
          top: 0

  .logos-files
    &.empty
      .logo-primary-drop
        display: none

  .files-list
    margin-top: 1.5em
    margin-bottom: 1.5em
    animation: showItem $speed-slower forwards ease
    animation-delay: $speed-fastest
    opacity: 0
    position: relative

    &:empty
      margin-top: 0
      margin-bottom: 0

  .card
    padding: 0
    margin-top: 0.75em
    margin-bottom: 0.75em
    width: 100%
    animation: none
    transform-origin: center center
    opacity: 1
    text-align: center
    cursor: grab
    user-select: none
    height: 10.5em

    &.selected
      background: darken(white, 2%)

    &:hover
      .card-download
        animation: showCardActiveLeft $speed-faster forwards ease

      .card-delete
        animation: showCardActiveRight $speed-faster forwards ease

    &.selected
      &:before, &:after
        display: none

    &.logo-primary-drop
      position: absolute
      top: 0
      left: 0
      width: calc(100% - 1em)
      height: 10.5em
      pointer-events: none
      background: transparent
      box-shadow: none
      opacity: 0
      animation: showFade $speed-normal forwards ease
      z-index: 2

      &:before
        content: ""
        position: absolute
        top: 0
        left: 0
        height: 100%
        width: 100%
        border-radius: $radius-btn
        z-index: 1
        box-shadow: 0 0 0 0.125em $color-success inset

      p
        background: darken($color-success, 5%)
        color: white
        font-size: 0.75em
        position: absolute
        text-shadow: 0 1px 0 rgba(black, 0.15)
        left: 0
        bottom: 0
        top: auto
        padding: 0.5em 0.75em 0.67em
        margin: auto
        line-height: 1
        border-radius: 0 $radius-btn 0 0
        z-index: 1
        width: auto
        height: auto

    .white
      background: $color-darkest

      p
        color: white
        text-shadow: none

.logo-content
  height: 100%
  width: 100%

  .file-img-bg, .file-img-preview
    height: calc(75% - 1.5em)
    width: 85%
    background-size: contain
    top: 0
    right: 0
    bottom: 0
    left: 0
    margin: auto

  .file-icon
    .icon
      color: $color-darkest
      font-size: 5.75em
      top: -0.15em

  .file-detail
    &:after
      display: none

    .file-detail-content
      text-shadow: 0 -1px 0 rgba(white, 0.5)
      pointer-events: none
      text-align: right
      color: $color-text-min
      background: rgba(white, 0.125)

    .file-text, .file-size
      display: inline-block
      overflow: hidden

    .file-text
      max-width: 16em
      text-overflow: ellipsis
      white-space: nowrap
      margin-right: 1.25em

    .file-size
      margin-left: 0.67em
      position: relative
      top: 0

      &:before
        content: "-"
        margin-right: 0.67em