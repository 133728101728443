.item-files
  .component-section-wrap.done
    .files-list .card
      display: block
      max-width: calc(25% - 1em)
      flex: 100%

    .card-download-wrap
      display: none

.file-img-wrap
  display: flex
  width: 100%
  height: 100%
  align-items: center
  justify-content: center 

.file-img-bg, .file-img-preview
  content: ""
  position: absolute
  top: 0
  left: 0
  height: 100%
  width: 100%
  background-size: contain
  background-position: center center
  background-repeat: no-repeat

.file-img-preview
  opacity: 0.5
  filter: blur(0.15em)

.file-loading
  content: ""
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  margin: auto
  height: 100%
  width: 100%
  background-image: url('../../images/loading-sm.svg')
  background-size: 14em
  background-repeat: no-repeat
  background-position: center center
  z-index: 2
  pointer-events: none

.file-icon
  &.brackets .icon:before
    content: "\eece"

  &.code .icon:before
    content: "\eeca"

  &.text .icon:before
    content: "\e9ae"

  &.word .icon:before
    content: "\e906"

  &.pdf .icon:before
    content: "\e900"

  &.spreadsheet .icon:before
    content: "\e9c6"

  &.excel .icon:before
    content: "\e904"

  &.presentation .icon:before
    content: "\e95c"

  &.powerpoint .icon:before
    content: "\e905"

  &.image .icon:before
    content: "\e93b"

  &.sketch .icon:before
    content: "\e907"

  &.figma .icon:before
    content: "\e90f"

  &.illustrator .icon:before
    content: "\e901"

  &.indesign .icon:before
    content: "\e902"

  &.photoshop .icon:before
    content: "\e903"

  &.xd .icon:before
    content: "\e910"

  &.audio .icon:before
    content: "\e94d"

  &.video .icon:before
    content: "\eef3"

  &.zip .icon:before
    content: "\e9ba"

  &.font .icon:before
    content: "\ea61"

.file-icon, .file-img
  .icon
    position: absolute
    color: $color-lighter
    font-size: 3.75em
    height: 1em
    width: 100%
    left: 0
    top: -0.33em
    bottom: 0
    margin: auto
    opacity: 1
    text-align: center
    transition: opacity ease $speed-faster

    @media (max-width: $smscreen)
      transition: none

.file-detail
  position: absolute
  bottom: 0
  left: 0
  height: 100%
  width: 100%
  pointer-events: none

  &:after
    content: ""
    position: absolute
    bottom: 0
    left: 0
    height: 100%
    width: 100%
    background: linear-gradient(transparent 50%, rgba(black, 0.25) 85%)

  .file-detail-content
    position: absolute
    top: auto
    bottom: 0
    left: 0
    padding: 0.85em
    margin: 0
    color: white
    z-index: 1
    font-size: 0.67em
    display: inline-block
    width: 100%
    text-shadow: 0 1px 1px rgba(black, 0.75)
    pointer-events: none
    text-align: left
    line-height: 1.25em
    font-family: "mulilight"
    font-style: normal
    font-weight: normal
    background: rgba(black, 0.5)

  .file-text
    display: block
    width: calc(100% - 4em)
    max-width: 16em
    margin-right: 4em
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

  .file-size
    position: absolute
    right: 1.25em
    top: 1.15em
    font-size: 0.925em

.item-drag
  transform: scale(1)
  list-style: none
  opacity: 1
  animation: none
  overflow: hidden
  padding: 0
  box-shadow: 0 0.5em 1em rgba(black, 0.33)
  user-select: none
  z-index: 3
  position: relative
  border-radius: $radius-btn
  background: $color-lightest

  &.file-drag
    background-color: $color-darker

  &.logo-drag
    background-color: darken(white, 2%)
    text-align: center

  &.font-drag
    .font-detail
      @media (max-width: $smscreen)
        display: inline-block
        padding: 1.75em 0.75em 2.5em
        width: 100%

    .font-variants-list
      display: none

  &.palette-drag
    text-align: center
    background-color: white

    .color-picker, .color-picker-btn .icon
      display: none

  &.image-drag
    border-radius: $radius-border

  .white
    background: $color-darkest

  .file-detail
    display: none

  .file-img-bg.progressive,
  .file-img-preview.progressive
    opacity: 1
    animation-duration: 0s
    animation-delay: 0s
    filter: none


// ITEM SPECIFIC FILES

.details-files, .styleguide-files, .showcase-files, .item-files-list
  .files-list
    margin-top: 1em
    margin-bottom: 0.15em

    &:empty
      margin-top: 0
      margin-bottom: 0

  .card.selected
    padding: 0
    width: calc(25% - 1em)
    height: 10em
    cursor: grab
    animation: none
    transform-origin: center center
    opacity: 1
    user-select: none
    background-color: darken($color-darker, 5%)
    border: 2px solid white

    @media (max-width: $mdscreen)
      width: calc(33.333% - 1em)

    @media (max-width: $smscreen)
      width: calc(50% - 1em)

.details-files, .styleguide-files, .item-files-list, .screens-files
  .card.selected
    &:hover
      .card-download
        animation: showCardActiveLeft $speed-faster forwards ease

        @media (max-width: $mdscreen)
          animation: none

      .card-delete
        animation: showCardActiveRight $speed-faster forwards ease

        @media (max-width: $mdscreen)
          animation: none

    a, .link
      cursor: grab
      color: white

    .file-icon
      animation: showFade $speed-normal forwards ease
      opacity: 0

    .card-download-wrap, .card-delete-wrap
      animation: showFade $speed-faster $speed-faster forwards ease
      pointer-events: auto

      @media (max-width: $mdscreen)
        transform: none
        transition: none
        animation: none
        opacity: 1

        &:hover, &:active
          animation: none
          opacity: 1

    .card-download
      @media (max-width: $mdscreen)
        animation: none


// FILES DROPZONE

.files-dropzone
  background: darken($color-primary-darkest, 5%)
  border-radius: $radius-border * 2
  padding: 1.5em
  margin: 0.5em 0
  text-align: center
  display: inline-block
  width: 100%
  box-shadow: 0 0 0.33em 0.15em rgba(black, 0.2) inset
  position: relative
  transition: background ease $speed-fast, box-shadow ease $speed-fast
  cursor: pointer

  @media (max-width: $smscreen)
    transition: none

  &:hover
    background: darken($color-primary-darkest, 15%)
    box-shadow: 0 0 0.5em 0.25em rgba(black, 0.2) inset

  &.active
    .files-drop-msg
      opacity: 1

      .icon
        animation: showDropIcon $speed-normal forwards ease

  &.uploading
    .files-drop-msg
      transition-delay: $speed-faster

    .files-drop-progress
      opacity: 1
      transition-delay: 0s

  &.extracting
    .files-extract-msg
      transition-delay: $speed-faster

    .files-extract-progress
      opacity: 1
      transition-delay: 0s

  h4
    margin: 0.5em 0.5em 0.75em
    font-size: 1.5em
    font-family: "mulilight"
    color: white
    text-shadow: 0 0.075em 0.15em rgba(black, 0.15)

  h5
    color: white
    font-size: 0.85em
    margin: -0.425em 0 1.5em

  .btn
    display: inline-block
    margin: 0.25em 0.5em 1.5em
    font-size: 1em

  label
    font-size: 0.75em
    color: white
    margin: 0.75em 0.67em 0.5em
    padding: 0
    text-shadow: 0 0.075em 0.15em rgba(black, 0.15)

    .icon
      font-size: 1.25em
      line-height: 0.75
      position: relative
      top: 0.125em
      margin: 0 0.5em 0 0

  input[type="file"]
    z-index: -1
    box-shadow: none
    background: transparent

  .files-usage
    margin: 0.25em auto 0.5em

    .progress-bar
      width: 18em

  .files-drop-msg, 
  .files-extract-msg, 
  .files-drop-progress, 
  .files-extract-progress
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%
    text-align: center
    border-radius: $radius-border * 2
    opacity: 0
    transition: opacity ease $speed-faster
    overflow: hidden
    pointer-events: none

    @media (max-width: $smscreen)
      transition: none

  .files-drop-msg
    box-shadow: 0 0 0.75em 0.25em rgba(black, 0.15) inset
    background: $color-primary-access

    p
      vertical-align: middle
      color: white
      font-size: 3em
      margin: 0
      padding: 0
      position: relative
      top: 50%
      transform: translateY(-50%)
      text-shadow: 0 0.05em 0.05em rgba(black, 0.075)

    .icon
      font-size: 13em
      color: $color-primary-darkest
      opacity: 0.33
      position: absolute
      width: 1em
      height: 1em
      left: 0
      right: 0
      top: 0
      bottom: 0
      margin: auto
      opacity: 0
      transform: translate3d(0,0,0)

  .files-drop-progress
    box-shadow: 0 0 0.25em 0.25em rgba(black, 0.075) inset
    background: darken($color-primary-darkest, 5%)

    .drop-progress-bar
      content: ""
      background: $color-primary-access
      width: 0
      height: 100%
      position: absolute
      top: 0
      left: 0
      transition: width ease $speed-slow

    .upload-loading
      content: ""
      position: absolute
      top: 0
      left: 0
      height: 100%
      width: 100%
      background-image: url('../../images/loading-md.svg')
      background-position: center center
      background-size: 14em
      background-repeat: no-repeat
      filter: drop-shadow(0 0.15em 0.33em rgba(black, 0.15))

.files-bad-wrap
  background: $color-alert
  margin: 0.5em 0 1em
  border-radius: $radius-border
  position: relative
  padding: 1em 1.5em 0.75em
  color: white
  text-align: left
  opacity: 0
  animation: showItem $speed-normal ease forwards

  .close-files-bad
    position: absolute
    right: 1em
    top: 0.5em
    padding: 0.5em

    .icon
      color: white
      cursor: pointer

      &:hover
        color: $color-lighter

  label
    margin: 0
    padding: 0
    font-size: 1em
    color: white

  ul
    margin: 1em 0 0.25em
    padding: 0

  li
    list-style: none
    font-size: 0.85em
    margin: 0 0.5em
    padding: 0.75em
    display: inline-block
    width: 100%
    border-bottom: 1px dashed rgba(white, 0.15)

    &:last-child
      border: none

    p
      margin: 0
      padding: 0
      display: flex
      width: 100%
      overflow: hidden

      .file-name, .file-size
        flex: 1

      .file-name
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
        padding-right: 1.5em
        line-height: 1.25

      .file-reason
        font-family: "muliregular"

      .file-size
        font-size: 0.85em
        margin-left: 1em
        opacity: 0.85

.item-details
  .item-section
    margin: 2em 0