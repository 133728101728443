#contact
  p
    font-size: 1.5em
    line-height: 1.5
    display: inline-block
    width: calc(50% - 1.5em)
    margin: 0.25em 1.5em 0.25em 0
    padding: 1.5em
    vertical-align: top
    background: $color-lightest
    border-radius: $radius-border
    box-shadow: 0 0.33em 0 -0.15em rgba(black, 0.075)
    position: relative

    @media (max-width: $mdscreen)
      width: calc(100% - 0.5em)
      padding: 1.25em
      margin: 0.25em 0.25em 1.25em

    .text-or
      display: block
      color: $color-secondary
      width: 100%
      text-align: center

  form
    display: inline-block
    width: 50%
    vertical-align: top
    padding: 0 1em 1.5em 4em
    margin: 0 0 2.5em

    @media (max-width: $mdscreen)
      width: 100%
      padding: 0.5em 0.5em 4em

.contact-form
  input, textarea
    padding: 0.85em 1em
    
  input, textarea, .selection
    margin: 0.5em 0
    font-size: 1.075em

    @media (max-width: $mdscreen)
      font-size: 1.25em

    @media (max-width: $smscreen)
      font-size: 1.33em

  button
    font-size: 1em
    margin: 0.75em 0
    width: 100%

    &.btn-success, &.btn-success[disabled]
      color: white
      background: $color-success

    .icon
      margin: 0 0.5em 0 0
      position: relative
      top: 0.075em
      font-size: 1.15em
      line-height: 0.85

.ui.modal.help-modal
  max-width: 36em

  .header
    padding: 1.5em 1.5em 1em
    text-align: center
    font-size: 1.85em

  .content
    padding: 0.25em 2.25em 1.75em

    input, textarea, .selection
      font-size: 1.075em

      @media (max-width: $mdscreen)
        font-size: 1.25em

      @media (max-width: $smscreen)
        font-size: 1.367em
