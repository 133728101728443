#project-edit, #styleguide-edit, #showcase-edit, #persona-edit
  &.closed
    animation: hideFade $speed-normal forwards ease

  &.saved
    .item-toast
      animation: saveItem $speed-fast forwards ease

      @media (max-width: $smscreen)
        animation: saveItemSm $speed-fast forwards ease

  &.published
    .btn-publish
      pointer-events: none

      .publish-text
        font-size: 0

      .icon
        margin: 0
        transform-origin: center center
        animation: publishedBtnIcon $speed-slow $speed-faster forwards ease

    .section-edit-wrap:hover
      cursor: initial

  &.deleting
    pointer-events: none
    overflow: hidden
    position: fixed

  &.duplicating
    pointer-events: none
    overflow: hidden
    position: fixed

    &:before
      content: ""
      position: fixed
      top: 0
      left: 0
      height: 100%
      width: 100%
      background: rgba(black, 0.5)
      z-index: 10
      pointer-events: none

    .item-duplicating
      animation: showFade $speed-fast forwards ease

.project, .styleguide, .showcase, .persona
  &.dimmed
    #root
      filter: none

    .item-outer, .item-edit-nav
      // filter: blur(4px)

  .item-outer, .item-edit-nav
    transition: filter ease $speed-slow

  header, footer
    display: none

  div[attach]
    outline-color: rgba(white, 0)

  .content-outer
    min-height: 100vh
    width: 100%

  .content-wrap
    h1
      font-size: 2.75em

  .item-duplicating
    position: fixed
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    background: $color-lightest
    text-align: center
    display: flex
    align-items: center
    justify-content: center
    z-index: 10
    flex-flow: column wrap
    padding: 0 2.75em
    border-radius: $radius-btn
    box-shadow: 0 0.5em 1em rgba(black, 0.5)
    opacity: 0

    img
      min-height: 16em

    h3
      margin: -1em 0 1.5em
      font-family: "mulilight"
      animation: pulseFade $speed-slowest infinite ease 
      opacity: 1

  #item-form
    padding: 4em 1em
    transition: padding ease $speed-faster

    @media (max-width: $smscreen)
      padding: 0 1em 5.75em
      transition: none

  h2
    opacity: 1
    font-size: 2em
    font-family: "mulilight"
    color: $color-body-text
    margin: 0 0 1.5em
    padding: 0
    transform: none
    opacity: 1
    max-height: 4em
    line-height: 1.15

    @media (max-width: $smscreen)
      margin: 0 0 1em
      font-size: 1.75em

    &.hide
      opacity: 0
      font-size: 0

    &.with-help
      padding-right: 2em
      position: relative
      z-index: 3

    .header-help
      position: absolute
      right: -0.33em
      top: 10.5%
      padding: 0.33em
      font-size: 1rem
      line-height: 1em

      @media (max-width: $mdscreen)
        top: 0

      &:hover
        cursor: pointer

        .icon
          color: $color-primary

      .icon
        font-size: 1.25em
        color: $color-text-min
        transition: color ease $speed-faster
        pointer-events: none

        @media (max-width: $smscreen)
          font-size: 1em

  input, textarea
    font-size: 1em
    font-family: "mulilight"
    padding: 1.25em 1.5em
    background: rgba(white, 0.75)

    @media (max-width: $smscreen)
      padding: 1em 1.15em

    &:hover
      box-shadow: 0 0.25em 0.5em rgba(black, 0.15), 3px 0 0 $color-primary inset
      cursor: pointer

    &:focus
      box-shadow: 0 0.25em 0.5em rgba(black, 0.15), 5px 0 0 $color-accent inset
      cursor: default
      background: white

  textarea
    min-height: 4em
    resize: none

    @media (max-width: $smscreen)
      min-height: 5em

    @media (max-width: $xsscreen)
      min-height: 6.25em

  .item-outer
    position: relative
    height: 100%
    z-index: 1
    overflow: auto
    min-height: 100vh
    box-shadow: 0 0 3em rgba(black, 0.15)

  .item-edit-nav
    position: fixed
    text-align: center
    top: 0
    left: 0
    width: 100%
    z-index: 3
    opacity: 0
    transition: top ease $speed-normal $speed-normal
    animation: showFade $speed-normal $speed-normal forwards ease

    @media (max-width: $smscreen)
      font-size: 1.15em
      top: auto
      bottom: 0

    &.publish
      .item-nav-trigger 
        &:hover
          .icon, .item
            color: darken($color-success, 33%)

        .icon, .item
          color: white

        .icon
          opacity: 0.85

      .item-toast
        background: darken($color-success, 7.5%)

      .item-publish
        top: -3.75em
        animation: publishBtn ease $speed-fast $speed-slow forwards

        @media (max-width: $smscreen)
          top: 5em
          animation: publishBtnBottom ease $speed-fast $speed-fast forwards

      .btn-publish
        @media (max-width: $smscreen)
          opacity: 1

  .item-nav-btns
    display: flex
    flex-flow: row nowrap
    position: relative
    text-align: center
    z-index: 1
    height: 3.75em
    width: 100%
    background: rgba(white, 0.75)
    box-shadow: 0 0.175em 0 -0.075em rgba(black, 0.05)
    transition: background ease $speed-faster, box-shadow ease $speed-faster
    -webkit-backdrop-filter: blur(0.33em)

    @media (max-width: $smscreen)
      height: 5em
      background: rgba(white, 0.925)
      box-shadow: 0 -0.15em 0.33em rgba(black, 0.15)
      transition: none

      &:hover, &:focus, &:active
        z-index: 3
        box-shadow: 0 -0.15em 0.33em rgba(black, 0.15)

    &:hover
      background: rgba(white, 0.925)
      box-shadow: 0 0.33em 0.67em rgba(black, 0.15)

      .item-nav-trigger
        .icon, span
          opacity: 1

  .item-close, .item-menu
    flex: 1

    @media (max-width: $smscreen)
      top: 0.25em

    div.dropdown .menu
      transform: translateX(-1.67em)
      top: 100%

      @media (max-width: $smscreen)
        top: auto
        bottom: calc(100% - 0.25em)
        right: -0.275em

        &:before
          bottom: auto
          top: 100%
          transform: rotate(180deg)

      .menu-item
        text-align: left
        font-size: 0.925em
        margin-bottom: 0.25em

        @media (max-width: $smscreen)
          font-size: 1.15em

        &:last-child
          margin-bottom: 0

        &.item-delete
          margin-bottom: 0
          color: $color-accent-light

          &:hover
            color: lighten($color-alert, 10%)

        .icon
          font-size: 1.15em
          margin-right: 0.67em
          position: relative
          top: 0.1em
          line-height: 0.75

    .item-menu-admin
      padding-top: 0.75em
      margin-top: 0.75em
      border-top: 1px solid rgba(white, 0.25)

    .item-nav-trigger
      display: inline-block
      padding: 0.625em 2.25em 0.875em
      cursor: pointer
      opacity: 0
      animation: showFade ease $speed-fast $speed-slow forwards

      @media (max-width: $smscreen)
        padding: 1.33em 2em 1.425em

      &:hover
        .icon, span.item
          color: $color-primary
          opacity: 1

      .icon
        font-size: 2.25em
        opacity: 0.75

      .icon, span.item
        color: $color-body-text
        transition: opacity ease $speed-faster, color ease $speed-faster

        @media (max-width: $smscreen)
          opacity: 1
          transition: none

    span.item
      position: relative
      top: -0.67em
      padding: 0 1em
      opacity: 0

      @media (max-width: $smscreen)
        display: none

    .dropdown.icon
      display: none

  .btn-publish
    position: absolute
    width: 100%
    left: 0
    font-size: 1em
    padding: 0.85em 0.5em 1.15em
    top: 0
    height: 3.75em
    z-index: 0
    border-radius: 0
    box-shadow: 0 0.25em 0.5em rgba(black, 0.25)
    overflow: hidden
    background: $color-success

    @media (max-width: $smscreen)
      box-shadow: 0 0.5em 0.5em rgba(black, 0.15), 0 -0.25em 0.5em rgba(black, 0.075)
      transition: opacity ease $speed-faster
      opacity: 0
      height: 5em
      padding: 0.85em 0.5em 1.15em

    &:hover
      animation: publishBtnHover $speed-slow ease forwards

      .icon
        transform-origin: bottom left
        animation: publishBtnHoverIcon $speed-slow ease infinite

    &.unpublished
      animation: publishBtnBlink $speed-normal * 5 12 ease

      &:hover
        animation: none

    .btn-publish-text
      font-size: 1.15em

      .publish-text
        font-size: 1em
        transition: font ease $speed-faster

      .icon
        font-size: 1.15em
        margin-right: 0.67em
        position: relative
        top: 0.15em
        line-height: 0.75
        left: 0
        display: inline-block

  .publish-help
    position: absolute
    right: 1em
    top: 0.5em
    padding: 1em 0
    margin: 0 0.5em
    font-size: 0.925em
    z-index: 2
    color: white
    text-shadow: 0 1px 1px rgba(black, 0.15)
    cursor: pointer

    @media (max-width: $smscreen)
      font-size: 0.75em
      max-width: 4em
      top: 0.33em

  .item-publish
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%

  .item-close
    left: 0
    text-align: left

  .item-menu
    right: 0
    text-align: right

  .item-toast
    background: $color-success
    padding: 0.5em 1.5em 0.925em
    border-radius: 0 0 $radius-border $radius-border
    position: absolute
    top: 3.75em
    left: 0
    right: 0
    width: 12em
    box-shadow: 0 0.15em 0.25em rgba(black, 0.15)
    opacity: 0
    pointer-events: none
    margin: auto
    z-index: 2
    transform-origin: center top
    animation: saveItemHide $speed-normal forwards ease

    @media (max-width: $smscreen)
      top: -2.5em
      border-radius: $radius-border $radius-border 0 0
      transform-origin: center bottom
      animation: saveItemHideSm $speed-normal forwards ease

    p
      margin: 0
      padding: 0
      font-size: 0.925em
      color: white

      .icon
        margin-right: 0.5em
        font-size: 1.25em
        position: relative
        top: 0.175em

.styleguide
  .item-section > .text-content.editing
    margin: 1.5em auto 3em

  .component-section-wrap
    margin: 1.5em 0

  h2, h2.with-help
    margin-bottom: 0.5em

  .fonts-custom
    margin-top: 2.5em

.item-progress
  position: absolute
  top: 3.5em
  height: 0.25em
  width: 100%
  background: white
  z-index: 1
  box-shadow: 0 1px 2px rgba(black, 0.085)
  pointer-events: none

  @media (max-width: $smscreen)
    top: 0

  .progress-bar
    display: block
    height: 100%
    width: 0
    max-width: 100%
    background-color: $color-success
    transition: width ease $speed-normal $speed-fastest
    box-shadow: 0 1px 0 rgba(white, 0.33) inset
    border-radius: 0 $radius-border $radius-border 0


// STEP PROGRESS

.active-step-1
  #step-1
    animation: showFade $speed-normal $speed-fastest forwards ease
    display: block

.active-step-2
  #step-1
    display: block
    opacity: 1

  #step-2
    display: block
    animation: showFade $speed-normal $speed-fastest forwards ease

.active-step-3
  #step-1, #step-2
    display: block
    opacity: 1

  #step-3
    display: block
    animation: showFade $speed-normal $speed-fastest forwards ease

.active-step-4
  #step-1, #step-2, #step-3
    display: block
    opacity: 1

  #step-4
    display: block
    animation: showFade $speed-normal $speed-fastest forwards ease

.active-step-5
  #step-1, #step-2, #step-3, #step-4
    display: block
    opacity: 1

  #step-5
    display: block
    animation: showFade $speed-normal $speed-fastest forwards ease

    &.item-images
      animation: none
      opacity: 1

.active-step-6
  #step-1, #step-2, #step-3, #step-4, #step-5
    display: block
    opacity: 1

  #step-6
    display: block
    animation: showFade $speed-normal $speed-fastest forwards ease

.active-step-7
  #step-1, #step-2, #step-3, #step-4, #step-5, #step-6
    display: block
    opacity: 1

  #step-7
    display: block
    animation: showFade $speed-normal $speed-fastest forwards ease

.active-step-8
  #step-1, #step-2, #step-3, #step-4, #step-5, #step-6, #step-7
    display: block
    opacity: 1

  #step-8
    display: block
    animation: showFade $speed-normal $speed-fastest forwards ease

.active-step-9
  #step-1, #step-2, #step-3, #step-4, #step-5, #step-6, #step-7, #step-8, #step-9
    display: block
    opacity: 1

.step
  opacity: 0
  transform-origin: center top
  display: none

.done-step
  .component-section-wrap
    transition: none

.project .item-progress,
.projects .item-progress
  &.step-1 .progress-bar
    width: 0

  &.step-2 .progress-bar
    width: 14.28%

  &.step-3 .progress-bar
    width: 28.5%

  &.step-4 .progress-bar
    width: 42.85%

  &.step-5 .progress-bar
    width: 57.15%

  &.step-6 .progress-bar
    width: 71.42%

  &.step-7 .progress-bar
    width: 85.75%

  &.step-8 .progress-bar
    width: 100%
    border-radius: 0

  .active-step-8
    #item-form
      padding-top: 7.5em

      @media (max-width: $smscreen)
        padding-top: 0em
        padding-bottom: 12em

.styleguide .item-progress,
.styleguides .item-progress
  &.step-1 .progress-bar
    width: 0

  &.step-2 .progress-bar
    width: 12.5%

  &.step-3 .progress-bar
    width: 25%

  &.step-4 .progress-bar
    width: 37.5%

  &.step-5 .progress-bar
    width: 50%

  &.step-6 .progress-bar
    width: 62.5%

  &.step-7 .progress-bar
    width: 75%

  &.step-8 .progress-bar
    width: 87.5%
    border-radius: 0

  &.step-9 .progress-bar
    width: 100%
    border-radius: 0

  .active-step-9
    #item-form
      padding-top: 7.5em

      @media (max-width: $smscreen)
        padding-top: 0em
        padding-bottom: 12em

.showcase .item-progress,
.showcases .item-progress,
.persona .item-progress,
.personas .item-progress
  &.step-1 .progress-bar
    width: 0

  &.step-2 .progress-bar
    width: 20%

  &.step-3 .progress-bar
    width: 40%

  &.step-4 .progress-bar
    width: 60%

  &.step-5 .progress-bar
    width: 80%

  &.step-6 .progress-bar
    width: 100%
    border-radius: 0

  .active-step-6
    #item-form
      padding-top: 7.5em

      @media (max-width: $smscreen)
        padding-top: 0em
        padding-bottom: 12em

.component-section-wrap
  padding: 7.5em 1.5em 3em
  margin: 3.5em 0 2.5em
  min-height: 5em
  position: relative
  transition: padding-top ease $speed-faster

  @media (max-width: $smscreen)
    transition: none
    padding: 3em 0

  &:before
    content: ""
    position: absolute
    top: 0
    left: -1em
    height: calc(100% - 3.05em)
    width: calc(100% + 2em)
    background: lighten($color-lighter, 2%)
    z-index: -1
    box-shadow: 0 -0.15em 0.5em rgba(black, 0.075)
    transition: background ease $speed-faster

  &.done
    margin: 1em 0
    padding: 1em
    filter: none

    &:before
      display: none

    @media (max-width: $smscreen)
      margin: 0
      padding: 1em 0

    &.none-selected
      .section-empty
        height: auto
        margin: 1em
        opacity: 1
        transition: opacity ease $speed-slow $speed-faster

        @media (max-width: $smscreen)
          transition: none

    .active > h2, h2.with-help
      transform: scaleY(0)
      max-height: 0
      margin: 0
      opacity: 0

    .section-btn-done
      display: none

      .btn
        transform: scaleY(0)
        opacity: 0

    .section-edit-wrap
      pointer-events: initial

      &:before
        transition: opacity ease $speed-faster, transform ease $speed-faster

        @media (max-width: $smscreen)
          transition: none

      &:hover
        cursor: url('../images/cursor-edit-md.svg'), auto

        &:before
          opacity: 1
          transform: scaleY(1)

        .icon
          animation: editIconShow $speed-fastest ease forwards
            
      .icon
        animation: editIconHide $speed-faster ease forwards

        @media (max-width: $bigscreen)
          display: none

    .cards-list
      margin: 0 -0.5em

      .card
        display: none

        &.selected
          display: block
          width: auto
          flex: 1
          margin-top: 0.5em
          margin-bottom: 0.5em

          @media (max-width: $smscreen)
            min-width: calc(50% - 1em)

          &:before, &:after
            opacity: 0

    .card-delete, .card-delete-wrap
      @media (max-width: $mdscreen)
        display: none

    .card-selected-wrap, .card-delete-wrap
      display: none

    .text-content
      margin: 0

      .text-content-edit
        display: none

.component-section-inner
  max-width: 60rem
  margin: 0 auto
  position: relative
  z-index: 1

  @media (min-width: $hugescreen)
    max-width: 64rem

  @media (min-width: $epicscreen)
    max-width: 70rem

  &.sorting, &.deleting, .sorting, .deleting
    .cards-list .card
      transition: inherit

      &.selected
        &:before, &:after
          animation-duration: 0s

        .card-delete
          animation: showCardInactiveRight 0s forwards ease

        .card-expand, .card-download
          animation: showCardInactiveLeft 0s forwards ease

        .card-reorder
          animation: showCardInactiveMiddle 0s forwards ease
      
      *
        pointer-events: none

      .file-img-bg, .file-icon, .fonts-custom-content, .file-img-preview
        animation-duration: 0s
        animation-delay: 0s
        transition-duration: 0s
        transition-delay: 0s
        opacity: 1

  &.reorder
    .cards-list .card.selected
      animation-delay: 0s
      animation-duration: 0s
      opacity: 1

      &, &:hover
        .card-delete, .card-delete-wrap,
        .card-download, .card-download-wrap,
        .card-reorder, .card-reorder-wrap
          animation-delay: 0s
          animation-duration: 0s
          opacity: 1

.section-btn-done
  display: block
  text-align: right
  width: calc(100% + 5em)
  margin: 3.5em -2.5em 0

  @media (max-width: $smscreen)
    text-align: center
    width: calc(100% + 2em)
    margin: 3.5em -1em 0

  .btn
    transform: none
    width: 100%
    background: lighten($color-lighter, 2%)
    border-radius: 0
    padding: 0 3em
    box-shadow: none
    filter: drop-shadow(0 0.375em 0.2em rgba(black, 0.085))
    text-align: center
    display: flex
    align-items: center
    justify-content: center

    &:hover
      background: $color-success

      .icon
        background: $color-success
        color: white

    .icon
      font-size: 3em
      padding: 0.285em 0.33em 0.33em
      background: lighten($color-lighter, 2%)
      color: darken($color-success, 10%)
      text-shadow: 0 2px 2px rgba(black, 0.15)
      margin-bottom: -0.425em
      display: inline-flex
      border-radius: 100%
      line-height: 1
      height: 1.615em
      width: 1.615em
      transition: background ease $speed-faster, color ease $speed-faster

      @media (max-width: $smscreen)
        transition: none

.section-edit-wrap
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  pointer-events: none

  @media (max-width: $smscreen)
    opacity: 0
    z-index: 4

  &:before
    content: ""
    position: absolute
    top: 0
    left: -1em
    height: 100%
    width: calc(100% + 2em)
    background: rgba(black, 0.085)
    opacity: 0
    z-index: -1
    transform: scaleY(0.85)
    transition: none

    @media (max-width: $mdscreen)
      width: 100%
      left: 0

  .section-edit-icon
    display: block
    position: relative
    height: 100%

  .icon
    padding: 0.5em
    top: 0
    bottom: 0
    background: rgba(white, 0.33)
    left: 100%
    margin: auto
    height: 3em
    width: 3em
    line-height: 2em
    position: absolute
    text-align: center
    color: $color-dark
    text-shadow: 0 1px 0 white
    font-size: 1em
    border-radius: 50%
    opacity: 0
    transform: scale(1)
    transition: none

    @media (max-width: $lgscreen)
      display: none
      

// CARDS

.cards-list
  margin: 1.5em -0.5em
  padding: 0
  display: flex
  flex-flow: row wrap
  align-items: stretch
  font-family: "muliregular"

  &.sortable
    overflow: auto

    &.editing
      overflow: visible

  .card
    list-style: none
    margin: 0.5em
    padding: 1.5em
    background-color: white
    width: calc(25% - 1em)
    border-radius: $radius-btn
    box-shadow: 0 0.525em 0 -0.2em rgba(black, 0.075)
    transition: box-shadow ease $speed-faster, background ease $speed-faster, filter ease $speed-faster
    opacity: 0
    cursor: pointer
    position: relative
    overflow: hidden
    animation: showItem $speed-slow forwards ease
    animation-delay: $speed-fastest

    @media(max-width: $mdscreen)
      width: calc(33.333% - 1em)

    @media(max-width: $smscreen)
      width: calc(50% - 1em)
      margin: 0.5em
      transition: none

    @media (max-width: $xsscreen)
      padding: 1.5em 1em

    &:hover
      box-shadow: 0 0.415em 0.67em rgba(black, 0.2)

    &:active
      box-shadow: 0 0.075em 0 rgba(black, 0.15)

    &.selected
      box-shadow: 0 0.25em 0 -0.125em rgba(black, 0.075)
      background-color: $color-lightest
      animation-delay: 0s

      &:before, &:after
        position: absolute
        pointer-events: none
        z-index: 1

      &:before
        content: ""
        top: 0
        right: 0
        border-style: solid
        border-width: 1.75em
        border-color: $color-success $color-success transparent transparent
        transform: translate3d(-2em, -2em, 0)
        transition: border-color ease $speed-faster
        animation: showCardActiveRight $speed-faster forwards ease
        filter: drop-shadow(0 0 0.15em rgba(black, 0.33))
        border-radius: 0 $radius-border 0 0

        // @media (max-width: $smscreen)
        //   transition: none
        //   animation: none
        //   transform: none

      &:after
        content: "\e934"
        font-family: "icomoon"
        top: 0.5em
        right: 0.5em
        color: white
        font-weight: 600
        transform: scale(0.75)
        animation: showCardActiveCheck $speed-slow forwards ease

      &.new
        opacity: 0
        animation: showItem $speed-slow forwards ease

    &.loading
      .file-icon .icon, .file-img .icon
        filter: blur(1px)
        opacity: 0.5

    &.deleted.selected, &.deleted.selected.new
      animation: deleteCard $speed-fast forwards ease

      &:before, &:after
        opacity: 0

    .card-selected-wrap
      border-radius: 0 $radius-btn 0 0

.item-logos, .item-fonts, .item-icons, .item-screens
  .cards-list .card.deleted.selected
    animation: deleteCardVert $speed-fast forwards ease

  .card-delete-wrap, .card-download-wrap, .card-reorder-wrap
    animation: showFade $speed-faster $speed-faster forwards ease
    pointer-events: initial

.empty
  .card-selected-wrap
    opacity: 0
    animation: showFade $speed-faster $speed-faster forwards ease

.selected
  .card-selected-wrap .icon
    animation: showCardActiveCheck $speed-slow forwards ease

.section-empty
  margin: 0
  color: $color-text-min
  font-size: 1.5em
  font-family: "muliextralight"
  text-align: center
  height: 0
  opacity: 0
  transition: none
  pointer-events: none

.card-selected-wrap, .card-delete-wrap
  position: absolute
  top: 0
  right: 0
  height: 3.67em
  width: 3.67em
  overflow: hidden
  border-radius: 0 $radius-border 0 0

.card-delete-wrap, .card-download-wrap, .card-reorder-wrap
  opacity: 0
  pointer-events: none
  z-index: 2

  @media (max-width: $smscreen)
    transition: none

.card-selected, .card-delete, .card-expand, .card-edit, .card-edit-item, .card-clear, .card-download, .card-link
  position: absolute
  top: 0
  height: 0
  width: 0
  z-index: 2
  cursor: pointer

  &:before
    content: ""
    position: absolute
    background-color: white
    height: 5em
    width: 5em
    top: -2.5em
    left: -2.5em
    background-color: white
    transform: rotate(45deg)
    transform-origin: center center
    box-shadow: 0 0 0 0.115em rgba(black, 0.085)
    transition: box-shadow ease $speed-faster, background ease $speed-faster

  &:hover
    &:before
      box-shadow: 0.15em 0 0.33em 0.075em rgba(black, 0.15)

  .icon
    top: 0.5em
    right: 0.5em
    color: white
    position: absolute

.card-selected
  animation: showCardInactiveRight $speed-faster forwards ease
  right: 0
  cursor: default

  @media (max-width: $mdscreen)
    transform: none
    transition: none
    animation: none

  &:before
    background-color: $color-success

    @media (max-width: $smscreen)
      transition: none

  .icon
    font-weight: 600

.card-delete
  animation: showCardInactiveRight $speed-faster forwards ease
  right: 0

  @media (max-width: $mdscreen)
    transform: none
    transition: none
    animation: none

  &:before
    background-color: $color-alert

    @media (max-width: $smscreen)
      transition: none

  &:hover:before
    background-color: darken($color-alert, 10%)

.card-link
  right: 0
  animation: showCardInactiveRight $speed-faster forwards ease

  &:hover:before
    background-color: $color-lightest

  &:before
    background-color: white

    @media (max-width: $smscreen)
      transition: none

  .icon
    color: $color-body-text
    left: auto
    right: 0.5em

.card-close
  animation: showCardInactiveLeft $speed-faster forwards ease

.card-expand, .card-download
  animation: showCardInactiveLeft $speed-faster forwards ease
  left: 0

  @media (max-width: $smscreen)
    // display: none

  &:hover:before
    background-color: $color-lightest

  &:before
    @media (max-width: $smscreen)
      transition: none

  .icon
    color: $color-body-text
    right: auto
    left: 0.5em

.card-edit, .card-clear
  left: 0
  animation: showCardInactiveLeft $speed-faster forwards ease

  &:hover:before
    background-color: $color-lightest

  &:before
    background-color: white

    @media (max-width: $smscreen)
      transition: none

  .icon
    color: $color-body-text
    right: auto
    left: 0.5em

.card-reorder
  position: absolute
  width: 3.5em
  height: 2.5em
  background: white
  border-radius: 0 0 $radius-btn $radius-btn
  left: 0
  right: 0
  top: 0
  margin: auto
  box-shadow: 0 0 0 0.115em rgba(black, 0.085)
  animation: showCardInactiveMiddle $speed-faster forwards ease
  transition: background ease $speed-faster
  cursor: pointer

  &:hover
    background: $color-lightest

  .icon
    font-size: 1.25em

.card-reorder
  &.ui.menu .dropdown
    height: 100%
    width: 100%

    .menu
      margin-top: 0.33em
      padding: 0.5em 1em 0.75em 0.75em

      .link
        text-align: left
        font-size: 0.85em

        .icon
          margin-right: 0.5em
          display: inline-block

  .card-reorder-trigger
    height: 100%
    width: 100%
    display: inline-flex
    align-items: center
    justify-content: center

  .reorder-down .icon
    transform: rotate(-90deg)

  .reorder-up .icon
    transform: rotate(90deg)

.item-drag.button-drag
  background: transparent
  box-shadow: none
  width: 100%
  overflow: visible
  font-size: 1em

  // @import '../reset'

  .btn-group
    display: flex
    flex-flow: row wrap
    width: 100%
    position: relative

    > button
      margin: 0.5em 1em
      flex: calc(25% - 2em)
      max-width: calc(25% - 2em)
      line-height: initial

      @media (max-width: $mdscreen)
        flex: calc(50% - 2em)
        max-width: calc(50% - 2em)

      @media (max-width: $smscreen)
        flex: calc(100% - 2em)
        max-width: calc(100% - 2em)

  .element-button-drag
    display: block

  .element-button-edit
    visibility: hidden


// ITEM NAME

.item-name
  .component-section-wrap
    margin: 0
    padding: 3em 1.5em

    @media (max-width: $smscreen)
      padding: 3em 0

    &.done
      margin: 0
      padding: 1em 0 0

      @media (max-width: $smscreen)
        padding: 1em 0 0

    &.none-entered
      padding: 3em 1em

      @media (max-width: $smscreen)
        padding: 3em 0

      &:before, .section-btn-done
        display: none

  #item-name-input
    max-width: 100vw
    background: none
    box-shadow: none
    font-family: "muliextralight"
    padding: 0.5em 0.66em
    margin: 0.5em -0.66em
    width: calc(100% + 1.33em)
    font-size: 3em
    opacity: 0
    animation: showFade $speed-normal ease forwards

    @media (max-width: $lgscreen)
      width: calc(100% + 0.5rem)
      padding: 0.5em
      margin: 0.5em -0.25rem 0.33em

    @media (max-width: $mdscreen)
      width: calc(100% + 0.5rem)
      margin: 0.5em -0.25rem 0.33em

    @media (max-width: $smscreen)
      border-width: 0
      font-size: 2.25em

    @media (max-width: $xsscreen)
      font-size: 2em

    &:hover
      box-shadow: 0 0.25em 0.5em rgba(black, 0.15), 3px 0 0 $color-primary inset
      background: rgba(white, 0.25)

    &:focus
      box-shadow: 0 0.25em 0.5em rgba(black, 0.15), 5px 0 0 $color-accent inset
      background: rgba(white, 0.67)

  .section-btn-done
    margin-top: 1em


// PUBLISH BAR

.item-publish-bar
  text-align: center
  position: fixed
  bottom: 0
  left: 0
  width: 100%
  min-height: 0
  background: rgba($color-lightest, 0.875)
  padding: 1em 0
  margin: 0
  min-height: 0
  box-shadow: 0 -0.075em 0.125em rgba(black, 0.075)

  &, &.done
    margin: 0

  &:before
    display: none

  .btn
    width: 100%
    font-size: 1.15em
        

// MODALS

.ui.modals 
  .demo-modal
    max-width: 30rem
    min-height: 26rem

    h2
      font-size: 2em

  #form-signup
    background: transparent
    padding: 0 3em
    box-shadow: none

    @media (max-width: $smscreen)
      padding: 0 2em

    @media (max-width: $xsscreen)
      padding: 0 1em

    .login-google
      margin-top: 1.5em

.ui.modals .item-deleted-modal,
.ui.modals .item-removed-modal,
.ui.modals .item-archived-modal,
.ui.modals .item-denied-modal,
.ui.modals .item-editing-modal
  text-align: center
  padding: 1.25em 2.25em 1em

  @media (max-width: $smscreen)
    padding: 1.25em 2em 1em

  h1, .header
    color: $color-alert

  p
    margin: 1em 0
    font-size: 1.15em
    line-height: 1.25

  button
    margin: 1.5em auto 1em

.ui.modals .add-user-modal.modal
  max-width: 40em

  &.owner-change
    .owner
      background: rgba(white, 0.33)

    .user
      opacity: 0
      animation: showFade $speed-normal forwards ease
      
  &.remove-user
    .header, .content
      // filter: blur(3px)

  &.removing-user
    .user
      opacity: 1
      animation-duration: 0s
      animation-delay: 0s

  h3
    text-align: center
    font-size: 1em
    margin: 1em 0 1.5em

  h4
    font-size: 1.15em
    font-family: "mulilight"
    margin: 0

  .header, .content
    transition: filter ease $speed-faster

  .header
    text-align: center
    font-size: 2.25em
    padding: 0.85em 1.5em 0.125em
    text-transform: capitalize

    @media (max-width: $smscreen)
      font-size: 2em

    > span
      font-family: "muliextralight"

  .content
    padding: 0 2.5em 1.5em

    @media (max-width: $smscreen)
      padding: 0 1.5em 1.5em

  .user-search-container
    margin: 0 0 2.25em
    display: inline-block
    width: 100%
    position: relative

    input
      font-size: 1.15em
      padding: 1em 3.75em 1em 1.5em

      @media (max-width: $mdscreen)
        font-size: 1.33em

      @media (max-width: $smscreen)
        padding: 1em 1.15em

  .user-search-results
    position: absolute
    z-index: 1
    width: auto
    height: 100%
    top: 0
    right: 0

    &.user-found
      top: calc(4em - 1px)
      width: 100%

    .user-add
      top: 0
      font-size: 1em
      animation: showFade $speed-faster forwards ease
      
    .user
      margin: 0
      box-shadow: 0 0.15em 0.33em rgba(black, 0.15)
      border-radius: 0
      background: white
      animation: showFade $speed-faster forwards ease

      .inline-loading
        margin-top: 0.25em

  .users
    max-height: calc(50vh - 1.5em)
    overflow-y: auto
    // padding-right: 0.85em
    margin-bottom: 0.5em
    opacity: 0
    position: relative
    animation: showFade $speed-normal $speed-normal forwards ease

    &::-webkit-scrollbar
      width: 3px

    &::-webkit-scrollbar-track
      background: $color-lightest

    &::-webkit-scrollbar-thumb
      background: darken($color-light, 15%)

  .users-pending
    margin-top: 1.75em

  .remove-confirm-wrap
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%
    margin: 0
    background: rgba($color-lighter, 0.85)
    text-align: center
    opacity: 0
    animation: showFade $speed-normal forwards ease

    @media (max-width: $mdscreen)
      width: 75%
      margin: 0 12.5%

    @media (max-width: $smscreen)
      width: 100%
      margin: 0

    .remove-confirm-content
      position: absolute
      left: 0
      width: 100%
      padding: 2.5em 4.25em
      top: 50%
      transform: translateY(-50%)

      @media (max-width: $smscreen)
        padding: 2em 2.5em

      h4
        font-size: 2em
        margin-bottom: 2em
        color: $color-alert
        word-break: break-word

      button
        display: block
        margin: 0.5em auto

        &.btn-text
          margin-top: 1em

  .user
    display: flex
    flex-flow: row nowrap
    width: 100%
    margin: 0.85em 0
    background: $color-lightest
    border-radius: $radius-border
    align-items: stretch
    overflow: hidden
    position: relative
    opacity: 0
    animation: showFadeHeight $speed-normal $speed-faster forwards ease

    &.user-add-email, &.user-search-email
      p, a, .link
        font-size: 1.15em
        margin: 1em 0
        line-height: 1em

      p
        display: block

  .user-profile
    padding: 0.5em 1em

    @media (max-width: $smscreen)
      padding-right: 0.33em
      padding-left: 0.75em

    .profile-img-wrap
      font-size: 0.33em
      cursor: default
      border-width: 0.33em
      margin: 0.15em auto

      @media (max-width: $smscreen)
        margin-top: 0.075em

      &:hover
        border-color: white

  .user-info
    flex: 1
    padding: 0.425em 0.75em 0.33em
    overflow: hidden
    text-overflow: ellipsis
    margin-right: 3.67em

    @media (max-width: $mdscreen)
      padding: 0.75em

    @media (max-width: $smscreen)
      margin-right: 3.5em

    p
      margin: 0.5em 0 0.33em
      font-size: 1.15em

      @media (max-width: $smscreen)
        font-size: 1.215em
        margin: 0.15em 0 0.375em

      .user-admin
        margin-left: 0.75em
        font-size: 0.75em
        opacity: 0.75
        position: relative
        top: -0.075em

    a, .link
      font-size: 0.925em
      display: inline-block

      @media (max-width: $smscreen)
        font-size: 1.075em

  .user-add, .user-remove, .owner-change, .owner-select
    position: absolute
    right: 0
    top: 0
    height: 100%
    background: white
    transition: background ease $speed-faster
    cursor: pointer
    padding: 0 0.85em

    .icon
      transition: color ease $speed-faster
      display: block
      position: relative
      font-size: 1.5em
      height: 1em
      top: 50%
      position: relative
      transform: translateY(-50%)

    .inline-loading
      width: 1.5em

  .user-add .icon, .user-remove .icon, .owner-change .icon, .owner-select .icon
    transition: color ease $speed-faster

  .user-add
    padding: 0 1em

  .user-add, .owner-select
    &:hover, &.selected
      background: $color-success

      .icon
        color: white

    .icon
      color: $color-success 

  .user-remove
    &:hover
      background: $color-alert

      .icon
        color: white

    .icon
      color: $color-alert

  .owner-change
    &:hover
      background: $color-primary

      .icon
        color: white

    .icon
      color: $color-primary-dark

  .owner-btns
    display: block
    text-align: center
    margin: 2em auto 0
    font-size: 0.85em
    opacity: 0
    animation: showFade $speed-normal forwards ease

    .btn
      display: block
      margin: 0.25em auto

    .btn-text
      margin-top: 1em

  .user-code
    color: $color-alert
    font-size: 0.825em
    line-height: 1.33
    padding: 1.5em 1.75em
    margin: 0
    text-align: center
    position: absolute
    top: 50%
    transform: translateY(-50%)
    background: white
    right: 0

    @media (max-width: $mdscreen)
      padding: 1.5em 1em


// QUILL

.quill
  @import 'quill'


// SECTION IMPORTS

@import 'edit/item-adjectives'
@import 'edit/item-palette'
@import 'edit/item-fonts'
@import 'edit/item-images'
@import 'edit/item-audience-details'
@import 'edit/item-files'
@import 'edit/item-logos'
@import 'edit/item-icons'
@import 'edit/item-elements'
@import 'edit/item-screens'
