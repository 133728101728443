+keyframes(showFade)
  0%
    opacity: 0

  100%
    opacity: 1

+keyframes(showFadeNext)
  0%
    opacity: 0

  100%
    opacity: 1

+keyframes(showFadeHeight)
  0%
    opacity: 0
    transform: scaleY(0.5)

  100%
    opacity: 1
    transform: none

+keyframes(showFadeHeightFlip)
  0%
    opacity: 0
    transform: scaleY(-0.5)

  100%
    opacity: 1
    transform: none

+keyframes(showFadeZero)
  0%
    opacity: 0
    transform: scale(0.5) translateY(-5em)

  50%
    opacity: 1
    transform: scale(0.75)

  100%
    opacity: 1
    transform: scale(1)

+keyframes(hideFadeZero)
  0%
    opacity: 1
    transform: scale(1)

  50%
    opacity: 1
    transform: scale(0.75)

  100%
    opacity: 0
    transform: scale(0.5) translateY(-5em)

+keyframes(hideFade)
  0%
    opacity: 1

  100%
    opacity: 0

+keyframes(dimFade)
  0%
    opacity: 1
    filter: none

  100%
    opacity: 0.25
    filter: saturate(0.15)

+keyframes(pulseFade)
  0%
    opacity: 1

  50%
    opacity: 0.25

  100%
    opacity: 1

+keyframes(hideHeaderBefore)
  0%
    opacity: 0.95
    transform: skewY(1deg)

  100%
    opacity: 0
    transform: translateY(-2.5vh) translateX(-5vw) scale(1.25)

+keyframes(hideHeaderAfter)
  0%
    opacity: 0.825
    transform: skewY(-1deg)

  100%
    opacity: 0
    transform: translateY(-2.5vh) translateX(5vw) scale(1.25)

+keyframes(showHeaderBefore)
  0%
    opacity: 0
    transform: translateY(-2.5vh) translateX(-5vw) scale(1.25)

  100%
    opacity: 0.95
    transform: skewY(1deg)

+keyframes(showHeaderAfter)
  0%
    opacity: 0
    transform: translateY(-2.5vh) translateX(5vw) scale(1.25)

  100%
    opacity: 0.825
    transform: skewY(-1deg)

+keyframes(showContentFade)
  0%
    opacity: 0
    transform: scale(0.936)

  100%
    opacity: 1
    transform: none

+keyframes(hideContentFade)
  0%
    opacity: 1
    transform: none

  100%
    opacity: 0
    transform: scale(0.936)
    
+keyframes(showContentFadeUp)
  0%
    opacity: 0
    transform: scale(0.936) translateY(1.5em)

  100%
    opacity: 1
    transform: none

+keyframes(showContentFadeMid)
  0%
    opacity: 0
    transform: scale(1.0425)

  100%
    opacity: 1
    transform: none

+keyframes(showContentFadeMidUp)
  0%
    opacity: 0
    transform: scale(1.0425) translateY(4em)

  100%
    opacity: 1
    transform: none

+keyframes(showBodyFadeMid)
  0%
    opacity: 0
    transform: scale(1.0425)

  50%
    opacity: 0
    transform: scale(1.0425)

  100%
    opacity: 1
    transform: none

+keyframes(showContentFadeBig)
  0%
    opacity: 0
    transform: scale(1.5)

  100%
    opacity: 1
    transform: none

+keyframes(showContentUp)
  0%
    transform: translateY(100%)

  100%
    transform: none

+keyframes(hideContentUp)
  0%
    transform: none

  100%
    transform: translateY(-100%)

+keyframes(showContentSpin)
  0%
    transform: none
    opacity: 0

  100%
    transform: rotate3d(0, 1, 0, 180deg)
    opacity: 1

+keyframes(hideContentSpin)
  0%
    transform: none
    opacity: 1

  100%
    transform: rotate3d(0, 1, 0, 180deg)
    opacity: 0

+keyframes(showCardActiveLeft)
  0%
    transform: translate3d(-2em,-2em,0)

  100%
    transform: translate3d(0,0,0)

+keyframes(showCardActiveRight)
  0%
    transform: translate3d(2em,-2em,0)

  100%
    transform: translate3d(0,0,0)

+keyframes(showCardActiveMiddle)
  0%
    transform: translate3d(0,-2.75em,0)

  100%
    transform: translate3d(0,0,0)

+keyframes(showCardInactiveLeft)
  0%
    transform: translate3d(0,0,0)

  100%
    transform: translate3d(-2em,-2em,0)

+keyframes(showCardInactiveRight)
  0%
    transform: translate3d(0,0,0)

  100%
    transform: translate3d(2em,-2em,0)

+keyframes(showCardInactiveMiddle)
  0%
    transform: translate3d(0,0,0)

  100%
    transform: translate3d(0,-2.75em,0)

+keyframes(showCardActiveCheck)
  0%
    transform: scale(0.75)

  50%
    transform: scale(1.5)

  100%
    transform: none

+keyframes(showItem)
  0%
    opacity: 0
    transform: scale(0.95)

  100%
    opacity: 1
    transform: none

+keyframes(showAboutText)
  0%
    opacity: 0
    transform: scale(0.85) translateY(-1.5em)

  100%
    opacity: 1
    transform: none

+keyframes(showAboutImgLeft)
  0%
    opacity: 0
    transform: translateX(4em)

  100%
    opacity: 1
    transform: none

+keyframes(showAboutImgRight)
  0%
    opacity: 0
    transform: translateX(-4em)

  100%
    opacity: 1
    transform: none

+keyframes(showModal)
  0%
    opacity: 0
    transform: scale(0.85)

  100%
    opacity: 1
    transform: none


+keyframes(saveItem)
  0%
    opacity: 0
    transform: perspective(3em) rotateX(-90deg)

  50%
    opacity: 1

  100%
    opacity: 1
    transform: none

+keyframes(saveItemSm)
  0%
    opacity: 0
    transform: perspective(3em) rotateX(90deg)

  50%
    opacity: 1

  100%
    opacity: 1
    transform: none

+keyframes(saveItemHide)
  0%
    opacity: 1
    transform: none

  100%
    opacity: 0
    transform: perspective(3em) rotateX(-90deg)

+keyframes(saveItemHideSm)
  0%
    opacity: 1
    transform: none

  100%
    opacity: 0
    transform: perspective(3em) rotateX(90deg)

+keyframes(showDropIcon)
  0%
    opacity: 0
    transform: scale(3)

  100%
    opacity: 0.5
    transform: none

+keyframes(hideCard)
  0%
    opacity: 1
    transform: scale(1)

  100%
    opacity: 0
    width: 0
    height: 0
    transform: scale(0)
    padding: 0
    margin: 0

+keyframes(showCard)
  0%
    opacity: 0
    width: 0
    height: 0
    transform: scale(0)
    padding-top: 0
    padding-bottom: 0
    margin-top: 0
    margin-bottom: 0

  100%
    opacity: 1
    transform: scale(1)

+keyframes(deleteCard)
  0%
    opacity: 1
    transform: scale(1)

  100%
    opacity: 0
    transform: scale(0)
    min-width: 0
    width: 0
    height: 0
    padding: 0
    margin: 0

+keyframes(deleteCardVert)
  0%
    opacity: 1
    transform: scale(1,1)
    transform-origin: top center

  100%
    opacity: 0
    height: 0
    padding: 0
    margin: 0
    opacity: 0
    transform: scale(1,0)

+keyframes(showImageCount)
  0%
    opacity: 0
    transform: translateY(2em)

  100%
    opacity: 1
    transform: translateY(0)

+keyframes(publishBtn)
  0%
    top: -3.75em

  100%
    top: 0

+keyframes(publishBtnBottom)
  0%
    top: 4.5em

  100%
    top: 0

+keyframes(publishBtnHover)
  0%
    background: $color-success

  100%
    background: adjust-hue($color-success, 15deg)

+keyframes(publishBtnHoverIcon)
  0%
    transform: none

  33%
    transform: translateY(-0.25em) translateX(0.25em)

  67%
    transform: translateY(0.15em) translateX(-0.15em)

  100%
    transform: none

+keyframes(publishBtnBlink)
  0%
    background: $color-success

  50%
    background: lighten($color-success, 10%)

  100%
    background: $color-success

+keyframes(publishedBtnIcon)
  0%
    transform: none

  100%
    transform: translateY(-3em) translateX(1.5em) scale(1.5)

+keyframes(demoBtnDone)
  50%
    background: $color-success
    color: white
    transform: scale(1)

  100%
    transform: scale(0)

+keyframes(showDemoPublishEdit)
  0%
    transform: none
    opacity: 1

  100%
    transform: translateY(-12em) scale(0.85) perspective(1.5em) rotateX(-0.5deg)
    opacity: 0

+keyframes(showDemoPublish)
  0%
    transform: translateY(12em) scale(0.85) perspective(1.5em) rotateX(0.5deg)
    opacity: 0

  100%
    transform: none
    opacity: 1


+keyframes(showNotification)
  0%
    opacity: 0
    transform: translateY(3em) scale(0.95) perspective(1.5em) rotateX(-4deg)

  67%
    opacity: 1
    transform: translateY(-0.67em) scale(1)

  100%
    opacity: 1
    transform: translateY(0) scale(1)

+keyframes(showSlideDown)
  0%
    transform: translateY(-1.5em)
    opacity: 0

  100%
    transform: none
    opacity: 1

+keyframes(showSlideDownSm)
  0%
    transform: translateY(-0.5em)
    opacity: 0

  100%
    transform: none
    opacity: 1

+keyframes(showSlideUp)
  0%
    transform: translateY(1.5em)
    opacity: 0

  100%
    transform: none
    opacity: 1

+keyframes(hideSlideUp)
  0%
    transform: none
    opacity: 1

  100%
    transform: translateY(-1.5em)
    opacity: 0

+keyframes(editIconHide)
  0%
    opacity: 0
    left: 100%

  100%
    opacity: 1
    left: 102%

+keyframes(editIconShow)
  0%
    opacity: 1
    left: 102%

  100%
    opacity: 0
    left: 100%

+keyframes(showHeader)
  0%
    opacity: 0
    transform: translateY(-$height-header)

  100%
    opacity: 1
    transform: translateY(-1em)

+keyframes(hideHeader)
  0%
    transform: translateY(-1em)

  100%
    transform: translateY(-$height-header)

+keyframes(showPopUp)
  0%
    top: -1em
    opacity: 0

  100%
    top: 0
    opacity: 1

+keyframes(showPopDown)
  0%
    top: 1em
    opacity: 0

  100%
    top: 0
    opacity: 1

+keyframes(showActiveNav)
  0%
    transform: translateY(1em)
    opacity: 0

  100%
    transform: none
    opacity: 0.75

+keyframes(inputBlink)
  0%
    opacity: 0

  50%
    opacity: 0

  51%
    opacity: 1

  100%
    opacity: 1

+keyframes(spinAround)
  0%
    transform: rotate(0) scale(1)

  100%
    transform: rotate(90deg) scale(1.15)

+keyframes(spinBack)
  0%
    transform: rotate(90deg) scale(1.15)

  100%
    transform: rotate(0) scale(1)

+keyframes(drawRight)
  0%
    transform: scaleX(0) skewX(-36deg)
    opacity: 0

  100%
    transform: scaleX(1) skewX(-10deg) skewY(-0.33deg)
    opacity: 1

+keyframes(drawRightHide)
  0%
    transform: scaleX(1) skewX(-10deg) skewY(-0.33deg)
    opacity: 1

  100%
    transform: scaleX(0) skewX(-36deg)
    opacity: 0


// Home Thumbs

+keyframes(showThumbProject)
  0%
   transform: scale(1)

  33%
    transform: scale(1) translateX(-50%)
    z-index: 2

  100%
    transform: scale(1.5)
    z-index: 2

+keyframes(showThumbProjectAlt)
  0%
   transform: scale(1)

  33%
    transform: scale(1) translateX(25%)
    z-index: 2

  100%
    transform: scale(1.5)
    z-index: 2

+keyframes(hideThumbProject)
  0%
   transform: scale(1.5)
   z-index: 2

  33%
    transform: scale(1) translateX(50%)
    z-index: 1

  100%
    transform: scale(1)
    z-index: 1

+keyframes(hideThumbProjectAlt)
  0%
   transform: scale(1.5)
   z-index: 2

  33%
    transform: scale(1) translateX(-25%)
    z-index: 1

  100%
    transform: scale(1)
    z-index: 1

+keyframes(showThumbStyleguide)
  0%
   transform: scale(1)
   z-index: 1

  33%
    transform: scale(1) translateX(-50%)
    z-index: 2

  100%
    transform: scale(1.5)
    z-index: 2

+keyframes(hideThumbStyleguide)
  0%
   transform: scale(1.5)
   z-index: 2

  33%
    transform: scale(1) translateX(-50%)
    z-index: 1

  100%
    transform: scale(1)
    z-index: 1

+keyframes(hideThumbStyleguideAlt)
  0%
   transform: scale(1.5)
   z-index: 2

  33%
    transform: scale(1) translateX(-25%)
    z-index: 1

  100%
    transform: scale(1)
    z-index: 1

+keyframes(showThumbShowcase)
  0%
   transform: scale(1)

  33%
    transform: scale(1) translateX(50%)
    z-index: 2

  100%
    transform: scale(1.5)
    z-index: 2

+keyframes(hideThumbShowcase)
  0%
   transform: scale(1.5)
   z-index: 2

  33%
    transform: scale(1) translateX(15%)
    z-index: 0

  100%
    transform: scale(1)
    z-index: 0

+keyframes(scrollArrow)
  0%
   transform: rotate(-90deg) translateX(0.25em) scale(1)
   opacity: 0

  67%
    transform: rotate(-90deg) translateX(-0.25em) scale(1.25)
    opacity: 1

  100%
    transform: rotate(-90deg) translateX(-0.5em) scale(1)
    opacity: 0

+keyframes(scrollArrowAlt)
  0%
   transform: translateY(0)
   opacity: 0

  25%
   transform: translateY(0.25em)
   opacity: 0

  45%
    transform: translateY(1em) scale(1.25)
    opacity: 1

  95%
    transform: translateY(1.5em) scale(1.5)
    opacity: 0

+keyframes(scrollArrowUp)
  0%
   transform: rotate(90deg) translateX(0.25em) scale(1)
   opacity: 0

  67%
    transform: rotate(90deg) translateX(-0.25em) scale(1.25)
    opacity: 1

  100%
    transform: rotate(90deg) translateX(-0.5em) scale(1)
    opacity: 0

+keyframes(scrollArrowAltUp)
  0%
   transform: rotate(180deg) translateY(0)
   opacity: 0

  25%
   transform: rotate(180deg) translateY(0.25em)
   opacity: 0

  45%
    transform: rotate(180deg) translateY(1em) scale(1.25)
    opacity: 1

  95%
    transform: rotate(180deg) translateY(1.5em) scale(1.5)
    opacity: 0