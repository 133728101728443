@import url('https://fonts.googleapis.com/css?family=Allura&display=swap')
@import url('https://fonts.googleapis.com/css?family=Alfa+Slab+One')
@import url('https://fonts.googleapis.com/css?family=Pacifico')
@import url('https://fonts.googleapis.com/css?family=Over+the+Rainbow')

.adjectives-list
  @media (max-width: $mdscreen)
    font-size: 0.925em

  .card
    min-width: calc(25% - 1em)

    @media (max-width: $mdscreen)
      min-width: calc(33.333% - 1em)

    @media (max-width: $smscreen)
      font-size: 0.925em

    p
      font-size: 1.75em
      margin: 0
      padding: 0.25em
      line-height: 1
      vertical-align: middle

  .clean
    font-family: "Quicksand"

  .elegant
    font-family: "Allura"

    p
      font-size: 2.5em
      line-height: 0.5
      position: relative
      top: 0.075em

  .bold
    font-family: "Alfa Slab One"

    p
      position: relative
      top: 0.125em

  .colorful
    
    font-family: "Pacifico"

  .minimal
    font-family: "muliextralight"

  .modern
    font-family: "Comfortaa"

    p
      position: relative
      top: 0.0925em

  .innovative
    font-family: "Gruppo"

    p
      font-size: 2em
      line-height: 0.75

  .unique
    font-family: "Over the Rainbow"

    p
      font-size: 2em
      line-height: 0.75
      position: relative
      top: 0.0925em